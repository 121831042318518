<script>
  import { fade } from "svelte/transition";
</script>

<div class="sceneWrapper" transition:fade={{ delay: 450, duration: 600 }}>
  <div class="poem" transition:fade={{ delay: 2000, duration: 300 }}>
    <div class="header-wrapper">
      <h1>The Beach</h1>
      <p>
        <em
          >Though I choose to avoid the subject of Rimbaud, he is the portal
          that leads me here. When we decide by a combination of design and
          chance to go to the beach, I become conscious of an expectation I
          should visit another place altogether, one saddled with personal
          history. But this isn’t that kind of project, and I’m not that writer.
          So after a quick splash diving from rocky cliffs into azure bays and
          underwater caves I dashed along the coast under a maddening sun and
          headlong into the buzzing filthy volatile crosscultural city of the
          kind that exposes my desires, before venturing briefly inland to level
          off in the guise of an organised and respectable cultural retreat.</em
        >
      </p>
    </div>
    <div class="poem-section">
      <p>
        the site could have been inhabited by the Greeks<br />
        no proof has yet been found<br />
        one of the main beaches is made cooler by a karstic source<br />
        karst (kärst) n. an area of irregular limestone<br />
        where erosion has produced fissures, sinkholes, underground streams, caverns<br
        />
        (German, after the Karst, a limestone plateau where<br />
        geologists first studied karstic landscapes)<br />
        I go around the world with you, thank you,<br />
        see people of all ages and varieties of undress<br />
        and if downtown it’s required<br />
        you’re well dressed, don’t assume because <br />
        these are nudist beaches, the smallest known to man,<br />
        blue water, villages, food, air to breathe,<br />
        beautifully clear with rocks<br />
        the public restroom before the hike <br />
        yes it’s a small rocky one with a nice vibe<br />
        pebbles so hard on the feet right into the water<br />
        so bring a thick towel or you will be uncomfortable<br />
        the shower’s quite warm despite early morning<br />
        steep entry in/out of water<br />
        yes it was crowded, yes it was rocky, yes<br />
        a truly delicious swim, soak, float on a hot July day<br />
        the waves crashing onto the rocks<br />
        on the sides of the little bay making<br />
        plans for a short trip back for late lunch<br />
        and would like to swim is there<br />
        anywhere to change into a swimsuit<br />
        a dramatic spectacle<br />
        should we plan on a restaurant WC
      </p>
    </div>
    <div class="video-wrapper">
      <video src="./visuals/c2a.mp4" autoplay muted controls />
    </div>
    <div class="poem-section">
      <p>
        The course of a well-known road race, held on the last Sunday each
        October, attracts some 12,000 runners. The day before people walk the
        course. The route follows the coastal D559 which winds its way through
        the dry hills between the two cities, through olive groves and forests
        in part, with great views. This is a one-way run, and it is hilly!
        There’s 1450 feet of elevation gain over the course, most of which
        happens over the last six miles, with an average grade of 3%. You can do
        this run one-way, and then take the 25-minute train ride back (or take
        the train and run back, which is more downhill). This is a busy road,
        but there is a decent enough shoulder for running. It can be hot and
        dry, so make sure you hydrate accordingly. There are viewpoint pullouts
        along the way. Follow the D559 nearly the whole way, but then at the
        roundabout at the edge of the forest take the D41E which ends at the
        train station.
      </p>
    </div>
    <div class="image-wrapper faded">
      <img src="./visuals/c2d.jpeg" alt="The Beach" />
    </div>
    <div class="poem-section">
      <p>
        clientelism and corruption are the system<br />
        ‘spray and pray’ shootings not uncommon<br />
        on average one person shot each day<br />
        entwined with passion for football
      </p>

      <p>
        picturesque images are part of folklore<br />
        replaced by no-go areas for the public<br />
        even the police<br />
        Marius, Fanny, César, Jean-Claude Izzo, Iam’s rap
      </p>

      <p>
        it’s a drama city, oh yes!<br />
        everyone knows a sardine becomes a shark in their mouths<br />
        the world capital of apartheid can be cute<br />
        if you’re a pragmatist it’s not for you
      </p>

      <p>
        dazzled by the sea an easy casual crowd typecast<br />
        the economic diaspora racially<br />
        contributes to its exotic character as much as<br />
        the chic of midi-money
      </p>

      <p>
        families have long and deep roots<br />
        the romance of the mafia<br />
        more an enormous village with the blinkered mentality<br />
        a dump and wishes to stay that way
      </p>

      <p>
        its saltiness has a love it or loathe it thing<br />
        the romance of seafaring<br />
        absurdly threatening overt displays of extreme decay<br />
        poverty and suddenly
      </p>

      <p>
        whole quarters in the grimy centre<br />
        restored into handsome boulevards<br />
        gleaming white buildings<br />
        endless conflicts between developers and dock worker unions
      </p>

      <p>
        sharp edges, flora, humans and animals<br />
        immediacy, spontaneity, instinct, action and reaction without thought<br
        />
        yes the dazzling effect of bright clear sunlight creating drama<br />
        an eyeful of the warm south
      </p>

      <p>
        the same as standing on fifth Avenue<br />
        when light is advancing<br />
        towers and people so beguilingly<br />
        all you can perceive is that
      </p>

      <p>
        this is The Big Town<br />
        fast, active, beautiful, ready to sweep you along<br />
        or spit you out into the river<br />
        either way you would be grinning and grateful
      </p>
    </div>
    <div class="image-wrapper faded">
      <img src="./visuals/c2b.jpeg" alt="The Beach" />
    </div>
    <div class="poem-section">
      <p>
        <span class="question">Q: How long does the train take?</span><br />
        <span class="answer"
          >A: The average journey time by train is 12 minutes, with around 7
          trains per day. The journey time may be longer on weekends and
          holidays, so use our Journey Planner on this page to search for a
          specific travel date.</span
        >
      </p>

      <p>
        <span class="question"
          >Q: What’s the fastest journey time by train?</span
        ><br />
        <span class="answer"
          >A: The fastest journey time by train is 12 minutes.</span
        >
      </p>

      <p>
        <span class="question">Q: How much does the train cost?</span><br />
        <span class="answer"
          >A: Train ticket prices can start from as little as €6.40 when you
          book in advance. The cost of tickets can vary depending on the time of
          day, route and class you book and are usually more expensive if you
          book on the day.</span
        >
      </p>

      <p>
        <span class="question">Q: Is there a direct train?</span><br />
        <span class="answer"
          >A: Yes, it is possible to travel without having to change trains.
          There are 7 direct trains each day. Though there may be fewer direct
          services available depending on your exact departure date.</span
        >
      </p>

      <p>
        <span class="question">Q: What time does the first train leave?</span
        ><br />
        <span class="answer"
          >A: The first train leaves at 06:59. Times and services may vary
          during weekends and holidays.</span
        >
      </p>

      <p>
        <span class="question">Q: What time does the last train leave?</span><br
        />
        <span class="answer"
          >A: The last train leaves at 20:14. Trains that depart in the early
          morning hours or very late evening may be sleeper services, time and
          services may also vary during weekends and holidays.</span
        >
      </p>

      <p>
        <span class="question">Q: What’s the distance by train?</span><br />
        <span class="answer"
          >A: Trains cover a distance of around 11 miles (18 km) during the
          journey.</span
        >
      </p>

      <p>
        <span class="question">Q: Looking for more information?</span><br />
        <span class="answer"
          >A: If you’re looking for the return train journey</span
        >
      </p>
    </div>
    <div class="image-wrapper faded">
      <img src="./visuals/c2c.jpeg" alt="The Beach" />
    </div>
    <div class="poem-section">
      <p>
        a complete failure this movie<br />
        boring, a waste,<br />
        too long and both actors are lame
      </p>

      <p>
        a glimpse into a life that links <br />
        simplicity to art, the chance to admire mundane <br />
        objects so perfectly homogeneous it’s a little museum of light
      </p>

      <p>
        I watched it alone and it was literally<br />
        AMAZING! highly recommend to watch<br />
        I will definitely watch it<br />
      </p>

      <p>
        we are very sorry that you were not moved<br />
        it may be humble<br />
        but a site of remembrance and inspiration
      </p>

      <p>
        highly accomplished time period<br />
        about art, love, and the struggle of two good friends<br />
        best regards
      </p>
    </div>
  </div>
</div>

<style>
  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 20px 20px 100px;
    padding: 20px;
    border: 1px solid white;
  }
  .header-wrapper h1 {
    flex: 1 0 50%;
    text-align: center;
    color: white;
  }
  .header-wrapper p {
    border-left: 1px solid white;
    padding-left: 20px;
    text-align: justify;
    color: white;
  }
  .poem {
    width: 100%;
    padding: 100px 0;
    background-color: rgba(0, 127, 255, 0.3);
    font-weight: 100;
    line-height: 1.4;
    font-size: 1rem;
  }
  .poem-section {
    max-width: 740px;
    padding: 0 3vw;
    margin: 1rem auto;
  }
  .poem-section p {
    color: white;
  }
  .question {
    font-weight: 800;
  }
  .answer {
    font-style: italic;
  }
  .image-wrapper,
  .video-wrapper {
    max-width: 100%;
    overflow: hidden;
    margin: 50px 0;
    display: flex;
    justify-content: center;
  }
  .video-wrapper video {
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 50%;
    object-fit: contain;
    margin: 0 auto;
  }
  .image-wrapper img {
    width: 50%;
    object-fit: contain;
    margin: 0 auto;
  }
  .image-wrapper.faded img {
    mix-blend-mode: difference;
  }
</style>
