<script>
  import { fade } from "svelte/transition";
  import { onMount } from "svelte";
  let sw;

  onMount(() => {
    console.log(sw);
    sw.scrollTo(0, 0);
    sw.addEventListener("map-zoom-end", () => {
      console.log("map-zoom-end");
    });
  });
</script>

<div
  class="sceneWrapper"
  bind:this={sw}
  transition:fade={{ delay: 2500, duration: 3000 }}
>
  <p>
    <em
      >“I recently attended this venue to attend this venue. This venue was a
      venue.” – anonymous, c. 1997</em
    >
  </p>
  <img src="./visuals/c5a.jpeg" alt="club" />

  <p>
    <em
      >Drawing a linear narrative over time can make little sense. I bought The
      Velvet Underground & Nico on vinyl having first been streaming it on
      Spotify. I emigrated from a non-EU state into Europe, but if you look at
      it from the prevailing perspective it’s the other way round. It is alleged
      I spend many after-hours hours queuing outside the world’s worst burger
      joint (I try it once in the afternoon, sober). This hardly suggests Nico
      performing right there just a short time apart, only for such an event to
      be realised when YouTube recommends me the footage. We are teenagers: we
      live with teenagers. This is a proper poem and it has an epigraph. We are
      here and this is a vantage point and we were here again. How can’t it be
      possible that you and I go for a bite and a few drinks?</em
    >
  </p>

  <p>
    a voice from the end of the world<br />
    funny accent, crazy eyes,<br />
    like iced water teasing us to walk upon
  </p>

  <p>
    belongs in the exstratrosphere<br />
    the rest a place for smokers<br />
    that’s become ageist
  </p>

  <p>
    when I was young I got stood up<br />
    I don’t like standing so I sat down, upon sitting down I stood up,<br />
    looked into the eyes of one who has seen infinity
  </p>

  <p>
    nothing, no soul,<br />
    got my first STD <br />
    and collecting what money was left
  </p>

  <p>
    bought a Lotus Europa<br />
    proper fast<br />
    long hair, flares, and Ben Sherman shirts
  </p>

  <p>
    absolutely chuffed<br />
    when some person barged into my sister<br />
    unconscious and unable to defend herself
  </p>

  <p>
    Russell was barred for being trans<br />
    Kismet Jane simply for not being local and young<br />
    Eddie always wore a suit, no shoes
  </p>

  <p>
    my daughter’s drink got spiked<br />
    underground, a sadness<br />
    like stale sweat
  </p>

  <p>
    and I love my cans of pop every day<br />
    I know<br />
    last summer was legend
  </p>

  <p>
    how excess destroys life<br />
    but she like a ghost among the living<br />
    her presence, ambiance, her voice, everything
  </p>

  <p>
    beautiful on dance floors<br />
    it’s hot in here, and too quiet,<br />
    I just want a gentle hug
  </p>

  <p>
    looks like she’s going to bite into somebody’s skull<br />
    like an apple<br />
    I don’t blame her<br />
  </p>
</div>

<style>
  .sceneWrapper {
    background-color: #fff;
    max-width: 50%;
    height: 100%;
    margin-left: 50%;
    padding: 20px 20px 25vh;
    overflow-y: scroll;
  }
  img {
    max-width: 100%;
    margin: 10px 0;
  }
</style>
