<script>
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";
  import GraffitiDisplay from "../toasts/GraffitiDisplay.svelte";
  let activeIntro = true;
  let activeAds = false;

  const rnd = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

  let animateSlot = (s, v, t) => {
    s.style.transform = `translateY(-${v}px)`;
    setTimeout(() => {
      animateSlot(s, v + 1, t);
    }, t);
  };

  const handleAdDismiss = (e) => {
    activeAds = false;
  };

  onMount(() => {
    setTimeout(() => {
      activeIntro = false;
    }, 10000);

    let s1 = document.getElementById("slot1");
    let s2 = document.getElementById("slot2");
    let s3 = document.getElementById("slot3");

    setTimeout(() => {
      animateSlot(s1, 0, rnd(45, 64));
      animateSlot(s2, 0, rnd(45, 64));
      animateSlot(s3, 0, rnd(45, 64));
    }, 10000);

    setTimeout(() => {
      activeAds = true;
    }, 25000);
  });
</script>

<div class="sceneWrapper" transition:fade={{ delay: 450, duration: 600 }}>
  <div class="poem" transition:fade={{ delay: 2000, duration: 300 }}>
    <div class="intro" class:activeIntro>
      <em
        >An Irish poet of note, Christodoulos Makris, was most certainly born
        here. Even in their earliest iterations, his poems show a characteristic
        sensibility for managing interior temperatures. He makes consistent use
        – for a variety of purposes – of any and every instance of quotidian
        wastage, all whilst combing every bit of fine print backwards and
        forwards. An obsessive documenter, what may appear to be a jumble of
        voices is actually the result of an agreement, and his signature can
        always be found therein, after careful examination.</em
      >
    </div>

    <div class="slot-wrapper">
      <div class="slot" id="slot1">
        <h2>Part ONE</h2>

        <p>
          Thought it might be handy for newbies to this sizeable rock and also
          those who have been here for a while to have a “list of things to
          watch out for” when renting or owning or purchasing property here…I
          have compiled this list (mainly borne out of personal experiences over
          the last 6 years) as a means of offering some level of protection for
          both new and old expats alike. Please note the following: All data and
          information provided on this site is for informational purposes only
          we make no representations as to accuracy, completeness, currentness,
          suitability, or validity of any information in this document and will
          not be liable for any errors, omissions, or delays in this information
          or any losses, injuries, or damages arising from its display or use.
          All information is provided on an as-is basis, and is provided in good
          faith.
        </p>

        <p>
          Please feel free to message me with things that you have experienced
          something that may not be listed here and we will add it. By the way
          they are not in any particular order just as they occurred. Hope it
          helps to protect you a little more than not having it at all.
        </p>

        <p>
          the information contained in this document must not be seen or taken
          as legal advice
        </p>

        <p>
          1 always rent for a short period first to ensure you know more about
          the area, services and neighbours before committing yourself. Once you
          move in take photos before and when you move out – the landlord may
          try and make claims against you and may want new for old.
        </p>

        <p>
          2 visit the area for external noises at varying times of day and
          night…Barking and howling dogs is common as is noisy full blown full
          flow car exhausts!!
        </p>

        <p>3 check out parking restrictions</p>

        <p>
          4 if you have pets makes sure the landlord will allow them, some will
          some won’t but some will accept it on the offer of payment of a
          further damages/cleaning deposit
        </p>

        <p>
          5 if sunshine is important to you then try to get a south facing
          property...it can pay dividends in winter and cut your bills for
          heating quite considerably. Does it get plenty of natural light?
        </p>

        <p>
          6 penthouses are fine but they are as a general rule very cold/damp in
          winter and too hot in summer…assess the number of exposed external
          walls – exposure to adverse weather conditions is a major factor in
          dampening any property
        </p>

        <p>
          7 make sure you have a lift in the block – hauling gas bottles and
          shopping up several flights of stairs is not fun as you get older
        </p>

        <p>
          8 check for air vents and fully opening windows – are there any signs
          of black damp, humidity and or condensation – feel the bedding etc
        </p>

        <p>
          9 does the property have insect screens on opening windows and doors
        </p>

        <p>
          10 does it have fans and or air con or at least fully opening windows
          to get a through draught
        </p>

        <p>
          11 check out comfort of furniture, sofas chairs and beds, especially
          springs and squeakyness....there are various designs of sprung beds
          some are extremely noisy LOL
        </p>

        <p>
          12 are the appliances eco friendly units, power efficient units – same
          with lighting – check your electrics to make sure they are wired to
          you and nobody else as well – this is an old trick… turn all your
          power and water off and see if the meters are still going…sounds
          extreme but believe me it happens more than you think. Make sure you
          are on the correct tariff as a resident. Get it in writing don’t leave
          it to a verbal agreement even if the estate agent makes comments make
          sure that’s in the contract too. Don’t leave anything to chance, if it
          has an impact on your budget then make sure it’s clear and fully
          disclosed.
        </p>

        <p>
          13 is there a time-switch on the water heaters or can you get one
          fitted – do you have a separate water heater for the kitchen sink – it
          saves a lot, meaning that the main boiler doesn’t need to be on all
          day just to give hot water for washing dishes – If you are lucky you
          may well have a solar panel installed that will provide you with hot
          water for free while the suns out and then some
        </p>

        <p>
          14 remember ground floor and mid floor apartments will suffer from a
          level of noise from units above, especially where young children are
          located. If next to a busy road the higher you are the less noise will
          be evident. Check out the neighbours, are they families, young ones
          oldies do they own or rent…believe me it makes a difference Check out
          the location…is it an area prone to flooding.
        </p>

        <p>
          15 check if the rent includes charges for communal costs for lighting,
          lifts including maintenance, rubbish taxes if appropriate and also
          regular cleaning of communal areas. If you are lucky enough to have
          access to a pool check that out too. If not then check what the costs
          are likely to be as pools may have to have licences and need to be
          treated regularly with chemicals overnight to keep the water clean and
          disease free as well as slime and algae free.
        </p>

        <p>
          16 is there an emergency lighting system in place for stair wells and
          lift shafts as power cuts are normal and climbing stairs is not
          recommended in the dark – get some good torches (rechargeable ones and
          batteries) and keep a supply of candles handy. 4-10 hours power cuts
          are infrequent but they do happen. Make sure you know where the fuse
          boxes are and the main switches etc. Also check out whether the area
          is prone to water shortages and water cut offs.
        </p>

        <p>
          17 if you have your own belongings and furniture assess whether you
          will need a lifter and whether you can get all items through the
          windows and or doors and is there space for the lifter unit to stand
          on the road – also note a permit may be required usually available
          from the local council offices – there may be a small charge. Note
          also if the lifter has to stand in a bus stop or on a pedestrian
          crossing then you may also need to get permission from transport
          authorities…especially if on a main road.
        </p>

        <p>
          18 is there any outdoor space available for you to use privately or
          shared, if so identify it, and make it your own if you can. Check also
          washing line availability and your allocated roof space
        </p>

        <p>
          19 is there enough electrical power sockets, and are they where you
          need them to be – any burn marks on electrical points get them checked
          and changed. Check security of sockets and if they all work the way
          they should
        </p>

        <p>
          20 gas cooking (oven and hob) is much cheaper to operate than electric
          – it is common place to have gas hob and electric oven…on remote
          complexes its common to have just electric appliances, try one of the
          small (12L /17L) round bench top halogen ovens, they’re very
          economical and work well – we use ours every day
        </p>

        <p>
          21 check out all your lighting features. Especially the lights over
          the kitchen surfaces and oven hoods, see if you can change the bulbs
          etc for energy efficient ones…they pay for themselves over time – take
          them with you when you leave (it’s one of the first things i do)
        </p>

        <p>
          22 always do an inventory and get it checked thoroughly and note any
          damages or anything missing – get it agreed, don’t just leave it to
          chance. It will be painful if you do – don’t accept the landlords
          “it’ll be ok don’t worry about it” – DO worry about it as it may come
          back to bite you) take photos – especially watch for furniture that is
          veneered as this often lifts due to the humidity levels
        </p>

        <p>
          23 check the water pressure as it can be very low and poor service for
          showers, if its low ask the landlord to fit a pump they are not too
          expensive and they’re cheap to run but make such a big difference
        </p>

        <p>
          24 check the water tanks for the security of the lids – make sure they
          are wired down as insect larva can get in. taste the water. also check
          the toilets for flushing, identify if they have half and full flush
          functions. We can suffer from very high winds and the tank lids can
          fly off. Although to be fair haven’t seen many of this type here they
          all seem to be cylindrical tanks so far
        </p>

        <p>
          25 tv phone and internet connections – check what’s available – check
          if the satellite channels you wish to view can be received – many
          british channels cannot be received here. Check if you can you can
          have one, can one be fitted, have you got the space, can it be
          installed easily, are there existing cable runs – if not where can the
          cables go. will you need permission to have one. Will others require
          service too. Internet tv (iptv) is easier to get for uk live tv,
          sport, movies, tv series, catchup, music, radio, weather, news etc etc
          etc and very cheap, there are free options and subscription options
          available and you would only need a decent internet service of about
          4mbps…They will include SD & HD quality channels via sites like
          www.filmon.com. Or try this http://www.ntv.mx?r=dr don’t take the
          local services as they’re usually poor out of date services…and you
          will be bored with what’s on offer within 3 months. Try it on your
          laptop – or get an android box install xbmc and install the addons
          associated with it…its superb viewing and free or subscription
        </p>

        <p>
          26 check for refuse collections how often and on what days and at what
          times you can put the rubbish out as there can be restrictions – thus
          awkward…Disposal of bigger household items can be arranged with the
          local council just phone them – Recycling points are in most villages
          so find out where they are if its important to you. you may be fined
          if you get caught putting your rubbish out before the allotted times
          or disposing of it in an irresponsible way
        </p>

        <p>
          27 check out how the bills for utilities are calculated, how and when
          they will be presented to you. Ask to see a recent one – don’t be
          fobbed off that a recent one is not available – ask to see one – the
          landlord will have one.
        </p>

        <p>
          28 do a consumption check when you arrive check the start readings and
          say after a normal week of consumption check it again and then do a
          calculation on the utility suppliers website…this will give you a good
          idea what you are using and the likely costs. Remember the elec bills
          come every 2 months and may be a little late – maybe 3 weeks or
          more…and often are due on the day of arrival at your door. lol. this
          can happen with Cyta too.
        </p>

        <p>
          29 make sure you are named on the bills – go to the EAK and get the
          electricity account changed into your own name immediately – €100
          deposit passport and lease is all you need…then do the same with the
          water account
        </p>

        <p>30 ensure you get a copy of the bills</p>

        <p>
          31 ensure you have a record of the rent paid and utility payments – a
          rent book is a good idea. Some landlords may want to be paid cash…NB
          sometimes (but not always) its to avoid declaring income for tax., but
          that’s not your concern
        </p>

        <p>
          32 keep your bills and records of payments, pay the bills yourself by
          a traceable method…this may help you later if you eventually have a
          problem.
        </p>

        <p>
          33 anything that the new renter finds by way of repairs, broken items
          missing items after you have signed and say within one week of the let
          – make sure you tell the landlord at the earliest possible time but
          don’t wait for a week or more, tell them straight away don’t prepare a
          long list and wait…do it as these are found, its better for you – it
          may be a pest to the landlord but it is better for you. Keep on at
          them too as you will find you will not be top priority…its just the
          way it is…
        </p>

        <p>
          34 Tax evading landlords are still highly evident – there is not a lot
          you can do currently (short of telling the tax man – not recommended
          due to serious legal and potentially violent repurcussions). Talking
          to your landlord – try and negotiate, be civil and courteous but firm.
        </p>

        <p>
          35 many landlords charge monthly and a little over the average just in
          case – but the important thing is that you get the bills calculated at
          the correct rates…check your meters regularly. Make sure your landlord
          isn’t connected to your supplies…believe me it does happen and you
          would be surprised how often....and many tenants don’t even know its
          happening until its too late…the good landlords do things right and
          don’t have an objection to their tenants going on the bills or
          re-imburse the tenant or put you in credit…if you’ve overpaid – its
          always best to recalculate it every three to six months and adjust
          accordingly if you pay it every month.
        </p>

        <p>
          36 always inspect your water tanks…for security of the fittings as
          once the water is passed the meter its your problem to pay for leaked
          water…and as the tanks are usually out of sight on the roof – you
          could have a leak and not know it – another reason to check
          consumptions regularly and investigate any major fluctuations.
        </p>

        <p>
          37 As a general rule on consumptions and yes i know everyone is
          different – our consumptions have remained fairly stable over the last
          6 years and we have every economy gadget going – when there was just
          two of us at about 4.5 electricity units per person per day and even
          now there is three of us its the same. as for water…well when there
          was two of us it was about 150-170 litres total per day…and now there
          are three of us its about 200-250 litres total per day…mind you “her
          indoors” does 1-2 clothes washes most days – obviously that depnds on
          your usages habits and appliances – mainly whether you use AC or not
          as they’re big consumers
        </p>

        <p>
          38 Running Air Con is expensive – we used 5-6 fans every day all day
          and night during the summer these are considerably more affordable to
          run – for air con you can rely on about 50-60c per hr per AC unit
          usage…ours aren’t ancient but probably about 5-6 yrs old – the newer
          more efficient invertor types are a little bit cheaper to run
        </p>
        <p>
          NB 9000-12000btu AC units (usually bedrooms) use about 1-1.5 units per
          hr – 24000 btu units (usually combined living kitchen diners) use
          about 3 units per hr
        </p>

        <p>
          39 if you haven’t got new water saving flush toilets fill a 1 litre
          bottle up with water and place it in the cistern…also to save water in
          the shower head replace the rubber washer between the hose and the
          head with a same size washer but with a smaller hole…make one yourself
          if you can’t find one. The normal hole size is similar to that of the
          size of a pencil – replace it with a washer with a hole the size of a
          matchstick. it does work.
        </p>

        <p>
          40 put buckets under the extract outlet of the air con and use this to
          refill the toilets after use. Or use the water to water your plants or
          use in the steam iron. You will generate a gallon of water over two
          hrs or so using air con
        </p>

        <p>
          41 never ever pay an estimated bill – they are often heavily over
          estimated – you may not be obliged too – you may wish to make a
          contribution towards the bill by way of your own caclulations or pay
          just the meter service charges as that’s all you are obliged to pay
          against the estimated bills. It can be illegal to demand payment
          against the issue of an estimated bill – a recent court case within
          the EU proved this – it is clear that the supplier has the
          responsibility to read the meters regularly and within a reasonable
          timeframe – dispute the over charges
        </p>

        <p>
          42 The AEK supplier may try to charge you 4.5% interest on unpaid
          outstanding bills – so be wary
        </p>

        <p>
          43 AEK are mandated to supply bills every two months. Some people have
          experienced late billing. Don’t ever fall for the threatening reminder
          letters, if the bill is wrong – it is wrong – tell them so by putting
          it in dispute by writing to the supplier – keep copies of all
          correspondence. If they persist (and this works for repeated estimated
          billing too) tell them that if you get just one more like it then you
          will take legal action.
        </p>

        <p>
          44 its not uncommon for the prepayment of utility bills to landlords
          (if the bills remain in their name) – however as bills may not arrive
          as often as youd like the landlords get the benefit of your money in
          their bank account and that could be anything up to a year. As the
          landlords tend to err on the high side – Its good practice to take
          your own readings and check them with a online calculator tool – this
          will then give you a more accurate idea as to what you should be
          paying…but then you must also be sure that you are getting the correct
          rates charged to you.
        </p>

        <p>
          45 finalizing / balancing / reconciling bills will depend on when they
          are supplied!!!!!!! reconcile as early as possible, even if you have
          to keep pestering them. The last thing anyone wants is a protracted
          delay and a hefty bill at the end.
        </p>

        <p>
          46 when taking a let take your meter readings agree them with the
          estate agent and landlord quote them in the contract and also take
          photos of all areas of the property to identify condition at start of
          the letting contract and keep them safe…similarly when leaving a let
          to move on do the same – and pay particular attention to furniture,
          services, flooring and fitted units etc, especially where you may have
          had pets…its not unusual for massive claims against you after you have
          long gone and left…its essential to keep these records…I know a number
          of people this has happened to…and they have ended up in court…so
          caveat emptor – i think that means buyer beware. when you have taken
          the photos of the furniture, condition of walls, shower etc email them
          to yourself – this provides irrefutable evidence with a date and time
          and should stop future discussions on when the pictures were taken. or
          use a camera with time and date stamp functions. I know it sounds over
          the top but believe me these things do happen – protect yourself from
          this
        </p>

        <p>
          47 to deter mozzies as we have had lots of them this year – try
          putting out a bowl of cloves in each room…not the crushed ones – the
          whole ones. If they still bother you at night put your fan on low and
          place it so it blows towards your head (mozzies are attracted to your
          breath) – but they cannot fly well if at all when in a breeze…
        </p>

        <p>
          48 Always take the time to read your contract even the small print, if
          there are clauses in it you do not like query them and have them
          removed if you don’t like them or have them adjusted / amended to your
          satisfaction, if there are things you want included get them included.
          REMEMBER YOU ARE THE AGENTS CLIENT TOO, don’t be afraid to ask, and
          you are paying them for the service make them work for their fees. If
          the changes required are objected to, then move on to another
          property, landlord or agent…or even all three. If you don’t and just
          accept them “because he’s a nice guy” you may feel the pain when the
          going gets tough and they both show their true colours. ASK the
          awkward questions, PROTECT YOURSELF FROM UNSCRUPULOUS LANDLORDS and
          AGENTS TOO.
        </p>

        <p>
          49 always ask for a receipt for the rent and utilities payments or a
          rent book signed by both of you each time you pay....no rent book or
          receipt DONT take the let. Do not trust the overly friendly local
          until you now them really well…and they have earned your trust…even
          then – be wary – things and people change.
        </p>

        <p>
          50 never ever sign a contract for your lease unless you know for sure
          that you are 100% happy with its contents and are 100% prepared to be
          bound by them
        </p>

        <p>FURTHER PART TO THIS WILL FOLLOW – Like</p>
      </div>
      <div class="slot" id="slot2">
        <p style="margin-top: 15rem;">
          a tale of two halves<br />
          the logistics of visiting<br />
          exceedingly well-thought-out<br />
          you’ll find representations<br />
          although crumbling significantly<br />
          for anyone interested
        </p>

        <p style="margin-top: 45rem;">
          southernmost capital<br />
          clay prism<br />
          city-state<br />
          audit firms
        </p>

        <p>
          failed campaign<br />
          white estate<br />
          continuous habitation<br />
          fertile plain
        </p>

        <p>
          performance spaces<br />
          bishopric seat<br />
          opus sectile<br />
          high relief
        </p>

        <p>
          village authority<br />
          luxury items<br />
          imperial administration<br />
          bride-to-be
        </p>
        <img class="slot-image double" src="visuals/k3c.jpg" alt="nicosia-ac" />

        <p style="margin-top: 45rem;">
          eleven bastions<br />
          strategic reasons<br />
          martyr story<br />
          public building
        </p>
        <GraffitiDisplay />
        <p>
          religious icons<br />
          minority groups<br />
          winter nights<br />
          present block
        </p>

        <p>
          new building<br />
          new site<br />
          imposing villas<br />
          post-independence
        </p>

        <p>
          vast majority<br />
          light frost<br />
          resistance organisation<br />
          modern history
        </p>

        <p style="margin-top: 55rem;">
          intercommunal violence<br />
          hot climate<br />
          attempted coup<br />
          city limits
        </p>

        <p>
          refugee problem<br />
          capital city<br />
          pleasant winters<br />
          military junta
        </p>

        <p>
          huge growth<br />
          village authority<br />
          estimated population<br />
          time zone
        </p>

        <p>
          walled city<br />
          shopping street<br />
          historic monument<br />
          murder mile
        </p>

        <p style="margin-top: 46rem;">
          old city<br />
          new city<br />
          failed attempts<br />
          picturesque frescoes
        </p>

        <p>
          frequent targeting<br />
          shopping streets<br />
          army invasion<br />
          fine museums
        </p>

        <p>
          police station<br />
          cultural center<br />
          social housing<br />
          concrete buildings
        </p>

        <p>
          new walls<br />
          general administration<br />
          entertainment center<br />
          metropolitan authority
        </p>

        <p style="margin-top: 55rem;">
          inward-looking<br />
          population figures<br />
          serious overcrowding<br />
          archaeological museum
        </p>

        <p style="margin-top: 28rem;">
          bold exterior<br />
          weather station<br />
          intimate environment<br />
          impressive array
        </p>

        <p style="margin-top: 25rem;">
          shooting event<br />
          train network<br />
          biggest venue<br />
          toponymic puzzle
        </p>
      </div>
      <div class="slot" id="slot3">
        <p style="margin-top: 20rem;">
          As a cypriot, i will focus on the disadvantages mostly heard from
          tourists from all over the world. Before thinking that i’m just a
          hater i’ll inform you that you already know the advantages of living
          in Cyprus and possibly you are over-excited. But yes, you will
          possibly find a small paradise here (i will explain myself in the
          end).
        </p>

        <div class="cyprus-tip" style="margin-top: 15rem;">
          <h2>Public transportation</h2>
          <p>
            While as a country we have generally smaller distances between two
            random places we tend to have a lifestyle of being relaxed and calm
            down. This created a base for not the best public transportation
            system and almost everyone over 21 owns a car.
          </p>
          <img
            class="slot-image"
            src="visuals/k3b.jpeg"
            alt="nicosia-postcard"
          />
        </div>

        <div class="cyprus-tip" style="margin-top: 65rem;">
          <h2>More expensive for tourists</h2>
          <p>
            As a mostly touristic destination we have almost double standards
            when it comes to tourists and non-tourists (even i say it now…).
            When you learn the language and way of living you will realise that
            because tourism is one of the dominant money sources in our economy
            we have to be more expensive for tourists as any other country
            though (don’t cry you will get it from our debt). That is important.
            Looks are not that important but when it comes to the way you behave
            if you are talking like a tourist it will be like begging us to put
            fire in your wallet.
          </p>
        </div>

        <div class="cyprus-tip" style="margin-top: 55rem;">
          <h2>Still relatively closed-minded</h2>
          <p>
            Even if you may have seen some sparks of hope for many people, we
            are still not that open-minded when it comes to homosexuality,
            church, politics, Turkish occupation e.t.c. For God’s sake don’t
            even for a joke say that Turkish occupation was helpful, good or
            positive in any way. It is a very very very sensitive subject mostly
            because our generation still has the scars of this war (dead
            relatives, molested family members, lost relatives e.t.c.). But in
            the other side this depends in your micro-society. If you come here
            and make friends with open-minded people, except from some hateful
            comments you will possibly hear and mention nothing. For example i
            try to make my world the most possible friendly place for LGBTQ
            members. But there are some rare times i still hear some hateful
            “jokes” and then i just clean my life from these people. A paradigm
            is our recent history which has nothing to show for hate against the
            LGBTQ community.
          </p>
        </div>

        <div class="cyprus-tip" style="margin-top: 45rem;">
          <h2>Hot weather</h2>
          <p>
            Especially in Nicosia we don’t just have a hot, dry micro-climate.
            We really own the heat (at least part of it). I’ve heard of people
            having culture shock because they though it would be fun to come
            from a country with negative average temperatures instantly to
            Cyprus in the summer with an average of 33 Celsius degrees and up to
            40 degrees. We have almost always an arid, hot weather except some
            rare exclusions. You can educate yourself from an online database
            though for more/or complicated informations.
          </p>
        </div>

        <div class="cyprus-tip" style="margin-top: 55rem;">
          <h2>Too many places</h2>
          <p>
            Because there are many places to visit and see, you have to spent a
            lot of time to find the best places for you. This means you have to
            get advices from the right people and invest a lot of time. But if
            you stay here you will have more than enough time to see many places
            and have a lot of fun. It’s just a matter of experience…
          </p>
          <p>
            So if you have a job, a car and enough money for accidental
            coincidence (any form) you will just be fine.
          </p>
          <p>
            For advantages i will mention the top of them as they are many and
            you can search about them online or ask me for more information.
          </p>
        </div>

        <p style="margin-top: 55rem;">
          Cool, beautiful, sexy and fuckable people smiling everywhere.
          Everything is no more than an hour by car away from you (In the winter
          you can go for swimming and in about 20 minutes have a bath in some
          dreammy catarracts and in 20 more snowboard somewhere on a mountain).
          Many cuisines, cultures, rich history, great night life (before 12
          p.m. is not considered night life for me), Almost the best weather
          possible. Great nature and sightseeings.
        </p>

        <p style="margin-top: 45rem;">
          I think these conclude everything about Cyprus. It is a beautiful
          island for sure. I would reccomend you to come here whenever you want.
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </div>
    </div>
    {#if activeAds}
      <ul class="air-conditioners">
        <li
          class="product type-product post-13129 status-publish first instock product_cat-air-conditioners product_cat-cooling-heating product_cat-split-type has-post-thumbnail featured taxable shipping-taxable purchasable product-type-simple yith-wcbm-product-has-badges"
          on:click={handleAdDismiss}
        >
          <div class="product-outer">
            <span class="close" on:click={handleAdDismiss}>Close</span>
            <div class="product-inner">
              <div class="product-loop-header">
                <span class="loop-product-categories"
                  ><a
                    target="_blank"
                    href="https://www.cmcelectric.com/product-category/cooling-heating/air-conditioners/"
                    rel="tag">Air Conditioners</a
                  >,
                  <a
                    target="_blank"
                    href="https://www.cmcelectric.com/product-category/cooling-heating/"
                    rel="tag">Cooling &amp; Heating</a
                  >,
                  <a
                    target="_blank"
                    href="https://www.cmcelectric.com/product-category/cooling-heating/air-conditioners/split-type/"
                    rel="tag">Split type</a
                  ></span
                ><a
                  target="_blank"
                  href="https://www.cmcelectric.com/product/tcl-tac-09chsa-xa21/"
                  class="woocommerce-LoopProduct-link woocommerce-loop-product__link"
                  ><h2 class="woocommerce-loop-product__title">
                    TCL TAC-09CHSA/XA21 Air Conditioner Inverter
                  </h2>
                  <div class="product-thumbnail">
                    <div class="container-image-and-badge  ">
                      <img
                        width="190"
                        height="190"
                        src="https://www.cmcelectric.com/wp-content/uploads/2018/05/TAC-09CHSA-190x190.jpeg"
                        class="attachment-woocommerce_thumbnail size-woocommerce_thumbnail lazyloaded"
                        alt=""
                        sizes="(max-width: 190px) 100vw, 190px"
                        srcset="https://www.cmcelectric.com/wp-content/uploads/2018/05/TAC-09CHSA-190x190.jpeg 190w, https://www.cmcelectric.com/wp-content/uploads/2018/05/TAC-09CHSA-380x380.jpeg 380w, https://www.cmcelectric.com/wp-content/uploads/2018/05/TAC-09CHSA-100x100.jpeg 100w, https://www.cmcelectric.com/wp-content/uploads/2018/05/TAC-09CHSA-150x150.jpeg 150w, https://www.cmcelectric.com/wp-content/uploads/2018/05/TAC-09CHSA-300x300.jpeg 300w, https://www.cmcelectric.com/wp-content/uploads/2018/05/TAC-09CHSA.jpeg 600w"
                        data-ll-status="loaded"
                      /><noscript
                        ><img
                          width="190"
                          height="190"
                          src="https://www.cmcelectric.com/wp-content/uploads/2018/05/TAC-09CHSA-190x190.jpeg"
                          class="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                          alt=""
                          srcset="https://www.cmcelectric.com/wp-content/uploads/2018/05/TAC-09CHSA-190x190.jpeg 190w, https://www.cmcelectric.com/wp-content/uploads/2018/05/TAC-09CHSA-380x380.jpeg 380w, https://www.cmcelectric.com/wp-content/uploads/2018/05/TAC-09CHSA-100x100.jpeg 100w, https://www.cmcelectric.com/wp-content/uploads/2018/05/TAC-09CHSA-150x150.jpeg 150w, https://www.cmcelectric.com/wp-content/uploads/2018/05/TAC-09CHSA-300x300.jpeg 300w, https://www.cmcelectric.com/wp-content/uploads/2018/05/TAC-09CHSA.jpeg 600w"
                          sizes="(max-width: 190px) 100vw, 190px"
                        /></noscript
                      >
                      <div
                        class="yith-wcbm-badge yith-wcbm-badge-52829 yith-wcbm-badge--on-product-13129 yith-wcbm-badge--anchor-point-top-right yith-wcbm-badge-css yith-wcbm-css-badge-52829"
                      >
                        <div class="yith-wcbm-badge__wrap">
                          <div class="yith-wcbm-css-s1" />
                          <div class="yith-wcbm-css-s2" />
                          <div class="yith-wcbm-css-text">
                            <div class="yith-wcbm-badge-text">
                              Temporary out of stock
                            </div>
                          </div>
                        </div>
                        <!--yith-wcbm-badge__wrap-->
                      </div>
                    </div>
                    <!--container-image-and-badge-->
                  </div></a
                >
              </div>
              <!-- /.product-loop-header -->
              <div class="product-loop-body">
                <span class="loop-product-categories"
                  ><a
                    target="_blank"
                    href="https://www.cmcelectric.com/product-category/cooling-heating/air-conditioners/"
                    rel="tag">Air Conditioners</a
                  >,
                  <a
                    target="_blank"
                    href="https://www.cmcelectric.com/product-category/cooling-heating/"
                    rel="tag">Cooling &amp; Heating</a
                  >,
                  <a
                    target="_blank"
                    href="https://www.cmcelectric.com/product-category/cooling-heating/air-conditioners/split-type/"
                    rel="tag">Split type</a
                  ></span
                ><a
                  target="_blank"
                  href="https://www.cmcelectric.com/product/tcl-tac-09chsa-xa21/"
                  class="woocommerce-LoopProduct-link woocommerce-loop-product__link"
                  ><h2 class="woocommerce-loop-product__title">
                    TCL TAC-09CHSA/XA21 Air Conditioner Inverter
                  </h2>
                  <div class="product-rating">
                    <div class="star-rating" title="Rated 0 out of 5">
                      <span style="width:0%"
                        ><strong class="rating">0</strong> out of 5</span
                      >
                    </div>
                    (0)
                  </div>
                </a>
                <div class="product-short-description">
                  <a
                    target="_blank"
                    href="https://www.cmcelectric.com/product/tcl-tac-09chsa-xa21/"
                    class="woocommerce-LoopProduct-link woocommerce-loop-product__link"
                  >
                    <div>
                      Capacity: 9000 btus/h<br />
                      Application area: 12 – 18m²<br />
                      Max airflow: 550m³h<br />
                      Sound Pressure Level: Mute/Low/Mi/Hi – 29/36/44/50dB<br />
                      Color: White
                    </div>
                  </a>
                  <div>
                    <a
                      href="https://www.cmcelectric.com/ac-installation/"
                      target="_blank"
                      rel="noopener noreferrer"
                      ><span style="color: #ff0000"
                        ><strong>Need Installation?</strong></span
                      ></a
                    >
                  </div>
                </div>
                <div class="product-sku">SKU: n/a</div>
              </div>
              <!-- /.product-loop-body -->
              <div class="product-loop-footer">
                <div class="price-add-to-cart">
                  <span class="price"
                    ><span class="electro-price"
                      ><span class="woocommerce-Price-amount amount"
                        ><bdi
                          ><span class="woocommerce-Price-currencySymbol"
                            >€</span
                          >323.00</bdi
                        ></span
                      ></span
                    ></span
                  >
                  <div
                    class="add-to-cart-wrap"
                    data-toggle="tooltip"
                    data-title="Add to cart"
                    data-original-title=""
                    title=""
                  >
                    <a
                      target="_blank"
                      href="?add-to-cart=13129"
                      data-quantity="1"
                      class="button product_type_simple add_to_cart_button ajax_add_to_cart"
                      data-product_id="13129"
                      data-product_sku=""
                      aria-label="Add “TCL TAC-09CHSA/XA21 Air Conditioner Inverter” to your cart"
                      rel="nofollow">Add to cart</a
                    >
                  </div>
                </div>
                <!-- /.price-add-to-cart -->
                <div class="hover-area">
                  <div class="action-buttons">
                    <div
                      class="yith-wcwl-add-to-wishlist add-to-wishlist-13129  wishlist-fragment on-first-load"
                      data-fragment-ref="13129"
                    >
                      <!-- ADD TO WISHLIST -->

                      <div class="yith-wcwl-add-button">
                        <a
                          target="_blank"
                          href="?add_to_wishlist=13129"
                          rel="nofollow"
                          data-product-id="13129"
                          data-product-type="simple"
                          data-original-product-id="13129"
                          class="add_to_wishlist single_add_to_wishlist"
                          data-title="Add to Wishlist"
                        >
                          <span>Add to Wishlist</span>
                        </a>
                      </div>

                      <!-- COUNT TEXT -->
                    </div>
                    <a
                      target="_blank"
                      href="https://www.cmcelectric.com?action=yith-woocompare-add-product&amp;id=13129"
                      class="add-to-compare-link"
                      data-product_id="13129">Compare</a
                    >
                  </div>
                </div>
              </div>
              <!-- /.product-loop-footer -->
            </div>
            <!-- /.product-inner -->
          </div>
          <!-- /.product-outer -->
        </li>
      </ul>
    {/if}
  </div>
</div>

<style>
  .poem {
    background-color: rgba(255, 255, 255, 0.5);
  }
  .intro {
    max-width: 600px;
    margin: 0 auto;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
    font-size: 0.85rem;
    padding: 10px;
    position: fixed;
    background-color: white;
    left: calc(50vw - 300px);
    border: 1px solid black;
    top: 25vh;
    border-radius: 3px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.55s ease-in-out;
  }
  .activeIntro {
    opacity: 1;
    pointer-events: all;
  }
  .slot-wrapper {
    width: 100%;
    height: 95vh;
    overflow-y: scroll;
    display: flex;
  }
  .slot {
    flex: 1 0 31%;
    width: calc(33% - 20px);
    height: 100%;
    padding: 10px;
    font-size: 0.8rem;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
    margin-top: 40vh;
    transition: all 0.032s linear;
  }
  h2 {
    font-size: 1rem;
  }
  .slot-image {
    max-width: 100%;
    object-fit: contain;
  }
  .slot-image.double {
    max-width: 200%;
  }
  .air-conditioners {
    list-style: none;
    padding-left: 0;
  }
  .air-conditioners li {
    background-color: white;
    border: 1px solid teal;
    position: fixed;
    bottom: 40px;
    right: 60px;
    padding: 10px;
    box-shadow: 2px 6px 12px rgba(0, 0, 0, 0.25);
  }
  .close {
    text-align: right;
    width: 100%;
    display: block;
    text-decoration: underline;
    color: rgba(0, 0, 0, 0.25);
  }
  .close:hover {
    cursor: pointer;
    color: blue;
  }
</style>
