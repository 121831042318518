<script>
  import { onMount } from "svelte";
  import { draggable } from "./helpers/draggable";
  export let src = null;
  let c, v;

  onMount(() => {
    let ctx = c.getContext("2d");
    // set canvas size = video size when known
    v.addEventListener("loadedmetadata", function () {
      c.width = v.videoWidth;
      c.height = v.videoHeight;
    });
    v.addEventListener(
      "play",
      function () {
        var $this = this; //cache
        (function loop() {
          if (!$this.paused && !$this.ended) {
            ctx.drawImage($this, 0, 0);
            setTimeout(loop, 1000 / 30); // drawing at 30fps
          }
        })();
      },
      0
    );
    draggable(c, document.querySelector(".sceneWindow"));
  });
</script>

<!-- svelte-ignore a11y-media-has-caption -->
<video bind:this={v} {src} autoplay muted />
<canvas bind:this={c} />

<style>
  video {
    visibility: hidden;
    height: 1px;
    width: 1px;
    pointer-events: none;
  }
  canvas {
    transform: scale(0.5);
    filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.5));
  }
</style>
