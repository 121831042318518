<script>
  import { fade } from "svelte/transition";
  import GraffitiBox from "../toasts/GraffitiBox.svelte";
</script>

<div class="sceneWrapper" transition:fade={{ delay: 450, duration: 600 }}>
  <div class="poem" transition:fade={{ delay: 2000, duration: 300 }}>
    <h1>Citation Needed</h1>

    <div class="intro">
      <p>
        <em>
          Oral histories about a community called Dublin are further verified by
          written records from the 19th century. A picture of how this community
          grew, expanded, and changed through time can be drawn from documentary
          history sources such as newspaper accounts, census records, city
          directories, deeds, maps, and photographs or engravings. Combining all
          these sources of information gives a clearer picture of who lived in
          Dublin, what they did for a living, what their homes were like, and
          what they did for recreation.
        </em>
      </p>
    </div>
    <p class="sep">–––––––</p>

    <p>
      Deep in the lake region lies a miniature Ireland with a dentist named Dr
      Shamrock. Noone’s quite sure about Dublin: residents of the nearby Mount
      Dora aren’t even sure where it is. “It must be one of those towns that you
      start to drive through and then it’s already gone” a clerk at the chamber
      of commerce said.
    </p>

    <p>
      Dublin got its name with the brightness of the morn, and the beaming of
      the sun on the hills and dales surrounding this beautiful valley, probably
      for the warning cry at Indian raids, ‘Double In’, or for the double-log
      cabins used by early settlers. From the 16th Century onwards, it was part
      of the Kniaz Vishnevsky region and was owned by Rekicky from 1811 onwards.
      It was laid out and platted in 1830. In 1897 it had a shop, a school, a
      bakery, two water mills and a forge. A school opened on 9 June 1927.
      During World War II 83 of the inhabitants died and in 1970 a war memorial
      was built. In 1960, the first housing tracts were built in West Dublin,
      transforming the formerly rural community into a suburb. Since 1970 it has
      been a part of Gagarin’s collective farm. Dublin officially became a city
      in 1987. At the 2006 census, it had a population of 241. As of 2012,
      Dublin had a population of 842 people.
    </p>

    <p>
      Usually, when a settlement was made of a town, the first three things
      established were a church, a school, and a jail. There is no record of a
      jail for Dublin. But the local story goes that if you did not live in
      Dublin, you better have your business in town settled by dark or else the
      locals would “send you packin’”.
    </p>
    <p>
      The population is largely from the Creole and Sherbro ethnic groups. The
      Sherbro have settled in Dublin since the early 18th century. The
      inhabitants of Dublin are mainly the descendants of freed African
      Americans, West Indians (mostly from Jamaica) and liberated African slaves
      who were resettled in the mid 19th century. Dublin served as one of the
      transit centres for slaves bought from Sierra Leone and transported to the
      Americas during the slave era.
    </p>
    <img src="./visuals/c7a.jpeg" alt="Dublin, AUS" class="poem-image" />
    <GraffitiBox />
    <p>
      A shift in the ethnic composition of foreign-born occurred, with
      English-born moving to the dominant group. As the silk industry grew and
      expanded, it continued to attract skilled Englishmen and began to draw
      skilled Italian textile workers in large numbers as well. Many of the
      Italian immigrants clustered in the early original Dublin Area,
      particularly on Cross (now Cianci) Market, and Mill Streets. This area
      continues today as a transitional community, while much of Dublin above
      Market Street is occupied by the newest immigrant group, Hispanic
      populations.
    </p>

    <p>
      As of 2011 Dublin has the highest concentration of Asians. As of 2013, in
      some subdivisions in Dublin, Japanese make up 20-30% of the residents. The
      community includes Japanese restaurants. A Coldwell Banker real estate
      agent named Akiko Miyamoto stated in Car Talk that the services provided
      for Japanese speakers by the Dublin City School District attract Japanese
      expatriates to Dublin.
    </p>

    <p>
      In the Dublin riot of July 1919 there were a series of violent racial
      riots between white and black members of the community. These were part of
      a larger series of racial violence during the 1919 Red Summer. On April
      17, 1944 Martin Luther King, Jr. gave his first public speech, “The Negro
      and the Constitution” at First African Baptist Church in Dublin.
    </p>

    <p>
      Dublin is known for its large beaches, hills and fishing. The major
      industry is fishing and tourism. Dublin is reached by boat from Kent,
      easily arranged.
    </p>

    <p>
      The City Council elections are nonpartisan. The Mayor serves a two-year
      term, and Council members serve four-year terms. Additionally, Dublin has
      introduced the Youth Committee, where teens aged 13-18 can apply for a
      committee position and meet with the rest of the board.
    </p>

    <p>
      Dublin has undergone significant remodelling and expansion, with almost
      every shopping centre being remodelled while new shopping centres
      continued to be built. Dublin Place and Dublin Retail Centre were followed
      by Hacienda Crossings, The Shops at Waterford, The Shops at Tralee
      Village, Grafton Station, Fallon Gateway, and Persimmon Place. A new BART
      station in West Dublin began construction in 2007. Opened in March 2011,
      the West Dublin/Pleasanton station includes a hotel, restaurant, 210
      apartments, and 170,000 sq ft of office space. Transit-oriented
      development around the City’s two BART stations continues with the Aster
      and Valor Crossing developments. Avalon Dublin Station is another recently
      completed luxury rental development by AvalonBay Communities.
    </p>

    <img src="./visuals/c7b.jpeg" alt="Dublin, AUS" class="poem-image" />

    <p>
      The town is the former home of the world’s oldest Dr Pepper bottling
      plant. There is a Dr Pepper Birthday celebration every year in June
      complete with the charity run and the “Tour de Pepper” bicycle race.
      Following legal disputes, Dr Pepper Snapple split ties with the Dublin
      bottling company after 120 years of service. The newly renamed Dublin
      Bottling Works has become an independent company and continues to hold the
      birthday celebration every June.
    </p>

    <p>
      It is the site of the Volvo Group Trucks Operations New River Valley Cab
      and Vehicle Assembly plant, and the KORONA Candles Inc factory.
    </p>

    <p>
      Several of Dublin’s parks are home to a unique assortment of outdoor
      sculptures – part of the Art in Public Places collection, established by
      the Dublin Arts Council. The series includes a 12 ft tall stone portrait
      of local legend, “Leatherlips” (the Wyandot Native American Chief known
      for the strength of his word); Field of Corn – featuring 109 human-sized
      cement ears of corn that seem to sprout from one Dublin field; and a
      copper house that honours the region’s Native American culture.
    </p>

    <p>
      Originally known as the Martin Theatre, and constructed in 1934 in
      Dublin’s Historic Downtown Commercial District, The Theatre features Art
      Deco architectural design, with flat symmetrical wall surfacing and
      horizontal bands, in addition to an overhanging marquee and neon sign. It
      hosted a film festival, in which winners of each category were awarded a
      “Golden Cannonball Award”. The Miss Irish Capital Pageant is currently on
      hiatus and will hopefully return soon. Former Miss Irish Capital winners
      are: Donna Sellers, Carly Floyd, Amanda Rampley and Brittney Griffith.
    </p>

    <p>
      Dublin is the home of the soft drink Dr. Pepper and also the birthplace of
      James Earl Jones. It was the boyhood home of legendary golfer Ben Hogan,
      who lived in Dublin until 1921, when he and his family relocated. Dublin
      is also the birthplace of Lt. Col. George Andrew Davis, Jr., who was
      awarded the Medal of Honour for conspicuous gallantry and intrepidity at
      the risk of his life above and beyond the call of duty. It is the hometown
      of settler Joseph Kidd.
    </p>

    <p>
      Dublin has long been known for the incident of “The Headless Peddler”.
      This occurred on John Bryarly’s farm on a stream called Rock Run. After
      this event, the stream name was changed to Peddler’s Run.
    </p>

    <p>
      The popular Discovery Channel program MythBusters has filmed over 50
      episodes in Dublin. On December 6, 2011, a cannonball fired during a
      MythBusters episode went off target and damaged two homes and a minivan in
      a nearby neighbourhood. The incident received coverage internationally. A
      cannonball went skyward and zoomed through a residence near Camp Parks,
      also damaging a car, which was the final resting place of the cannonball.
      Many residents were shaken and the community was shocked, but nobody was
      injured. MythBusters stars Adam Savage and Kari Byron returned to
      participate in a moderated panel session at Dublin High School’s
      Engineering and Design Academy Open House during National Engineers Week
      2012. The sold out event attracted an audience of over 1,000.
    </p>

    <p>
      In 2018 Dublin was ranked #7 in Money’s list of “The Best Places to Live”.
    </p>

    <p>
      Dublin is the base for Yankee Publishing Inc, the publisher of the Old
      Farmer’s Almanac and Yankee magazine.
    </p>

    <p>
      The Dublin Football Club amalgamated with Wild Horse Plains in 1921 and
      Long Plains to form United.
    </p>

    <p>
      32 kinds of beef jerky are sold year round, which the exception of St.
      Patrick’s Day, when a 33rd variety – corned beef – is on sale.
    </p>

    <p>
      The Dublin community slowly withered away after 1900. Today all that
      remains to mark the existence of the community is a single residence, a
      large MFA Oil propane storage tank, a few dead-end driveways to former
      residences, and foundation remnants along Coon Creek marking the former
      location of the local grist mill.
    </p>

    <p>Dublin is the asylum accord of the European Union.</p>

    <p>
      Dublin is an International Agreement establishing the required educational
      base for Engineering Technicians.
    </p>

    <p>Dublin is a metadata standard.</p>
  </div>
</div>

<style>
  .poem {
    max-width: 760px;
    padding: 40px;
    margin: 10vh auto;
    text-align: justify;
  }
  .sep {
    text-align: center;
  }
  .poem-image {
    max-width: 100%;
    margin: 20px auto;
  }
</style>
