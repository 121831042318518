<script>
  import { fade } from "svelte/transition";
  import { onMount } from "svelte";

  const scroll = (el, time) => {
    let i = 100;
    let l = el.querySelector(".last");
    setInterval(() => {
      if (l.getBoundingClientRect().bottom < 0) {
        el.style.opacity = 0;
        el.style.transform = `translateY(350px)`;
        i = 350;
      } else {
        el.style.opacity = 1;
        el.style.transform = `translateY(${i--}px)`;
      }
    }, 1000 / time);
  };

  onMount(() => {
    let columns = [...document.querySelectorAll(".col")];
    scroll(columns[0], 20);
    scroll(columns[1], 15);
  });
</script>

<div class="sceneWrapper" transition:fade={{ delay: 450, duration: 600 }}>
  <div class="poem" transition:fade={{ delay: 2000, duration: 300 }}>
    <h1>The New Land (Closed Captions)</h1>
    <div class="intro">
      <p>
        <em
          >I’m totally seduced by Elisa Taber’s proposal to shoot a series of
          thirty-second films and transcribe what transpires. I say films but
          you know what I mean: digital moving image; personal perspective;
          ephemeral, arbitrary action. I love the unsettling effect of metonymic
          translation. Any literary treatment of remote ethnographies (remote to
          whom) must embed a polyvocal rendition of orality. To write
          hypertextually is to accept the author as a medium entering and
          exiting multiple sources, a scavenger operating in defiance of some
          feted organisation of meaning. Even though the product is delivered
          traditionally flattened it comes with reams of metadata that have the
          potential to re-estrange. That’s as it should be we might say.
          Non-narratives running concurrently, and where is the sense of place
          but in untapped joints.</em
        >
      </p>
    </div>
    <p class="sep">–––––––</p>
    <div class="inner-wrapper">
      <div class="col col-left">
        <p>
          One of the things that I like very much as I told you is how you use
          these seeds of myth and legend and bring them into intellectuality and
          into the present time and how these ideas that have been for centuries
          or for millennia even transform and act over people which came
          thereafter like Mennonites or the Spaniards who moved to Paraguay I I
          suppose a huge plane and is uh probably mostly empty but a lot of
          people come from different places like Brazil Argentina Paraguay
          Uruguay Chile and it’s a kind of uh like uh my imagine is a little bit
          like the some places in the Midwest of the United States that are half
          deserts and you know uh a word people go there for different reasons
          one some people for economic reasons some people for cultural reasons
          they want to do something in isolation so there must be a mix of uh
          very strange uh not only strange people but like French ways to
          understand life and strange ways uh strange projects live projects uh
          mixing there in this yeah I think so
        </p>

        <p>
          Yeah I think also like it myth is also just the myth of Paraguay is
          like what you’re saying is it’s I think pointing to that too and even
          the title and archipelago in Atlanta country is inspired by an essay
          by auto robotos which is in in many ways that the most well known
          Paraguay and author and and he spoke of an island surrounded by land
          in this essay because he he was both referring to the wars that cut
          off but away from its access to the sea but also to this idea that a
          that it was still an island that it was still secluded and that it was
          to be discovered and that it was empty and and I think that’s not only
          a something that occurs in Paraguay but I do think that whenever I’ve
          presented a this book it it it always seemed like a like something
          that that people came into their uh reading of the place with yeah
          yeah
        </p>

        <p>
          It’s very American actually not only for Hawaiian or South American
          but it’s very it’s a very community it’s a very American feeling
        </p>

        <p>
          Yeah and I think also at least to me what’s interesting about Paraguay
          also is like um this idea of a people coming to the place uh and and
          and doing something that maybe they didn’t intend to or that they
          don’t have the in the training to do er so there’s there’s a few
          authors that I’m thinking about like Rafael um who’s uh maybe you know
          him hellman so he’s a Spanish author and he came to Paraguay and also
          Argentina and a he was an anarchist and a and and wrote about these
          places in a very ethnographic way but also a very in literary way and
          he really captured like the essence of the place so the the Paraguayan
          pain is is the the book that he wrote about uh Paraguay and also and I
          think like it’s it’s interesting how this place could awaken that in
          him and yeah and then also a lot of people in the country maybe do not
          have the training to do a anthropology but they’re doing it and and at
          least for me like that is also just my personal history like I I came
          from writing and and translation um and when I was 23 I went to
          Paraguay and I visited the ethnographic library and I started doing
          research and I visited New England and I was taking a lot of notes and
          descriptions of people and things and and so I just began doing
          ethnography without an idea of what anthropology was and and also
          without an intention of doing anthropology I think that even if I
          subtitle the book a lyric ethnography it’s fiction and and and I think
          maybe in maybe this idea of like shifting disciplines isn’t only
          something that I’m interested in others it’s also something that I
          practice and we’ve talked about that a little bit Harman like um like
          once you feel comfortable in discipline switching and always use the
          medium in against what it was intended to be used <span class="last"
            >for</span
          >
        </p>
      </div>
      <div class="col col-right">
        <p>
          open vista a car pickup truck turns left a motorcycle follows cut to
          behind the two motorbikes a row of workshops from the front singing
          Anabaptist religious songs with subtitles thin moustachioed guitar
          playing man woman singing horse around the barrel slow motion black
          hat man looking talking lasso around cut to children weathered faced
          men man with glasses singing from hymn sheet silence we arrived here
          in Paraguay with the same ideas of the Bolivian Mennonites simply
          dressed pastors women in black we began to progress some people said
          we need to make changes they worked a lot of opposition as always
          we’ve had them we have to work with agriculture swinging green heel as
          they called the Chaco because it was green we don’t know if the name
          is Paraguayan in 1953 the settlers realised they had to
          institutionalise the cooperative service the community purchased new
          lands and it graded it wanted to go into farming a close up cow’s face
          everyone has a job in the colony they said there’s 40% unemployment
          here the other Paraguayan people live the indigenous people who work
          in our cooperative there are some indigenous who don’t work they don’t
          care about the following day they say in 1967 was created the
          association cooperative service of Mennonites of which approximately
          nine thousand indigenous the objective so that the families could
          achieve economic safety now in this area there is more indigenous than
          Mennonites because they have come from other places we have given it
          to them if you leave them by themselves the next day cutting trees
          chopping before this was virgin forest we almost without realising we
          realised too late we destroyed this virgin forest but now we want to
          put more attention there are places where we want to preserve it’s
          called civic cooperation for the people who live in the surrounding of
          the Mennonite colonies so go with them we look for projects to help
          them with agriculture with water they work in their communities we
          co-exist they are our neighbours we want to live in peace to give love
          to our neighbours now I should pay the personnel and they are cleaning
          it we sent a tractor and we sent the indigenous we cut them with an
          axe everyone puts in five thousand to pay the hospital if they get
          sick you stayed at home some days didn’t you and Wednesday yes were
          you yes how long will it be before we are the same rights we want to
          love the same living standards we want to find work in our indigenous
          communities the meeting of the two cultures indigenous to Mennonite
          was pacifist could face the widespread fear to fight child mortality
          this is the photo of the first missionary who came to this community
          the natives didn’t want to know they practice their traditions that is
          why the missionary came Christianism when the first Mennonites settled
          in our lands we began to lose our costumes to lose knowledge we have
          built schools we have evangelised those who live here haven’t changed
          their traditions however they have their roots but they don’t want to
          turn back we received the new religious teachings and that was a
          problem we changed god’s law doesn’t accept shamans and then will they
          save us at the end of the <span class="last">world?</span>
        </p>
      </div>
    </div>
    <p class="sep">–––––––</p>
    <!-- svelte-ignore a11y-media-has-caption -->
    <video src="./visuals/c9.mp4" autoplay muted controls />
  </div>
</div>

<style>
  .poem {
    max-width: 960px;
    margin: 10vh auto 100px;
  }
  .inner-wrapper {
    display: flex;
    justify-content: space-between;
    overflow-y: hidden;
    height: 350px;
  }
  .col-left,
  .col-right {
    width: 33%;
    font-size: 1.1rem;
    line-height: 1.4;
  }
  p {
    text-align: justify;
    max-width: 100%;
  }
  .sep {
    text-align: center;
  }
  video {
    margin: 40px auto;
    display: block;
    box-shadow: 2px 6px 12px rgba(0, 0, 0, 0.25);
  }
</style>
