<script>
  import { onMount } from "svelte";
  import { activeToast } from "../../store";
  let active = false;

  onMount(() => {
    active = true;
  });

  const close = () => {
    active = false;
    setTimeout(() => {
      $activeToast = null;
    }, 550);
  };
</script>

<div class="toast" class:active>
  <h1>It's Not Too Late to Invest in Calvinocoin</h1>
  <p>
    Calvinocoin is the revolutionary new cryptocurrency taking world markets by
    storm. Investors are making big returns – join them! Get a secure digital
    Calvinocoin wallet now, and start investing right away.
  </p>
  <button><a href="/">Join The Goldrush</a></button>
  <button id="close" on:click={close}>✕</button>
</div>

<style>
  h1 {
    font-size: 1.2rem;
    margin: 0 0 10px;
  }
  .toast {
    opacity: 0;
    transition: opacity 0.55s ease-in-out;
    position: relative;
    bottom: 250px;
  }
  .active {
    opacity: 1;
  }
  #close {
    position: absolute;
    top: 5px;
    right: -20px;
    border: none;
    background-color: rgba(255, 255, 255, 0);
    color: black;
    box-shadow: none;
    padding: 0;
    margin: 0;
    width: 1em;
  }
  #close:hover {
    color: darkgray;
    cursor: pointer;
  }
</style>
