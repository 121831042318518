<script>
  import { fade } from "svelte/transition";
</script>

<div class="sceneWrapper" transition:fade={{ delay: 450, duration: 600 }}>
  <div class="poem" transition:fade={{ delay: 2000, duration: 300 }}>
    <h1>
      I sincerely hope these exceptionally beautiful islands will remain sacred
      for all time
    </h1>

    <div class="intro">
      <p>
        <em>
          This place returns me to my memory of the lab and the eye colour
          experiment that didn’t work as it should have. I must not have sorted
          the test subjects properly to begin with. Nonetheless, that experience
          echoes for me in the way so many children kneel down in the perfected
          grass with a magnifying glass and tweezers, lifting decorative
          boulders and exclaiming.
        </em>
      </p>
    </div>
    <p class="sep">–––––––</p>

    <div class="poem-inner">
      <p>
        It’s very very important that we<span class="w12" />reveal this. <br />
        Google science. Unless you do<span class="w6" />the engagement <br />
        And get people aware of what’s actually<span class="w6" />happening in
        the water <br />
        If we want to do<span class="w8" />something about what’s going on
      </p>

      <p>
        In the ocean<span class="w8" />we really need to take people virtual
        diving<br />
        In the validation of data and imagery<span class="w6" />to really prove
        <br />
        What kind of vegetation what kind of birds are<span class="w6" />in
        certain areas of the island. <br />
        Those snakes almost ate that lizard.<span class="w12" />High knees baby!
        High knees!
      </p>

      <p>
        Be careful, it is a remote island<span class="w10" />so if you mess with
        them, they’re going to die.<br />
        Five minutes ago this was completely cleared &nbsp;&nbsp; you could see the
        steam you could see the sulphur <br />
        You could see all the lava. How does it look up there?<span
          class="w6"
        />Can you can you hear me? <br />
        Visit in Streetview but it’s so remote; very nice<span
          class="w6"
        />evolution, makes me happy.
      </p>

      <p>
        I believe that Darwin<span class="w10" />was right about his theory.
        <br />
        Whoever’s done this Google doddle<span class="w12" />it was good in 3D.
      </p>
    </div>
    <img src="./visuals/k8a.jpg" alt="Galapagos" class="poem-image" />
  </div>
</div>

<style>
  .poem {
    max-width: 960px;
    padding: 40px;
    margin: 10vh auto;
  }
  .sep {
    text-align: center;
  }
  .poem-image {
    max-width: 100%;
    margin: 20px auto;
  }
  .poem-inner {
    margin: 20px auto;
  }
  span {
    display: inline-block;
  }
  .w6 {
    width: 6em;
  }
  .w8 {
    width: 8em;
  }
  .w10 {
    width: 10em;
  }
  .w12 {
    width: 12em;
  }
</style>
