<script>
  import { onMount } from "svelte";
  import { activeToast } from "../../store";
  let subscribed = false;
  let active = false;

  onMount(() => {
    active = true;
  });

  const close = () => {
    active = false;
    setTimeout(() => {
      $activeToast = null;
    }, 550);
  };
</script>

<div class="toast" class:active>
  {#if subscribed}
    <h2>Thank you for subscribing!</h2>
  {:else}
    <h1>Sign Up To Our Newsletter</h1>
    <label for="newsletter-signup">Email:</label>
    <input type="text" id="newsletter-signup" />
    <button
      on:click={() => {
        subscribed = true;
        setTimeout(() => {
          close();
        }, 1000);
      }}>Subscribe</button
    >
    <button id="close" on:click={close}>✕</button>
  {/if}
</div>

<style>
  h1 {
    font-size: 1.2rem;
    margin: 0 0 10px;
  }
  .toast {
    opacity: 0;
    transition: opacity 0.55s ease-in-out;
    position: relative;
    bottom: 250px;
  }
  .active {
    opacity: 1;
  }
  #close {
    position: absolute;
    top: 5px;
    right: -20px;
    border: none;
    background-color: rgba(255, 255, 255, 0);
    color: black;
    box-shadow: none;
    padding: 0;
    margin: 0;
    width: 1em;
  }
  #close:hover {
    color: darkgray;
    cursor: pointer;
  }
</style>
