<script>
  import { afterUpdate, onMount } from "svelte";

  import { mapX, mapY, mapZ, mapActive, locationHistory } from "../store";

  let coord = {
    x: "",
    y: "",
    z: "",
  };

  let map, activeMap, mapWrapper;
  export let L;

  let zoomEvent = new CustomEvent("map-zoom-end");

  mapX.subscribe((x) => (coord.x = x));
  mapY.subscribe((y) => (coord.y = y));
  mapZ.subscribe((z) => (coord.z = z));
  mapActive.subscribe((a) => (activeMap = mapActive));

  onMount(() => {
    mapActive.update((a) => (a = true));
    map = L.map("mapid", { preferCanvas: true }).setView([$mapX, $mapY], $mapZ);
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      maxZoom: 19,
      attribution:
        '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>',
    }).addTo(map);

    map.addEventListener("zoomend", () => {
      console.log("zoomend");
      mapWrapper.dispatchEvent(zoomEvent);
    });
  });

  const updateHistory = (loc) => {
    let updatedHistory = [...$locationHistory, loc];
    $locationHistory = updatedHistory;
    localStorage.setItem("locationHistory", JSON.stringify(updatedHistory));
  };

  afterUpdate(() => {
    if (activeMap) {
      map.flyTo([$mapX, $mapY], $mapZ);
    }
    updateHistory({ x: $mapX, y: $mapY, time: Date.now() });
  });
</script>

<div id="mapid" bind:this={mapWrapper} />

<style>
  #mapid {
    filter: saturate(0);
    opacity: 0.5;
    mix-blend-mode: luminosity;
    position: fixed;
    z-index: 0;
    width: calc(100vw - 40px);
    height: calc(100vh - 40px);
    top: 20px;
    left: 20px;
    will-change: transform;
  }
</style>
