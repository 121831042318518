<script>
  import { scenes } from "../scenes";
  import NavLink from "./NavLink.svelte";
  import { locationHistory } from "../store";

  const format_history = (history) => {
    let new_array = [];
    history.forEach((h) => new_array.push(h));
    return new_array.reverse();
  };

  const get_location = (loc) => {
    let s = $scenes.find((s) => s.mapX == loc.x);
    return s ? s : null;
  };

  const format_date = (time) => {
    if (time) {
      let d = new Date(time);
      return d.toLocaleDateString("en-IE");
    }
  };
  const format_time = (time) => {
    if (time) {
      let d = new Date(time);
      return d.toLocaleTimeString("en-IE");
    }
  };
</script>

<ul class="history-window window">
  {#each format_history($locationHistory) as h}
    {#if get_location(h)}
      <li class="history-entry" />
      <details>
        <summary>{h.x} &nbsp;·&nbsp; {h.y}</summary>
        <p>
          Visited: {format_date(h.time)} &nbsp;·&nbsp; {format_time(h.time)}
        </p>
        <p class="nav-link-wrap">
          <span>Go:&nbsp;</span>
          <NavLink
            linkID={get_location(h).id}
            linkTitle={get_location(h).title}
          />
        </p>
      </details>
    {/if}
  {/each}
</ul>

<style>
  .history-window {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 250px;
    height: 80px;
    border: 1px solid black;
    z-index: 1000;
    background-color: white;
    overflow-x: hidden;
    overflow-y: scroll;
    list-style: none;
    padding-left: 5px;
    font-size: 0.75rem;
    opacity: 0.5;
  }
  .history-window:hover {
    opacity: 1;
  }
  summary:hover {
    cursor: pointer;
  }
  p {
    margin: 2px 0;
  }
  .nav-link-wrap {
    display: flex;
    border-bottom: 1px solid #bbb;
    margin-bottom: 4px;
    padding-bottom: 3px;
  }
</style>
