<script>
  import { fade } from "svelte/transition";
</script>

<div class="sceneWrapper" transition:fade={{ delay: 450, duration: 600 }}>
  <div class="poem" transition:fade={{ delay: 2000, duration: 300 }}>
    <div class="left">
      <h1>I'd love to be able to say 'I've been there'</h1>
      <div class="intro">
        <p>
          <em
            >I stepped away from the usual method of imagining a city for a
            special interlocutor. Instead, I tried to listen rather than
            dictate. I heard a ringing, coming from somewhere. There were so
            many surfaces that without special equipment it would have been
            impossible to determine the source and its location. I left feeling
            familiar.</em
          >
        </p>
      </div>
      <p>
        church bells can still be heard ringing <br />
        below the waters of the bay <br />
        you can hear the bells of the church ring <br />
        you can hear the muffled sounds of the city’s bells tolling <br />
        far beneath the waves<br />
        I’d love to gaze out over the water in the “correct” location <br />
        to try and hear its bells ringing <br />
        if you want to hear the bells <br />
        beneath the waves <br />
        try the left side of the beach <br />
        when the sea is calm one can hear <br />
        the bells of the church of ringing <br />
        from the depths of the bay<br />
        locals say that on a quiet day <br />
        the bells of the city can be heard <br />
        below the waves<br />
        they also said that when the weather <br />
        is very quiet one may hear the bells ring <br />
        from the disappeared city<br />
        the rise of a cathedral from the water <br />
        and subsequent return to the depths – complete <br />
        with bells chiming, priests chanting, and organ playing<br />
        folklore asserts that the bells <br />
        of the churches can still be heard<br />
        below the waters <br />
        of the bay <br />
        when it is calm we can just make out<br />
        amid the echoes and the afterthoughts, the quiet <br />
        ringing of the bells <br />
        from beneath the sea, <br />
        a haunting, mesmerizing sound<br />
        it’s almost as if we can hear the gentle <br />
        tolling of church bells <br />
        from well beneath <br />
        the surface of the sea<br />
        this opening sequence sounds incredibly akin <br />
        to the ringing of cathedral bells echoing <br />
        from beneath the sea<br />
        and then I read a little more <br />
        about the city and how the bells <br />
        of the cathedral, you can still hear them ringing <br />
        underwater on a still day<br />
        the drowned city, in the bay, <br />
        is said to have church bells which still <br />
        ring when the sea is rough <br />
        only the cathedral, symbol of the decent <br />
        and devout people, enjoys any kind <br />
        of afterlife, as it is said to rise up <br />
        out of the watery depths on clear days <br />
        at sunrise, the ringing bells and booming organ <br />
        audible across the expanse of the bay, <br />
        before sinking back into the sea by night<br />
        it’s still said that when the tide is low, <br />
        the towers of the city can be seen <br />
        just below the surface, <br />
        and that sometimes, the bells <br />
        from the cathedral can still be heard <br />
        ringing from the depths of the sea<br />
        it is said that on stormy nights, <br />
        you can hear the sound of the ghostly <br />
        bells ringing out across the waters<br />
        on the day it happens, the first person <br />
        who sees the church’s spire or hears <br />
        the sound of its bells, will become king <br />
        of the city and all its territory
      </p>
    </div>
    <div class="right">
      <img src="visuals/k10a.jpeg" alt="ys-1" class="ys-img" />
      <!-- svelte-ignore a11y-media-has-caption -->
      <video src="visuals/k10c.mp4" alt="ys-3" class="ys-img" muted autoplay />
      <img src="visuals/k10b.jpeg" alt="ys-2" class="ys-img" />
    </div>
  </div>
</div>

<style>
  .poem {
    display: flex;
    height: 100%;
  }
  p {
    font-size: 0.9rem;
    line-height: 1.4;
  }
  .left,
  .right {
    flex: 1 0 50%;
    width: 50%;
    height: 100%;
  }
  .right {
    display: flex;
    flex-direction: column;
  }
  .left {
    overflow-y: scroll;
    padding: 10vh 20px 100px;
  }
  .ys-img {
    height: 33.33%;
    object-fit: cover;
    border-left: 3px solid black;
    opacity: 0.85;
    border-bottom: 3px solid black;
  }
  .ys-img:last-child {
    border-bottom: none;
  }
</style>
