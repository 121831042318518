<script>
  import { fade } from "svelte/transition";
  import { activeToast } from "../../store.js";
  import Newsletter from "../toasts/Newsletter.svelte";
  import GraffitiDisplay from "../toasts/GraffitiDisplay.svelte";

  setTimeout(() => {
    $activeToast = Newsletter;
  }, 4000);
</script>

<div class="sceneWrapper" transition:fade={{ delay: 450, duration: 600 }}>
  <div class="poem" transition:fade={{ delay: 2000, duration: 300 }}>
    <div class="intro">
      <img src="./visuals/c3a.jpeg" alt="Elkhart" />
      <em>
        Attempting to categorise the poetry of Kimberly Campanello, recognised
        citizen of everywhere, in terms of place and time, brings us to the
        continuous here and now. Lines constantly crossed, extremes of customer
        behaviour negotiated, an entire traumatic epoch radically documented in
        sound and vision, the self too deeply secure to be performed. Scrolling
        around Elkhart and its all-American streets, collecting verbal footage
        that enacts deep partisanships (a microcosm), you come to realise the
        thing to do is to hire a vehicle and load up on pianos, saxophones and
        the like, and set forth to dissecting (in song, of course) the
        constellation of blind spots and dangerous roads taken in Irish poetry.
        Yes, again.
      </em>
    </div>

    <div class="gdpr">
      <h2>PLEASE READ</h2>
      <h3>451: Unavailable due to legal reasons</h3>
      <p>
        We recognize you are attempting to access this website from a country
        belonging to the European Economic Area (EEA) including the EU which
        enforces the General Data Protection Regulation (GDPR) and therefore
        access cannot be granted at this time.
      </p>
    </div>

    <div class="poem-inner">
      <ol>
        <li>the daily use of the plaza appears to lack any useful meaning</li>
        <li>
          the details and quality is completely different than a mass production
          fifth wheel
        </li>
        <li>the piano was once owned by a local church</li>
        <li>hopefully they will just replace Jennifer</li>
      </ol>

      <p>
        i recently became a citizen and changed my last name<br />
        i need a new ssc with my husband’s last name<br />
        and the court order so i did not even<br />
        i called back and the lady told me to wait a week<br />
        the guy was so rude and told me <br />
        so i said no i’m not doing that he just said<br />
        do my name chage? It’s so fricken frustrating
      </p>

      <p>
        groovin in the garden<br />
        a great place to work<br />
        the speakers fabulous<br />
        small crew and you’re not stumbling over everybody
      </p>

      <p>
        silent movies with the organist<br />
        a glimpse from a century ago<br />
        music with an instrument black <br />
        and white light hearted film fun<br />
        escape from modern day-to-day
      </p>
      <GraffitiDisplay />
      <p>
        great light show again this year<br />
        the go cart races were awesome<br />
        several people brought their pooches<br />
        paved walking trail! pond to walk around! wind chimes!<br />
        several barriers set up along the walking paths burning seasoned oak<br
        />
        the cocoa, warming stations, and live music made it<br />
        always lovely! they even had a man inside the tent<br />
        which might adversely affect the condition
      </p>

      <p>
        be sure to dress warm... gloves and a hat... several layers<br />
        the water could be colder to drink making it feel like an inner city<br
        />
        there are still homeless there asleep on the tables<br />
        lots of homeless and drug addicts<br />
        pointing and laughing at the phone while it rings<br />
        if you’re homeless don’t set here<br />
        a horrible smell<br />
        let’s not blame the workers<br />
        the homeless and ghetto people made our visit unenjoyable<br />
        the drawback is the crowd in and around
      </p>

      <p>
        I am a three-tour Combat Veteran and Purple Heart recipient<br />
        I called today about my piano and<br />
        where is my piano?<br />
        we didn’t know anything about pianos
      </p>

      <p>
        I upgraded the system<br />
        they fixed and painted the fiberglass crack and put a seem in<br />
        it will never crack again<br />
        it’s always fun to see the history and craftsmanship<br />
        put into mouthpieces<br />
        I took the tour with Vicki<br />
        despite getting caught red handed<br />
        we drove five hours to buy a camper<br />
        they’re telling me it’s in Georgia and I should schedule to get it soon<br
        />
        sorry for the delay due to the global pandemic which held up your transaction<br
        />
        much like a child denying they were in the cookie jar
      </p>

      <p>
        I love the river walk<br />
        I don’t
      </p>

      <p>
        watch for taste of gardens and concerts<br />
        great people, great service, just all around great
      </p>

      <p>any issues contact newsroom@elkharttruth.com or call 574-294-1661</p>
    </div>
    <div class="overlay" />
  </div>
</div>

<style>
  .poem {
    position: relative;
  }
  .intro {
    position: relative;
    top: 10vh;
    max-width: 740px;
    padding: 40px;
  }
  .poem-inner {
    position: relative;
    top: 15vh;
    padding: 40px 40px 140px;
  }
  .gdpr {
    position: absolute;
    width: 60vw;
    padding: 20px;
    text-align: center;
    height: auto;
    top: 25vh;
    left: 20vw;
    z-index: 101;
    background-color: white;
    border: 2px solid black;
    box-shadow: 2px 6px 12px rgba(0, 0, 0, 0.25);
  }
  .overlay {
    background: linear-gradient(rgba(255, 255, 255, 0), white);
    position: fixed;
    z-index: 100;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    pointer-events: none;
  }
</style>
