exports.handleStats = async (location_id) => {
	let api =
    window.location.hostname == "localmost"
      ? "http://localhost:4231"
      : "https://invisible-cities.fly.dev";


	console.log(location_id);
	
	let update_stats = await fetch(`${api}/stats/${location_id}`, {
		method: 'POST'
	}).then((res) => res.json());

	console.log(update_stats);

}