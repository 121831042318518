<script>
  import { fade } from "svelte/transition";
</script>

<div class="sceneWrapper" transition:fade={{ delay: 450, duration: 600 }}>
  <div class="poem" transition:fade={{ delay: 2000, duration: 300 }}>
    <div class="intro">
      <p>
        <em>
          This is the headquarters. She is everywhere on the road but not
          everywhere you’d like her to be. She is real but the image is of a
          red-haired pig-tailed head and shoulders. They removed her face
          recently and now it’s only a word, her name, on the signs.
        </em>
      </p>
    </div>

    <p class="justified">
      Your let’s sacrifice is too great you know why because I’ve had so many
      that worked as juicy as we can to trickle down your gentle little we’re
      sorry when you want to eat dinner at home dinner juicy have lots of meat
      and lots of your favourite toppings you see how and Wendy superfast pickup
      window can speed you home if you’re going now Wendy’s a philosophy give
      people a hamburger fixed the way they want it and don’t make them wait for
      it given that why would anyone go any place else ain’t no reason like that
      Mr Smooth here gets his without onions and give me a single Wendy’s
      hamburger with ketchup pickle unless forget the cash advance not tomato
      Wendy’s ain’t no reason to go any place I have nothing against heat lamps
      for sore backs but I don’t want one to bake my already cooked hamburger
      that’s why I come here to Wendy’s at Wendy’s the hamburger comes too hot
      off the grill you can see it with your own juicy hamburger Wendy I wanna
      shake your something bad no what now when making bacon Wendy’s is making
      bacon introducing Wendy’s bacon cheeseburger clean strips of bacon on top
      of a Wendy’s cheeseburger to become Wendy’s new hot and juicy bacon
      cheeseburger with bacon cheeseburger patient when you drive to Wendy’s and
      order a single you get more beef than the Whopper or Big Mac you never
      have to ask where’s the beef only at Wendy’s Wendy’s has a fresh approach
      to hamburgers because the hot and juicy Wendy’s hamburger is made fresh
      just for you for a limited time you can enjoy a quarter pound single
      hamburger with cheese regular sized serving of Coke and fries for just
      $2.99 that’s fresh dad David having no choice is no fun that’s wired
      Wendy’s every hamburger dressed the same fresh lettuce fresh onions cheese
      bacon and more choose fresh choose Wendy yeah yes I’d like a hamburger
      with extra tomatoes no pickle no onion and don’t get the same stop step
      aside step aside there step aside some hamburger places when you order
      special toppings they say you’ll have to wait and could you at Wendy’s you
      get a choice of fresh toppings without ever having to step aside
      hamburgers are made fresh with your choice of toppings Wendy’s new big
      classic hamburgers soft Kaiser bun the fat tomatoes the fresh toppings the
      fresh beef Wendy’s new big classic come celebrate new crispy chicken
      nuggets at Wendy’s Friday meaning start for everyone classic what Wendy’s
      they’ve never heard of you haven’t either classic introducing Wendy’s new
      big classic Kaiser bun fat tomatoes the fresh toppings defeat this is the
      good stuff crowd when you get kinda hungry son motor racing come to
      Wendy’s taste of summer combos hot and juicy big classic chicken sandwich
      quarter pound single special prize summer kinda hungry come to Wendy’s
      three taste of summer combos special summer prices would you choose
      hamburger a Wendy’s hamburger in which you get the fresh toppings you want
      or be where you take the toppings they give you I’m not used to getting
      what I want I went to the beauty parlour and asked for a glamorous hairdo
      I don’t think I got it what do you think I love it wouldn’t you choose the
      fresh hamburger choose Wendy’s Sir would you choose hamburger a Wendy’s
      hamburger that’s made fresh or one made earlier so it’s dry like Mom’s I
      remember she’d make dry roast beef dry gravy most people don’t like dry
      food yeah maybe that’s why Dad left most people like the taste of hot and
      juicy hamburgers like Wendy’s the best burgers in the business get a
      Wendy’s single for under a buck at all North Alabama Wendy’s locations
      this could put me out of circulation a single for under a buck order
      please you seen biker bars piano bars handlebars even sandbars but you’ve
      never seen super bar where you get all you can eat for one low price only
      in Wendy’s the best burgers in the business more now after 4:00 p.m you
      can have dinner at Wendy’s all you can eat super bar for only $2.99 it’s a
      dinner deal that lights up the night so tried tonight just for you great
      tasting hamburger crispy fries and your drink and just guess what else I’m
      going tomato I’m green peppermint now inside each Wendy’s kids meal a
      spring garden you planted and watch it grow after two weeks three weeks
      four weeks spring garden seed packets of Wendy’s you can collect this is
      the McDonald’s good burger but it costs more than two bucks this on the
      other hand is Wendy’s quarter pound single it’s made hot off the grill
      with fresh lettuce tomato my choices and for limited time only $0.99 now I
      can understand why a burger restaurant would want to charge more I just
      can’t understand why anyone would want to pay more Wendy’s hot and juicy
      quarter pound single just $0.99 until January 31st the best burger for
      your daughter we make the best hamburgers in the business but my daughter
      Wendy says dad people are eating more salads today so let’s make a great
      taco salad but let us in tomato cover with chilli and cheese so I said
      Wendy crashes which means that’s a heck of a good idea now try Wendy’s
      delicious taco salad to go our salads are the best in the business I would
      name the place after my daughter who puts smiles on faces silly places you
      had great things to eat with the taste is a real treat Wendy’s with a
      Frosty fries and hamburger on a bun that’s right when you get a Wendy’s
      kids meal we could get four different Playskool bristle block sets your
      own pure imagination Wendy’s kids meal each with a Playskool bristle
      blocks set you could collect all four introducing Wendy’s new SuperValu
      menu all under $1.50 gimme a break I mean chilli salad Frosties baked
      potatoes hamburgers with bacon with cheese Biggie fried drinks hey Biggie
      folks values one thing but great taste is something else wow Super Values
      they’re going to allow you to oh sorry I guess I’m old fashioned I think
      people should be able to eat good food without spending much money special
      low price Biggie drinks Biggie fries chilli including our new junior bacon
      cheeseburger delicious food that leaves change in your pocket what do you
      have to know to get a seat around here come drive the Super Value menu at
      Wendy’s Thomas is always on the lookout enjoy the new ideas in food sure
      what if you’ve got a big appetite Dave’s deluxe with bacon sauteed onions
      the works only at Wendy’s after this everybody is getting into shape these
      days riding that extra mile pumping a little more iron doing whatever it
      takes to stay fit and Wendy’s can help introducing Wendy’s new grilled
      chicken fillet made from a whole breast of chicken lettuce and tomato and
      topped with honey mustard on a toasted bun it’s delicious way to stay in
      shape you know what I like about exercise I like Wendy’s new grilled
      chicken fillet grill to be great Tom Brady my daughter’s name maximum I’m
      an old guy when families have dinner together that’s why Wendy super bar
      makes so much sense now everyday after 4:00 p.m. all the pizza you can eat
      at all the Pepsi you can drink for one low price my daughter when he said
      dad the super bar is such a great deal people are going to want to eat
      here every night I said Wendy that’s what we’re hoping for I bet includes
      Wendy’s all Americans hot dogs just $0.99 summer which means it’s time for
      hot dogs and Wendy’s has them topped with mustard relish and onions plus
      $0.99 Biggie drinks Biggie fries and Frosties coming for the oil American
      beef hot dogs on Wendy’s Super Value menu my daughter when he said to me
      dad I think you’ve got to come up with some new kind of foods being
      different a cutting edge right so I had an idea for a special chicken
      sandwich introducing Wendy’s chicken cordon bleu a bonus rested chicken
      ham Swiss cheese great proponent mayonnaise on a toasted Kaiser father in
      the kitchen on the cutting edge private new chicken cordon bleu sandwich
      at Wendy’s for a limited time Dave Thomas travels the world over looking
      for new food for Wendy’s excuse me when I ordered and it’s not easy big
      Dave selects each back up in quarter pound of fresh beef cheese three
      strips of bacon sauteed onions the works only at Wendy come in for another
      deluxe today hey hi we’re looking for new food ideas for Wendy boy I sure
      could go for a big Dave a quarter pound of fresh beef cheese three strips
      of bacon sauteed onions but works only at Wendy’s got ketchup now you can
      get Wendy’s big quarter pound single but just $0.99 when you order any
      size fries and a soft drink do you think we’re crazy Wendy’s old fashioned
      quarter pound single served hot off the grill with your choice of fresh
      topping I’m going crazy just order your favourite stop breaking any size
      fries and get a hot and juicy quarter over $0.99 please hurry I don’t know
      how much longer I got bored of the crazy Wendy’s old fashioned quarter
      pound single now just $0.99 with purchase of any size fries in the soft
      drink Dave Thomas travels the world over looking for new foods for Wendy’s
      but what I ordered tonight with a fire and it’s not easy big Dave 1/4
      pound of fresh beef cheese three strips of bacon sauteed onions the works
      only at Wendy’s come in for another big deluxe today it’s Dave salad days
      at Wendy’s with five great salads to go like our taco salad deluxe garden
      salad and a new grilled chicken salad made fresh everyday you keep eating
      picking picking and picking seems to me everybody’s been using our pick up
      window to get Wendy’s new salads as we make him fresh everyday like our
      taco salad deluxe garden salad in our new grilled chicken salad but you
      can get him inside too it’s Dave salad days and Wendy’s inside and out boy
      I had come a big year in my garden so we’re going to be making a lot of
      salad at Wendy’s it’ll be a nice change of pace taco salads with rich and
      meaty chilli deluxe garden salad and a delicious new grilled chicken salad
      with bread sticks my daughter Wendy’s idea made fresh everyday because
      that’s away their best each day salad days at Wendy’s five salads that are
      fresh fast and ready to go think I used too much water introducing Dave’s
      salad days at Wendy’s no lot of people are getting Wendy’s new fresh
      salads to go through to pick up window I guess that’s good because we’ve
      got a lot of salads taco salad with rich and meaty chilli the less garden
      salad and a delicious new grilled chicken salad for the bread sticks my
      daughter Wendy’s idea and we make them everyday for people on the go 5
      salads that are fresh fast and ready to go boy it’s amazing how much
      broccoli you get from low bigger seats who would think that so sizzling
      bacon slice of Swiss and put them on 1/4 pound of fresh beef who would use
      the freshest toppings and add a special sauce who would serve it all up on
      a toasted Kaiser button and call it bacon Swiss Wendy’s dad that’s who
      come in and try the Swiss only at Wendy’s now Wendy’s has nine choices for
      $0.99 each like a junior bacon cheeseburger Biggie fries and baked potato
      side salad and junior cheeseburger deluxe Super Value menu things cause
      it’s nice to get a break once in awhile dear Wendy summer vacation week
      two I’m having a great time take a lot of photos with some new friends
      afterwards everyone was hungry luckily there was a Wendy’s nearby we had
      junior bacon cheeseburgers I treated because everything on the Super Value
      menus just $0.99 each I finally broke 100 love Dad oh sorry Wendy Super
      Value menu the best place to spend your summer Wendy’s spicy chicken yeah
      it’s different days different he’s a rebel they zig zag Wendy’s new spicy
      chicken sandwiches a whole breast specially seasoned with Dave’s own blend
      of pepper spices days spicy that’s intriguing and you’re hot you’re hot
      Wendy’s spicy chicken fillet sandwich it’s caused quite a stir a man his
      age shouldn’t be making spicy food Amen hi everybody OK we’re all worried
      about what you have and ordered your holiday here for my store cookies I
      made for you know your turkey nothing just making a new big bacon classic
      combo what about a fresh three strips of bacon and the works with Biggie
      fries and a cold drink so this holiday save your appetite and I thought
      you forgot my start who could forget try Wendy’s big bacon classic combo
      today eggs bacon a stack of pancakes and coffee new bullet baking class
      ohh yeah just call me it’s a whole quarter pounder fresh processed cheese
      lots of copies how many three big strips of bacon chicken classic if
      you’ve got a big appetite for big bacon cheeseburgers at this exit but
      remember come hungry you didn’t finish breakfast Tanya why is Dave Thomas
      up at the crack of dawn thank you he’s getting fresh vegetables for
      Wendy’s garden spot salad bar ripe tomatoes Chris cucumbers you can make a
      fresh salad that Wendy’s unless you get a good day already remember the
      twins my friend Luke how are exchange students happening start when we go
      to Wendy’s number Wendy’s I something for everyone like junior bacon
      cheeseburgers and season side salad on the $0.99 Super Value menu
      hamburger kids meal for $1.99 chilli delicious food you can only get here
      plus for $1.99 get a hamburger kids meal great Wendy’s you don’t pay more
      you just get more skating partner what do you say winning gold medal
      figure skating in 1992 but no pairs much anyway got a full afternoon you
      know when these $2.99 old fashioned quarter pound single combo made fresh
      to order with Biggie fries in a cold drink oh boy single combo $2.99
      hungry get a Wendy’s $3.99 old fashioned quarter pound single combo served
      hot off the grill with Biggie fries and a medium drink it’s the perfect
      answer to the question what’s for lunch it works for me on a recent trip
      Dave Thomas fell in love with San Francisco that city by the Bay besides
      the people and a delicious roll although introducing Wendy’s sourdough
      bacon cheeseburger it’s a San Francisco style roll stuffed with a quarter
      pound of fresh beef monterrey jack bacon and Dijon sauce come to Wendy’s
      and try one soon hopefully they will be back by then it could happen at
      anytime that craving for Wendy’s bacon mushroom melt a quarter pounder
      fresh piece 3 strips of bacon and mushrooms in a cheddar cheese sauce when
      you gotta have one you gotta have one is Wendy’s announced a new chicken
      sandwich guacamole was the word I’m the coach I’m talking mushrooms
      introducing Wendy’s chicken bacon Swiss a whole 3 strips of bacon Swiss on
      a tiny sauce have a clue I just wanted to make it delicious had a few
      things to learn about potatoes loved across the Carolinas so let me get it
      straight to chilli and close log on top of the quarter pound hamburger and
      then I put on dropped onions plus Munster that’s the way we like it best
      presenting Wendy’s Carolina classic because they couldn’t wait to bring
      you the taste loved across the Carolinas just remember the three seas
      showy coleslaw chopped onions come try Wendy’s Carolina classic today Dave
      now that my wife is working on Saturdays I’ve got the boys all day and
      that includes lunch anyway it’s been an eye opener luckily the $2.89
      hamburger kids meal at Wendy’s makes a delicious tasting meal they get a
      great hamburger fries and a drink or junior Frosty plus a toy they like as
      much as they like the food but we’re running out of things to do in the
      afternoon any suggestions how bout a map Wendy’s we’ve got delicious
      tasting kids meals including our cheeseburger and $2.89 hamburger kids
      meal with one $0.99 Super Value menu get three things a lot of choices
      delicious food and a great price ohh wait there’s one more thing now you
      can choose Wendy’s crispy chicken nuggets 5 tender pieces of Wendy’s or
      white meat crispy on the outside juicy on the inside delicious with one of
      our four savoury sauces Wendy’s crispy chicken Nuggets just $0.99 on
      Wendy’s Super Value menu better food makes it a better value Dick Clark
      I’m a big eater now tell the court where you were for lunch Tuesday I was
      eating a Wendy’s big bacon classic combo you know quarter pounder fresh
      beef three strips of bacon cheese toppings Biggie fries and a drink sounds
      like a big meal did you finish it every bite really well liar the fraud
      was too big for you Stan Wendy’s big bacon classic combo is only for big
      eaters let this happen to you the cheese world feta cheese is being Dave
      anything else try the pepper jack wow cheeseburger give me 1000 pounds
      sliced okay and spice it up he did it’s Wendy’s new pepper jack bacon
      cheeseburger a quarter pounder fresh ground beef spicy pepper jack cheese
      three strips of bacon and the perfect sauce rate cheeseburger day sure
      beats better sure beats feta come try Wendy’s new pepper jack bacon
      cheeseburger today day passes want to put on Wendy’s new bacon
      cheeseburger hey something mild plants say come on don’t be a wimp give it
      some saying ohh no good bullet be nice spice it up pepper jack ohh my well
      Dave started cooking a quarter pound of fresh beef three strips of bacon
      the perfect sauce and a slice of pepper jack cheese or a bacon
      cheeseburger that’s really spicy ohh Dave be careful my own business
      attaboy Wendy’s new pepper jack bacon cheeseburger gotta be the cheese
      welcome David along time you got a spicy chicken sandwich but here try
      this got bad water no thanks now try Wendy’s spicy chicken Wendy’s spicy
      chicken sandwiches a whole breast fillets season with Dave’s own blend of
      pepper and spices it’s one very delicious very spicy sandwich place hi
      Wendy spicy chicken sandwich and Long Live the King my brother told me to
      watch his chicken nuggets for a minute so no one needs them but when
      someone gets it’s hard not to try these chicken nuggets for $0.99 who ate
      my nuggets thought you might be hungry happy holiday happy holidays God
      this holiday you deserve a big hearty here like Wendy’s smoky bacon
      cheeseburger combo three strips of hickory smoked bacon smoked cheddar on
      1/4 pounder fresh beef with sauteed onions made right when you order it
      plus Biggie fries and a drink come in bro Wendy smoky bacon cheeseburger
      combo today what a nice way to say happy holidays you know nothing beats a
      great cheeseburger except maybe a great cheeseburger for just $0.99
      introducing Wendy’s new 99 centre quarter pound double stack it’s got
      everything that makes a cheeseburger great plus two patties of fresh beef
      served hot off the grill the 99th and quarter pounder double stack it’s a
      stack of beef for a little price and it’s only on Wendy’s $0.99 Super
      Value menu if you love beef this one really stands out you don’t see this
      everyday but you do see Wendy’s $0.99 Super Value menu everyday with
      choices like crispy chicken nuggets side salad or hot baked potato and
      they’re always $0.99 to be or not to be you don’t see this everyday that’s
      the question but you do see the junior bacon cheeseburger and rich and
      meaty chilli on Wendy’s $0.99 Super Value menu everyday where everything
      is always $0.99 whether tis noble lyrics Dave Thomas had a problem should
      his new bacon cheeseburger have a slice of cheddar or a creamy cheddar
      cheese sauce add with sauce will do both introducing Wendy’s delicious new
      cheddar cheddar cheeseburger with two kinds of cheddar 3 full strips of
      bacon because nothing tastes better with cheddar all on 1/4 pound of fresh
      beef made right when you order it I agree it’s not often you get to make
      everyone happy try when it’s too hot and juicy cheddar cheddar
      cheeseburger today are you hungry yet something different hungry at least
      try engage haven’t heard Wendy’s has fresh stuff like our garden ranch
      chicken with big tender chunks chicken seasoned with shredded parmesan
      classic Greek word feta cheese and our delicious veggie question we need
      there pita at Wendy’s thanks Dave it’s everything you love slept in a big
      warm soft pita selling lots of day gets around come out and try one today
      are you looking for that red hot gift this holiday that gift that’s hotter
      than hot the one everyone’s then come the Wendy’s and get a spicy chicken
      sandwich it’s very hot and why is there say holidays it’s funny how things
      come to you tonight I suddenly thought hey we should make a patty melt at
      Wendy’s a quarter pound of fresh beef sauteed onions special sauce and two
      slices of melted Swiss on a toasted Kaiser bun served hot off the grill a
      combination that can’t be beat we were going to call it a patty man I
      thought no let’s call it a windy man it’s got more zip come in and try the
      new Wendy milk today what is that looks good press play it sure is
      believable hey did you get that here no Wendy’s we Chris I’m hungry gonna
      commercial introducing Wendy’s new honey ham and chicken sandwich chicken
      doesn’t get any better than this but you have to go to Wendy’s together
      how do I check for you man try Wendy’s new honey chicken mother please
      excuse me chilli when he’s cold nothing but a hot bowl of Wendy’s rich and
      meaty chilli will do so we keep it simmering all day long and it’s just
      $0.99 on our Super Value menu chilli no big potato and sour cream and
      chive baked potatoes slow baked in an oven $0.99 Super Value menu 10
      delicious choices everyday thinking about something hot chicken sandwiches
      season with a blend of pepper and spices so you’ll feel warm thinking
      about you’re hot you’re hot due to circumstances the under control
      tonight’s game is cancelled pick your skates on what’s up coach the games
      postponed what do you mean some guy came in with a Wendy’s spicy chicken
      clear all there good it was so hot the ice melted again Wendy’s spicy
      chicken is a whole breast fillets seasoned with Dave’s own blend of pepper
      and spices makes one hot sandwich it’s the second time this week they
      sorry Wendy spicy chicken when you’re hot you’re hot hi there junior bacon
      cheeseburger with two full strips of bacon made fresh to find fresh fresh
      ingredients do you mean fresh at one time no I mean made fresh now we
      don’t do that fresh at Wendy’s fresh means fresh so our duty bacon
      cheeseburgers made right when you order it it’s the best nighty night sent
      you can spend your view afresh it’s very narrow really the $0.99 Super
      Value menu at Wendy’s it’s better here and our pick up windows open till
      midnight or later so you can eat great even late for a quick bite or
      enough to feed an army when people want delicious varieties made fresh
      they turned the Wendy’s $0.99 in Super Value menu any Super Value menu is
      there for you sit down and listen to your inner voice my inner voice has
      honoured my inner child mine says love everyone my inner voice says I like
      a Wendy’s bacon mushroom out David you need to go deeper OK the big prize
      at a drink right my voice is saying that to my hungry Wendy’s bacon
      mushroom out with three strips of bacon and sauteed mushrooms in a cheddar
      cheese sauce is like a voice that can’t be denied when you gotta have one
      you gotta have one boy talk about inner peace like introducing Wendy’s
      cheddar lovers bacon cheeseburger it’s three shifts of bacon sauteed
      onions two slices of cheddar and cheddar cheese sauce come in and try was
      today all you have to do is say cheese welcome to the world it’s just for
      kids trying handled a hamburger or the crispy Nuggets next fried rice
      cooler strides mounted Wendy’s kids meals are just for kids and so is once
      inside Bobby’s world toys don’t you know there can imagine body blast
      enough as an astronaut or join him on the forbidden planet chicken in the
      alien air for cooties even ever videos what do English cheddar experts say
      about Wendy’s cheddar lovers bacon cheeseburgers really really good
      mistake not trying to get my face found it but it’s quite a job yes yes
      cause not some chattering tomorrow text burger speaking as lovely gorgeous
      Wendy’s cheddar lovers bacon cheeseburger is very good for something from
      the colonies you’re in a mood for baked potato and a big drink yeah at
      Wendy’s we know so much about what you like for lunch sometime we can
      guess big barbecue this weekend so you’re just having five chicken nuggets
      for $0.99 on the side salad how do you do that each year 90 nights and
      Super Value menu is such a great value you get exactly what you want
      believable like junior bacon cheeseburgers made when you order them that’s
      uncanny Wendy’s 99 Super Value menu is made for you across the no that
      would be my next guess well yeah but I’ll be right back these bacon
      mushroom now I know you have fun yeah stay out a few more laps with three
      strips of bacon and mushrooms in the cheddar cheese sauce it’s impossible
      to resist now where’s that sandwich Wendy’s bacon mushroom melt when you
      gotta have one you gotta happen I hope not chilly chilly you look down
      right cold no bowl of chilli yes let me get my boots will go to Wendy’s
      only Wendy’s 99th in Super Value menu has rich and meaty chilli simmering
      all day and baked potatoes hot from the oven baked potato yeah Wendy’s I
      go with you but I just had some chilli I’m full it was Hardy thank you
      though bye come in for Wendy’s Super Value menu the best $0.99 you can
      spend remember Wendy’s pick up windows open late so you can eat great even
      late shut up I miss my house I called the Calvin’s House of Cheddar this
      is my wallet cheese I love cheese as you can see ohh boy cheddar England
      Ground Zero fresh cheddar lovers you love cheddar Jenna loves bacon
      cheeseburgers my first she said happened I got from my dad my bacon show
      hate onions and tons of cheddar the man who put the two e’s and cheese
      Dave Thomas come try Wendy’s channel of his bacon cheeseburger cheese and
      now Wendy’s pick up windows open late so ever wonder why people get sleepy
      after lunch maybe it’s what they need if it’s dull and boring why bother
      facing the rest of the day hello Wendy are Eleanor wicked parmesan sauce
      on a whole breast play that so delicious you are bursting with go get him
      this Wendy’s mozzarella chicken supreme wake up in haste the chicken he
      should have stopped at one remember Wendy’s pickup window is open late so
      you can eat great even later love your hair nice shirt out thanks it’s
      natural when you need a Wendy’s mozzarella chicken supreme you just feel
      good great time thanks my mom gave it to me with this whole breast fillet
      mozzarella and promises an SOS call you feeling me I’m feeling great picks
      up the whole day I do my Wendy’s mozzarella chicken supreme and have a
      nice day in town remember Wendy’s pick up windows open late so you can eat
      great even late are these kids meal your pants kicked me off you collect
      all 5 what did you do this weekend I called this girl but the line was
      busy Gary and I went to Wendy’s for lunch well I see the hamburgers on TV
      and look good I ordered a classic double with cheese call me crazy but I
      think they made it just for me hot juicy it tasted great and when I
      finished I felt and I like that feeling from when these classic double
      with cheese and now Wendy’s pick up windows open late so you can eat great
      even late this mission is numbered a Wendy’s kids meals are just for kids
      no don’t you look at Wendy’s kids meal and see what’s inside Wendy’s kids
      meals are just for kids no don’t work at Wendy’s kids meal and see what’s
      inside now the adventures of Pokémon are in Wendy’s kids meals we’ve
      trained this Pikachu to tell time and his friends help your quest with his
      cool compass collector card and you can store your favourites in this
      treasure box there’s one Pokémon toy with collectible card and everyone
      these kids meal you can catch all 5 when you get up here to the stop sign
      okay good put your signal on no signal alright okay will go right okay
      here align straight out and stop clear windows down in order to be a
      Wendy’s bacon mushroom milk and I’m buying for everybody when you gotta
      have that bacon and mushrooms in a delicious cheddar sauce you gotta have
      it entertaining too good Wendy’s is better here and I’ll pick up windows
      open to 1:00 a.m. or later so you can eat great even you want a more
      personal note I think we don’t like to spend more time with our kids
      almost he wouldn’t sit down over a nice meal together my favourite places
      junior bacon cheeseburgers side salad chicken nuggets there $0.99 each so
      lunch doesn’t cost much nice after the game are you with Wendy’s
      officially the $0.99 Super Value menu at Wendy’s it’s better here I’m
      almost done I pick up windows open till midnight or later hello we’re
      going door to door to remind people about Wendy’s pick up window don’t you
      have anything better to do actually look how many times have you been
      forced to eat something you didn’t want later happens all the time he’s
      not make you really good classic double with cheese fresh instead of
      something he really didn’t want anyway you’re with Wendy’s officially so
      does anyone else hold yeah sometimes classic hamburger always made fresh
      I’d like a classic single combo but instead of fries I’d like a baked
      potato no problem introducing combo choices only from Wendy’s choose from
      five sides cost gave Tom always said if you’re a Wendy’s second it just
      can’t like Wendy’s spicy chicken sandwich bold chicken breast fillet
      covered with their own fiery blend of cayenne chilli peppers and spices
      for more meat and more he satisfy your craving for spicy with Wendy’s
      original spicy chicken sandwich your job is you do Wendy’s introducing
      Wendy’s double melt in the middle there’s pepper jack cheddar sauce bacon
      and jalapenos with every double melt receive a dollar on a gift card for
      flavour where accounts do what taste they say they’re going to Wendy’s you
      say hey bring back chilli in a baked potato and they do and we love them
      it’s so thoughtful because Wendy’s chilli is simmered on a stove and made
      with care and baked potatoes are pulled from the oven and covered with
      broccoli and cheese or bacon and cheese we can have a parade for them but
      next time bring them that Wendy’s chilli and a baked potato and say hey
      thanks for doing what tastes right it’s warm chicken tastes good cold
      crunchy veggies taste good when the warm chicken cool crunchy salad taste
      good good introducing the new chicken salads from Wendy’s hand cut fresh
      lettuce a tender centre cut chicken breast fillets sliced and served warm
      it’s way better than fast food it’s Wendy’s when you’re on the go you need
      a one handed snap with you and it taste Wendy’s new chicken go wraps
      they’re not strips like some other rats they are made with only tender
      centre cut chicken breast in homestyle spicy or grilled each just $1.49 is
      a way better than fast food it’s Wendy’s now eat way better way later is
      hamburgers were meant to be frozen wouldn’t cows come from Antarctica
      Wendy’s hamburgers are made with fresh never frozen beat who else can say
      that it’s way better than fast food Wendy’s coffee twisted Frosty Wendy’s
      new coffee toffee twist cool creamy Frosty handspun with real coffee
      flavour Wendy’s $0.99 sausage and egg burrito the most important meal of
      the day you mean breakfast is the most important meal no a $0.99 sausage
      and egg burrito is the more money you save in the morning the more money
      you have later in the day that is important it is if you want any lunch or
      dinner or pay rent filled with fresh grilled sausage and eggs Wendy’s
      sausage and egg burrito is $0.99 that taste like more it’s way better than
      fast food Wendy’s when it’s raining you know not nice morning tray what is
      it $0.99 introducing $0.99 spicy chicken nuggets all white meat season
      with peppers and savoury spices but the real kick he get 5 pieces for only
      $0.99 only at Wendy’s introducing Wendy’s new $0.99 everyday value man how
      about a beefy double stack or maybe a crispy chicken sandwich now there
      are seven tasty choices from $0.99 how much choices real value everyday
      introducing Wendy’s new $0.99 everyday value man how about a busy double
      stack or maybe a crispy chicken sandwich now there are seven tasty choices
      from $0.99 how much real choice is real value everyday where is the beast
      where is the beach introducing Wendy’s thickest hottest juices
      cheeseburgers Dave’s hot and juicy new toppings but shared real chipotle
      and red peppers onions and Mediterranean oregano a touch of amber honey
      all in our authentic spicy chipotle sauce that’s worthy of our all breast
      meat chicken together introducing Wendy’s spicy chipotle boneless wings
      real is in the ingredients and try the sweet and spicy Asian or honey
      barbecue there’s nothing quite like the Frosty have a cool creamy Wendy’s
      Frosty today another day another dollar and we make that dollar work
      harder for you everyday with Wendy’s $1.89 everyday value fries with
      junior bacon cheeseburger and crispy chicken nuggets come see how good
      $1.89 continues new breakfast starts with all natural ingredients grilled
      sausage all natural fresh cracked eggs natural hollandaise sauce natural
      try Wendy’s new artist today wonder if there’s more out there lunch says
      it all what’s it say says you can do so much better right I should
      literally your lunch pick your meal to the next level with Wendy’s
      delicious new signature size chilli cheese fries or make sweet potatoes
      sides that make the meal better I do that too he’s tried this no yup it’s
      kind of like visiting old friends some of them a little older than others
      you know Wendy’s has this summer berry salad is made fresh everyday it is
      so good why settle when Wendy’s berry chicken salad is made with fresh and
      cut strawberries plump blueberries fresh tastes made fresh everyday that’s
      Wendy’s way better no matter who you are no matter how you say it fresh
      just for you always hot fresh never frozen beef at your choice of your
      cheeseburger should be special like you usually I need a fresh breakfast
      on my morning I’m always in a rush I need a fast breakfast I’m always on a
      tight budget and I said I was most to fit I have a solution morning
      original with chicken sausage or with smoked beef strips ready fresh food
      may trust you’re happy about that no but the courthouse is right next to
      that little pub with those amazing cheeseburgers yeah like these did you
      guys country duty Wendy’s can I plead insanity now especially crafted
      cheeseburger is closer than you think when you speak pretzel bacon
      cheeseburger with melted cheddar and smoky honey mustard all in a warm
      soft pretzel bun now that’s better we’re open late best friends Wendy’s
      pretzel pub chicken look it’s an offensive but now lightly breaded chicken
      natural Munster cheese honey mustard and warm cheddar sauce Wendy’s new
      pretzel chicken that’s better and now kids meals just $1.99 after 4 oh
      here’s one movie turns out he’s an alien the whole thing one big dream how
      about the one where a group of friends encounter something totally
      unexpected very spicy was it end with you driving us to when ranch chicken
      club is totally unexpected our bold applewood smoked bacon and natural
      last cheese way past ordinary that’s Wendy’s way that’s better and now get
      a Frosty for just $0.99 you look very handsome I told him we were having
      smoked gouda chicken tender chicken rich smoked gouda Wendy’s new smoked
      gouda chicken you look very pizza I told him we were having smoked gouda
      chicken it’s a rental tender chicken rich smoked gouda Wendy’s new smoked
      gouda chicken when I was young never need it and having lunch stress for
      those days are gone anymore dry tears Wendy’s pretzel buns back insert up
      just the way you remember it get it love it gourmet lunch back from
      Wendy’s wow okay actually take video my web friend I’d rather share it
      with my taste buds grilled chicken now at Wendy’s warm grilled chicken on
      toasted flat bread into bold new tastes as right from Wendy’s better and
      we’re open late hey hi what you in the mood for steakhouse also how much
      you get as in like steakhouse cheeseburger from Wendy’s change for the bus
      when his new steakhouse junior cheeseburger deluxe which is $1.49 with
      fresh steakhouse seasoned beef and garlic your dollars more delicious here
      from the people who brought you the baconator it’s Wendy’s baconator fries
      just $1.99 freshly cooked applewood smoked bacon joins forces with melted
      cheddar to take fries to another dimension this summer baconator fries at
      Wendy’s near you when you freeze something it’s for later that’s why when
      you want a hamburger now Wendy’s makes it for you with fresh beat never
      frozen that’s the way Dave Thomas did it when he founded with making
      hamburgers that are juicy delicious and well square of course so while
      somethings are tastier when frozen nearly 50 years later Wendy’s is still
      doing beef the fresh never frozen way because staying fresh never gets old
      Wendy’s doublestack is still an option in the four for four with a quarter
      pound of fresh beef that’s a deal so good it should not exist like how
      owls shouldn’t be able to turn their heads all the way around wouldn’t
      like that deal the double stack in the four for four deal so great is
      impossibly good but not for long when is four for four is now better than
      ever because it’s the deal with more variety the network with eight
      different sandwiches to choose from plus nuggets fries and a drink so
      choose a double stack or the crispy chicken sandwich or grilled chicken go
      round or the junior bacon cheeseburger or any of these other whatever you
      choose you still get this much food offer just $4 so try all and pick your
      favourite it takes what makes the new four for four deliciously different
      only in planning for dollars
    </p>
    <img src="./visuals/k7a.jpeg" alt="Hamburger" class="poem-image" />
  </div>
</div>

<style>
  .intro {
    max-width: 600px;
    margin: 15vh auto 40px;
  }
  .justified {
    text-align: justify;
  }
  .poem-image {
    margin: 20px auto;
    display: block;
  }
</style>
