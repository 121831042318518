<script>
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";

  const scan = (el, h, w) => {
    let new_x = Math.floor(Math.random() * w);
    let new_y = Math.floor(Math.random() * h);
    console.log(el, h, w, new_x, new_y);
    el.style.transform = `translate(${new_x}px, ${new_y}px)`;
    setTimeout(() => {
      scan(el, h, w);
    }, 1000);
  };

  onMount(() => {
    let iw = document.querySelector(".image-wrapper img");
    let h = iw.getBoundingClientRect().height;
    let w = iw.getBoundingClientRect().width;
    console.log(h, w);
    // scan(iw, h, w);
  });
</script>

<div class="sceneWrapper" transition:fade={{ delay: 450, duration: 600 }}>
  <div class="background" />
  <div class="image-wrapper">
    <img src="./visuals/c1.jpg" alt="Travel Hostel Flyer" class="poem-image" />
  </div>
  <div class="poem" transition:fade={{ delay: 2000, duration: 300 }}>
    <h1>Sunday 31 August 1997</h1>

    <p>
      <em
        >Fortunately I don’t need to rely on memory because I opted to write. I
        made a travel notebook and collected maps, paraphernalia, addresses,
        refusing to take photos. Strangely much of what I remember is missing
        from my journals. The maps too, I couldn’t find them at first. The
        hostel as I remembered it didn’t appear to exist in digital times. Even
        the street itself didn’t appear to exist except in a town hundreds of
        miles away. Later, on recovering a bag of mementoes deep in a drawer
        next to my bed, I realised I’d misspelled the street name and that not
        only does it still exist, but the hostel exists also, only now renovated
        and respectable. You were probably reading Rimbaud round about then, but
        poetry was far from my mind. That travel journal, I have just been
        reminded, was my first sustained piece of writing.</em
      >
    </p>

    <p class="inset-1">
      not modern or corporate but charming<br />
      at night a little noisy<br />
      if you’re a scary person about the light<br />
      sorry we cannot help with this
    </p>

    <p class="inset-1">
      a bit old school but oldies is goodies<br />
      people and passing cars<br />
      of various ages crawl across the wall and the dead<br />
      (photos attached)
    </p>

    <p>
      Woke up very late and missed breakfast. Me, James and Matt went to sit in
      the common area, on the stairs, when the girls at reception told us they
      had some news for us. Diana was dead. “Yeah, OK” was the spaced out
      answer, but soon they tuned to BBC World Service on the radio which
      convinced us.
    </p>

    <p class="inset-3">
      very grave after car crash during a high speed chase early today the
      ambassador is anxiously waiting news found slumped in the back passenger
      on a journey to disaster how the inside of the limo was full of blood
      eyewitnesses told cars slammed into a wall instantly killed unsuccessfully
      given a heart massage
    </p>

    <p>
      I’m certainly glad I’m not in Britain at this time because it’s going to
      be hysteria. Especially with the way it happened too, the chasing
      paparazzi, it will be blown out of proportion as if it needed that. I’m
      sure everyone will be in shock and for probably weeks no one will talk
      about anything else.
    </p>

    <p class="inset-3">
      speeding to get away from pursuing<br />
      foreign press photographers on a motorcycle<br />
      crumpled at the roadside
    </p>

    <p class="inset-3">
      who knew of trust funds<br />
      on the scale of a Greek tragedy
    </p>

    <p>
      James and Matt reacted as expected. They were in such shock that they were
      cracking jokes about it at the tiniest opportunity. They did not want to
      admit their shock. Soon Matt made it obvious as he was immediately telling
      everyone coming in. He got reactions such as “good”, or “OK”, or “really?”
      but not the shock he was counting on feeding on.
    </p>

    <p class="inset-1">
      I went there and was very pleased<br />
      professional approach, pleasant environment, maximum satisfaction<br />
      my dream I had my first suit made<br />
      O splendour
    </p>

    <p class="inset-2">
      we work hard to make our guests happy<br />
      pop on the floor<br />
      with a flashlight more under dark corners<br />
      in gaps
    </p>

    <p class="inset-1">
      I enjoyed an hour and a half of testing<br />
      Mr Berdych was helpful<br />
      amazing looking instrument out of an old ugly violin<br />
      if you’re interested in buying a Czech string instrument
    </p>

    <p class="inset-2">
      exposed wood beams behind the bed<br />
      add to the charm<br />
      we found the common toilet hard to use<br />
      there’s a fast lift so no need to worry about stairs
    </p>

    <p class="inset-1">
      perfect service from his girls<br />
      Michaela and Simona are so helpful<br />
      real skin, reasonable price<br />
      worth a visit
    </p>

    <p>
      Soon after I left to find a launderette as I had no clean clothes left. It
      took me three hours to finish, and my plans to go to Scarlett O’Hara Irish
      Pub (wasn’t she American?) to watch the game went out of the window. It
      didn’t matter in the end as the game was postponed. Got back to the
      hostel, and because they were out of beer, me & Ben went to a nearby
      supermarket and bought some bottles for Kr8.50 (~ 17p). Anna was very
      subdued and feeling the effects of the night before. Everybody else,
      though, was getting drunk again, and so was I.
    </p>

    <p class="inset-4">
      seriously injured the companion has been killed the driver also understood
      to be dead in the west of the city been enjoying dinner at the Ritz hotel
      going through a tunnel we were involved in some kind of collision the car
      ended up on the roof she is suffering from concussion a broken arm and a
      cut on one of her thighs ministers quoted that she died at sky centre in
      the far east has in fact was killed just a few hours ago extreme concern
      taken from the wreckage news blackout for a significant amount of time
    </p>

    <p>
      Finally met Clare and Lorna. They looked like the two girls in that ad
      about some car where the blonde one goes “he wants them for his wives” in
      a strong Middle Eastern accent. Clare was the dark haired one and reminded
      me of you in some ways. They had a pierced lip and tongue, were from Kent
      and Somerset, and were studying Theatre Design and Textile Art at
      Birmingham Uni. They were cool, maybe a bit too cool (well, Ben and
      someone else thought so). I talked and drank with Clare for a couple of
      hours until they left to get their train to Split and then to Budapest.
      They told me they had already been to Krakow and Auschwitz, talked about
      hair & toothbrushes. Some thought I was ‘in there’ with Clare, especially
      a guy from Stockholm. Our quiet night continued with heavy drinking. Me &
      Rob were talking music, he’s a huge Beatles fan as well as a Nirvana fan.
      We got more drunk
    </p>

    <p class="inset-1">and appreciate your feedback.</p>
  </div>
</div>

<style>
  .inset-1 {
    margin-left: 2rem;
  }
  .inset-2 {
    margin-left: 4rem;
  }
  .inset-3 {
    margin-left: 6rem;
  }
  .inset-4 {
    margin-left: 8rem;
  }
  .sceneWrapper {
    overflow-y: scroll;
    display: flex;
    position: relative;
  }
  .image-wrapper {
    flex: 1 0 50%;
    overflow: hidden;
    position: sticky;
    top: 0;
    border-right: 4px solid;
    transform: translate(0, 0);
    transition: all 0.5s ease-in-out;
  }
  .poem {
    padding: 20px;
    padding-bottom: 100px;
    z-index: 100;
  }
  .background {
    background-color: rgba(233, 215, 165, 0.5);
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
</style>
