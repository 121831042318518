<script>
  import {
    activeScene,
    mapX,
    mapY,
    mapZ,
    themeColour,
    homeLoc,
    navHighlights,
    activeToast,
    hitCounter,
  } from "../store";

  import Popup from "./toasts/Popup.svelte";
  import Crypto from "./toasts/Crypto.svelte";
  import Popular from "./toasts/Popular.svelte";
  import { handleStats } from "./helpers/handleStats";
  import { handlePageLock } from "./helpers/handlePageLock";
  export let linkTitle, linkID;
  let highlight;

  const handleNav = (id) => {
    let locked = handlePageLock(id, $hitCounter);

    if (locked) {
      console.log({ locked });
      $activeToast = Popular;
      return;
    }

    activeScene.update((v) => (v = id));
    // Handle the History API here also.

    handleStats(id);

    if (id !== 0) {
      let rnd = Math.random();
      let rndTime = Math.floor(Math.random() * 10);
      // Popup the ad toast.
      if (rnd > 0.925) {
        setTimeout(() => {
          $activeToast = Popup;
        }, rndTime * 1000);
      }
      if (rnd > 0.85 && rnd < 0.925) {
        setTimeout(() => {
          $activeToast = Crypto;
        }, rndTime * 1000);
      }
    }

    // Returning Home
    if (id == 0) {
      mapX.update((v) => $homeLoc.x);
      mapY.update((v) => $homeLoc.y);
      mapZ.update((v) => 14);
      themeColour.update((v) => "#fff");
    }
  };

  $: {
    $navHighlights.find((n) => n.id === linkID) && linkID != 0
      ? (highlight = true)
      : (highlight = false);
  }
</script>

<li class:highlight on:click={handleNav(linkID)}>{linkTitle}</li>

<style>
  li:hover {
    cursor: pointer;
    color: #aaa;
  }
  .highlight {
    color: blue;
  }
</style>
