<script>
  import { fade } from "svelte/transition";
</script>

<div class="sceneWrapper" transition:fade={{ delay: 450, duration: 600 }}>
  <div class="poem" transition:fade={{ delay: 2000, duration: 300 }}>
    <h1>The New Republic</h1>
    <div class="intro">
      <p>
        <em>
          For it is related in our records how once upon a time your state
          stayed the course of a mighty host, which, starting from a distant
          point in the Atlantic ocean, was insolently advancing to attack the
          whole of Europe, and Asia to boot. For the ocean was at that time
          navigable; for in front of the mouth which you Greeks call, as you
          say, ‘the pillars of Heracles,’ there lay an island which was larger
          than Libya and Asia together; and it was possible for the travellers
          of that time to cross from it to the other islands, and from the
          islands to the whole of the continent over against them which
          encompasses that veritable ocean. For all that we have here, lying
          within the mouth of which we speak, is evidently a haven having a
          narrow entrance; but that yonder is a real ocean, and the land
          surrounding it may most rightly be called, in the fullest and truest
          sense, a continent.
        </em>
      </p>
    </div>
    <div class="sep">–––––</div>
    <ul>
      <li>
        A Swedish academic distanced himself from his controversial theory that
        the city is not natural, it is a man-made conceit that abstracts away
        from the erotic needs of the body.
      </li>

      <li>
        Dr U<span class="redacted">lf</span> E<span class="redacted"
          >rlingsson</span
        > had claimed that the description the city struck some critics as harsh,
        rigid, and unfree; indeed, as totalitarian.
      </li>

      <li>
        The <span class="redacted">44</span>-year-old academic said although the
        work was wrong on almost every point, the questions it raises and the
        methods it uses are essential to the western tradition.
      </li>

      <li>
        But today he said he never meant people to think the marriages of the
        ruling class ‘sacred’; they last only one night and are the result of
        manipulating and drugging couples into predetermined intercourse with
        the aim of eugenically breeding guardian-warriors.
      </li>
    </ul>

    <div class="image-wrapper">
      <img src="./visuals/c10a.jpeg" alt="Atlantis" class="poem-image" />
      <img src="./visuals/c10b.jpeg" alt="Atlantis" class="poem-image" />
    </div>

    <p>
      Blankfaced and barechested, Mark is something of a frozen fishstick in
      yellow trunks. Or at least that’s how Mr. B<span class="redacted"
        >uono</span
      >
      envisions him. Playing the evil scientist S<span class="redacted"
        >chubert</span
      >, the rotund Mr. B<span class="redacted">uono</span> gives one of those
      eyerolling, handrubbing outrageously hammy performances that normally only
      actors like D<span class="redacted">onald</span> P<span class="redacted"
        >leasance</span
      >
      attempt these days. The mad scientist is inordinately fond of rare cognac and
      Beluga caviar. He keeps a string quartet employed to entertain him during meals,
      occasionally joining them on cello for something festive in the way of a Schubert
      quintet to celebrate his latest nasty triumph. This evening S<span
        class="redacted">chubert</span
      >
      is doing nothing less than melting Antarctica in order to raise the levels
      of seas and oceans, thereby flooding the world. He promises to desist only
      if Mark agrees to become his guinea pig in an experimental search for superior
      beings. S<span class="redacted">chubert</span> doesn’t pamper Mark. “Go
      on,” he orders the amiable mammal, “you’re dripping on the carpet.” When
      Mark dives into his underwater home while fleeing several of S<span
        class="redacted">chubert’s</span
      > henchmen, the frustrated scientist gleefully shouts, “Everybody into the
      pool!” And when, of course, Mark eventually defeats him, the irrepressible
      enemy warns him, “Don’t come to me for grant, that’s all I can say!”
    </p>

    <p class="spacer">/ / /</p>

    <p>
      - Me: Tries to listen to very enjoyable and nostalgic song<br />
      - Internet: S T O P<br />
      - i dont remember typing this wtf
    </p>

    <p class="spacer">/ / /</p>

    <div class="comments">
      <div class="comment-wrapper">
        <img alt="avatar" src="./visuals/avatars/av1.png" class="avatar" />
        <p class="comment">
          No, one would have guessed that a 49 yr. old black male likes this
          type of music.
        </p>
      </div>
      <div class="comment-wrapper">
        <img alt="avatar" src="./visuals/avatars/av2.png" class="avatar" />
        <p class="comment">
          52 year old black guy! i grew up listening to punk rock
        </p>
      </div>
      <div class="comment-wrapper">
        <img alt="avatar" src="./visuals/avatars/av1.png" class="avatar" />
        <p class="comment">That is cool.</p>
      </div>
      <div class="comment-wrapper">
        <img alt="avatar" src="./visuals/avatars/av3.png" class="avatar" />
        <p class="comment">what the fuck</p>
      </div>
      <div class="comment-wrapper">
        <img alt="avatar" src="./visuals/avatars/av4.png" class="avatar" />
        <p class="comment">HAHAH how cool</p>
      </div>
      <div class="comment-wrapper">
        <img alt="avatar" src="./visuals/avatars/av5.png" class="avatar" />
        <p class="comment">music is for everyone</p>
      </div>
      <div class="comment-wrapper">
        <img alt="avatar" src="./visuals/avatars/av6.png" class="avatar" />
        <p class="comment">oh yes daddy</p>
      </div>
      <div class="comment-wrapper">
        <img alt="avatar" src="./visuals/avatars/av7.png" class="avatar" />
        <p class="comment">That is pretty cool</p>
      </div>
      <div class="comment-wrapper">
        <img alt="avatar" src="./visuals/avatars/av8.png" class="avatar" />
        <p class="comment">Lmao</p>
      </div>
      <div class="comment-wrapper">
        <img alt="avatar" src="./visuals/avatars/av9.png" class="avatar" />
        <p class="comment">
          29 year old black female I grew up listening to alt and punk
          rock...but I also dug the nu rave scene
        </p>
      </div>
      <div class="comment-wrapper">
        <img alt="avatar" src="./visuals/avatars/av10.png" class="avatar" />
        <p class="comment">I’m 13 yr Asian but I love this song</p>
      </div>
      <div class="comment-wrapper">
        <img alt="avatar" src="./visuals/avatars/av11.png" class="avatar" />
        <p class="comment">
          Lol I’m African American but much younger also I heard this song from
          a game called nfs prostreet and it bring back good memories
        </p>
      </div>
      <div class="comment-wrapper">
        <img alt="avatar" src="./visuals/avatars/av12.png" class="avatar" />
        <p class="comment">
          Late 20’s black and proudly cyberpunk af. Love anything with synths in
          it and a good bass lick.
        </p>
      </div>
      <div class="comment-wrapper">
        <img alt="avatar" src="./visuals/avatars/av13.png" class="avatar" />
        <p class="comment">I only.like this shit cause of pro street.</p>
      </div>
      <div class="comment-wrapper">
        <img alt="avatar" src="./visuals/avatars/av14.png" class="avatar" />
        <p class="comment">
          I’ve been listening to this band since 2010 I was 13
        </p>
      </div>
      <div class="comment-wrapper">
        <img alt="avatar" src="./visuals/avatars/av15.png" class="avatar" />
        <p class="comment">
          When music is good...it should be loved by all, no matter what it is.
          This is good shit
        </p>
      </div>
      <div class="comment-wrapper">
        <img alt="avatar" src="./visuals/avatars/av16.png" class="avatar" />
        <p class="comment">You are correct sir stereotyping at it’s finest</p>
      </div>
      <div class="comment-wrapper">
        <img alt="avatar" src="./visuals/avatars/av17.png" class="avatar" />
        <p class="comment">I guessed it. I guess I’m the one</p>
      </div>
      <div class="comment-wrapper">
        <img alt="avatar" src="./visuals/avatars/av18.png" class="avatar" />
        <p class="comment">13 year old Mexican that got this from NfsPS</p>
      </div>
      <div class="comment-wrapper">
        <img alt="avatar" src="./visuals/avatars/av19.png" class="avatar" />
        <p class="comment">19 years old Brazilian here too haha</p>
      </div>
      <div class="comment-wrapper">
        <img alt="avatar" src="./visuals/avatars/av20.png" class="avatar" />
        <p class="comment">Did you know we actually created Rock?</p>
      </div>
      <div class="comment-wrapper">
        <img alt="avatar" src="./visuals/avatars/av21.png" class="avatar" />
        <p class="comment">
          good on yah. I have black friends that just copy their other black
          friends and only listen to hip-hop lol they’d like other tunes if they
          tried it.
        </p>
      </div>
      <div class="comment-wrapper">
        <img alt="avatar" src="./visuals/avatars/av22.png" class="avatar" />
        <p class="comment">Oh? Why?</p>
      </div>
      <div class="comment-wrapper">
        <img alt="avatar" src="./visuals/avatars/av23.png" class="avatar" />
        <p class="comment">
          i don’t care, you can be white,black,blue,green and yellow and still
          like metal.
        </p>
      </div>
      <div class="comment-wrapper">
        <img alt="avatar" src="./visuals/avatars/av24.png" class="avatar" />
        <p class="comment">The world wouldn’t be complete if you didn’t.</p>
      </div>
      <div class="comment-wrapper">
        <img alt="avatar" src="./visuals/avatars/av24.png" class="avatar" />
        <p class="comment">and every other “modern” genre, basically</p>
      </div>
      <div class="comment-wrapper">
        <img alt="avatar" src="./visuals/avatars/av25.png" class="avatar" />
        <p class="comment">It’s our secret, bud</p>
      </div>
    </div>
  </div>
</div>

<style>
  .poem {
    max-width: 740px;
    margin: 10vh auto;
  }
  .redacted {
    background-color: black;
    color: black;
  }
  .comment-wrapper {
    display: flex;
    border: 1px solid black;
    border-bottom: none;
  }
  .comment-wrapper:last-child {
    border-bottom: 1px solid black;
  }
  .avatar {
    width: 40px;
    margin: 10px;
  }
  .comment {
    margin: 10px 0;
  }
  .sep,
  .spacer {
    text-align: center;
  }
  .image-wrapper {
    display: flex;
    border: 3px solid;
    margin: 40px 0;
  }
  .poem-image {
    max-width: 50%;
    object-fit: cover;
    flex: 1 0 50%;
    opacity: 0.75;
  }
  .poem-image:first-child {
    border-right: 3px solid;
  }
</style>
