<script>
  import { fade } from "svelte/transition";
</script>

<div class="sceneWrapper" transition:fade={{ delay: 450, duration: 600 }}>
  <div class="poem" transition:fade={{ delay: 2000, duration: 300 }}>
    <h1>The name is probably a corruption</h1>

    <div class="intro">
      <p>
        <em>
          This homeland of one of the globe’s greatest poets is difficult to
          define. There is very little that can be done about associations when
          they come up and block the sense of control you have over narrative,
          over who you or any place truly are/is, or who you or any place might
          be, especially if you were somewhere else or the place holds different
          people. Tenses aren’t the only thing that shifts in a conditional
          phrase. One minute you have a clear sense of some place or someone and
          the next you are somewhere else, with someone else, naked, submerged
          in salt water (choking on tears?), trying to remember, trying to
          return to the land of your birth (literal or metaphorical). You are
          travelling.
        </em>
      </p>
    </div>
    <p class="sep">–––––––</p>

    <div class="split">
      <p>
        overseas <br />
        an island <br />
        region of <br />
        an island <br />
        department of<br />
        named by <br />
        influenced by <br />
        an island <br />
        land area <br />
        overseas region <br />
        single department<br />
        north of <br />
        south of<br />
        an integral part of<br />
        as part of<br />
        is part of<br />
        overseas<br />
        currency is<br />
        language is<br />
        inhabitants also speak<br />
        a possession<br />
        a precipitous coast<br />
        a mountain range <br />
        plains <br />
        exports<br />
        climate <br />
        an island <br />
        overseas<br />
        capital <br />
        discovered by <br />
        settlement in <br />
        passed into and out of <br />
        the name <br />
        the meaning <br />
        early charts <br />
        influenced by <br />
        the name of <br />
        an island in <br />
        most northern of<br />
        largest of <br />
        taken from <br />
        restored to <br />
        again taken <br />
        restored at <br />
        again captured <br />
        reverted to <br />
        overseas<br />
      </p>
      <div class="poem-images">
        <img src="./visuals/k9a.jpeg" alt="Martinique" class="poem-image" />
        <img src="./visuals/k9b.jpeg" alt="Martinique" class="poem-image" />
      </div>
    </div>
  </div>
</div>

<style>
  .split {
    display: flex;
    margin-bottom: 100px;
  }
  h1 {
    max-width: 600px;
    text-align: center;
    margin: 10vh auto 20px;
  }
  .intro {
    max-width: 600px;
    margin: 20px auto;
  }
  .sep {
    text-align: center;
  }
  .split p {
    width: 50%;
    padding: 0 20px;
    flex: 1 0 50%;
    margin-left: 10vw;
  }
  .poem-images {
    flex: 1 0 50%;
  }
  .poem-image {
    max-width: 70%;
    margin: 20px auto;
    object-fit: contain;
    border: 3px solid black;
  }
</style>
