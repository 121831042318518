<script>
  import { onMount } from "svelte";
  let active = false;

  let api =
    window.location.hostname == "localmost"
      ? "http://localhost:4231"
      : "https://invisible-cities.fly.dev";

  onMount(() => {
    active = true;
  });

  const postGraffiti = async (e) => {
    let content = document.querySelector("#graffiti_input").value;
    let post = await fetch(`${api}/graffiti`, {
      method: "POST",
      body: JSON.stringify({ content: content }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());
    console.log({ post });
    e.target.textContent = "Posted";
    e.target.disabled = true;
    active = false;
  };
</script>

<div class="toast graffiti-box" class:active>
  <input type="textarea" placeholder="Leave your mark..." id="graffiti_input" />
  <button on:click={postGraffiti}>Post</button>
</div>

<style>
  .toast {
    opacity: 0;
    transition: opacity 0.55s ease-in-out;
    position: unset;
    display: flex;
    flex-direction: column;
  }
  .active {
    opacity: 1;
  }
  #graffiti_input {
    height: 60px;
  }
</style>
