<script>
  import Modal from "./Modal.svelte";
  import { activeModal } from "../../store";
  import { onMount } from "svelte";

  let all_reviews;

  let api =
    window.location.hostname == "localhost"
      ? "http://localhost:4231"
      : "https://invisible-cities.fly.dev";

  let get_reviews = async () => {
    let review_data = await fetch(`${api}/reviews`).then((res) => res.json());
    console.log({ review_data });
    if (review_data.name == "error" || !Array.isArray(review_data)) {
      return [];
    } else {
      return review_data.filter((r) => r.flagged !== true);
    }
  };

  let format_rating = (rating) => {
    let r = parseInt(rating);
    let s = "";
    for (let i = 0; i < r; i++) {
      s = s + "&#9733;";
    }
    return s;
  };

  let report = async (e) => {
    let r_id = parseInt(e.target.dataset.id);
    let data = { flagged: true, review_id: r_id };
    console.log({ data });
    let flag = await fetch(`${api}/reviews`, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());
    console.log({ flag });
    e.target.textContent = "Reported";
    e.target.disabled = true;
    return flag;
  };

  let handle_form_submit = async (e) => {
    e.preventDefault();
    let data = {
      username: document.getElementById("review-form-username").value,
      email: document.getElementById("review-form-email").value,
      review_body: document.getElementById("review-form-body").value,
      review_rating: parseInt(
        document.getElementById("review-form-rating").value
      ),
    };
    try {
      let insert = await fetch(`${api}/reviews`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => res.json());

      if (insert.success) {
        console.log(insert);
        all_reviews = await get_reviews();
        return insert.data;
      } else {
        throw new Error(insert.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  onMount(async () => {
    all_reviews = await get_reviews();
  });
</script>

{#if $activeModal === "reviews"}
  <Modal on:close={() => ($activeModal = false)}>
    <h2 slot="header">User Reviews</h2>

    <div class="modal-body">
      <div class="testimonials">
        <div class="testimonial">
          <p class="testimonial-body">
            When we are moved, we are transported—literally from one place to
            another, and figuratively from a state of self-awareness to a place
            beyond the self. A poem, a performance, a film, a book—those things
            that have the power to transport us are often elevated to the status
            of art. And yet it is also possible to be moved by a punk concert,
            by the news of a celebrity's death, by a public memorial. In this
            work, Makris and Campanello play with both forms of movement, giving
            readers hemmed in by the pandemic an opportunity to travel, via
            desktop tourism, and to contemplate what they call "the traveller's
            gaze," which often seeks to possess all that it describes. Melding
            90's browser aesthetics with contemporary maps and media, the
            project invites us to travel across not only space, but also
            time—based, as it is, in memories of travel both personal and
            imagined. Linking what appear to be personal reminiscences with
            text, image, and video culled from the web, their epistolary
            exploration takes us to a series of cities in which the authors find
            legacies of poetry and art that help us think through what it means
            to observe and be observed, to move and be moved.
          </p>
          <p class="testimonial-attrib">
            - Amaranth Borsuk (Seattle, WA)<br /><em
              >Poet, scholar, and book artist working at the intersection of
              print and digital media. Author of The Book (MIT Press, 2018).</em
            >
          </p>
        </div>
        <div class="testimonial">
          <p class="testimonial-body">
            The title of the digital text/poem Sorry You Were Not Moved says it
            all, but that makes engagement with this hybrid, fractured,
            globe-spanning work all the more worthwhile. It’s the experience of
            non-experience, a diffusion/confusion of ‘presence’, the world seen
            through a GoPro, packed with disposable insights frozen for
            posterity online. Here is the tourists’ casual, dismissive ‘doing’
            of places. Here is the limit and simultaneous paucity of travel
            writing, the comforting non-space of armchair travel, the local beer
            glass half full of emptiness – this is the dynamic of Sorry You Were
            Not Moved. As global travel passes its peak, listen: you can hear
            its engines idle, rumble, stall.
          </p>
          <p class="testimonial-attrib">
            - Nasser Hussain (Leeds, UK)<br /><em
              >Poet and critic. Author of SKY WRI TEI NGS (Coach House Books,
              2018)</em
            >
          </p>
        </div>
        <div class="testimonial">
          <p class="testimonial-body">
            Kimberly Campanello and Christodoulos Makris have created a telling
            tribute to the way we travel now. A lockdown word tour, by
            travelling without moving, we can be moved without traveling. But
            wherever we go there's nowhere to escape our vocabulary: wish you
            were here!
          </p>
          <p class="testimonial-attrib">
            - Joanna Walsh (Dublin)<br /><em
              >Writer. Author of Girl Online (Verso, 2022)</em
            >
          </p>
        </div>
        <div class="testimonial">
          <p class="testimonial-body">
            Both conceptually and aesthetically it's a fascinating experience,
            and so well put together, and it made me thankful that there are
            venues for this kind of creative work in Ireland. It's beautiful and
            so appropriate to this cold covid-plagued January.
          </p>
          <p class="testimonial-attrib">
            - Roisin Kiberd<br /><em>
              Author of The Disconnect: A Personal Journey Through the Internet
              (Serpent's Tail, 2021)</em
            >
          </p>
        </div>
      </div>
      <div class="add-new-review">
        <h4>Leave A Review</h4>
        <form id="new-review-form">
          <label for="review-form-username">Name:</label>
          <input
            type="text"
            name="username"
            id="review-form-username"
            class="review-form-input"
          />
          <label for="review-form-email"
            >Email (for moderation purposes only):</label
          >
          <input
            type="email"
            name="email"
            id="review-form-email"
            class="review-form-input"
          />
          <label for="review-form-rating">Rating (out of 5):</label>
          <input
            type="number"
            min="0"
            max="5"
            name="review_rating"
            id="review-form-rating"
            class="review-form-input"
          />
          <label for="review-form-body">Review:</label>
          <input
            type="textarea"
            name="review_body"
            id="review-form-body"
            class="review-form-input"
          />
          <button type="submit" on:click={handle_form_submit}>Submit</button>
        </form>
      </div>
      <div class="review-list">
        {#await all_reviews}
          <!-- promise is pending -->
          <p>Looking for reviews...</p>
        {:then reviews}
          <h4>Previous Reviews</h4>
          <!-- promise was fulfilled -->
          {#if reviews.length > 0}
            {#each reviews.reverse() as review}
              <div class="review" data-id={review.review_id}>
                <div class="review-info">
                  <p>Review by: {review.username}</p>
                  <p>&nbsp;&nbsp; | &nbsp;&nbsp;</p>
                  <p>Rating: {@html format_rating(review.review_rating)}</p>
                  <button
                    data-id={review.review_id}
                    class="flag-button"
                    on:click={report}>Report</button
                  >
                </div>
                <div class="review-body">
                  {review.review_body}
                </div>
              </div>
            {/each}
          {:else}
            <p>No Reviews Found.</p>
          {/if}
        {:catch error}
          <!-- promise was rejected -->
          <p>Something went wrong: {error.message}</p>
        {/await}
      </div>
    </div>
  </Modal>
{/if}

<style>
  h2 {
    font-size: 1.4rem;
    margin: 5px 0 15px;
    text-align: center;
  }
  p {
    font-size: 1rem;
    line-height: 1.4;
  }
  .review-form-input {
    margin-bottom: 16px;
  }
  #review-form-body {
    width: 100%;
    height: 180px;
  }
  .review-list {
    border-top: 1px solid black;
    padding: 5px 0;
  }
  .review {
    border-left: 3px solid black;
    margin-bottom: 20px;
    padding: 5px 10px;
  }
  .review-info {
    display: flex;
  }
  .review-info p {
    margin: 5px 0;
  }
  .review-body {
    white-space: pre-line;
    border-top: 1px solid #ccc;
    padding: 8px 0;
  }
  .testimonial {
    border-bottom: 1px solid #ccc;
    padding: 8px 0;
    margin-bottom: 16px;
  }
  .testimonial p {
    font-size: 0.8rem;
  }
  .flag-button {
    margin-left: 15px;
    font-size: 0.65rem;
    padding: 8px 8px;
    line-height: 1;
    min-height: auto;
    min-width: auto;
    border: none;
    box-shadow: none;
    margin-top: 2px;
    border: 1px solid white;
  }
  .flag-button:hover {
    border: 1px solid black;
    cursor: pointer;
  }
</style>
