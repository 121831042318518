<script>
  import Modal from "./Modal.svelte";
  import { activeModal } from "../../store";
</script>

{#if $activeModal === "about"}
  <Modal on:close={() => ($activeModal = false)}>
    <h2 slot="header">Sorry That You Were Not Moved</h2>

    <div class="modal-body">
      <p>Dear reader,</p>

      <p>
        After several months navigating digital space-time in intertextual
        collusion with Italo Calvino's Invisible Cities, we present these
        mementoes of what we encountered on our voyages. The engines of our
        digital travels were fired by diverse strategies and they landed us both
        nowhere and everywhere. All reflect and consequently question the
        traveller's gaze, which is another phrase for a lyric ‘I’ timelessly
        flattering and flattening lands and people. These texts are not the
        product of imagination, of stolen solitary time in distant bars with an
        open notebook and a glass of local liquor. Rather, they are the
        apocrypha of the internet’s flatlands, carefully extracted and preserved
        here, reiterating their own unavoidable and condensed violence on
        locations, real and imagined.
      </p>

      <p>[Leave your customer reviews.]</p>

      <p>
        KC & CM<br />
        February 2022
      </p>
      <div class="logo-wrapper">
        <img src="visuals/ac.png" alt="Arts Council Logo" class="logo-image" />
        <p>Supported by the Arts Council of Ireland.</p>
      </div>
    </div>
  </Modal>
{/if}

<style>
  h2 {
    font-size: 1.4rem;
    margin: 5px 0 15px;
    text-align: center;
  }
  p {
    font-size: 1rem;
    line-height: 1.4;
  }
  .logo-wrapper {
    margin: 20px 0 0;
  }
  .logo-wrapper p {
    margin-top: 4px;
    font-size: 0.8rem;
  }
  .logo-image {
    max-width: 160px;
  }
</style>
