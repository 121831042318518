<script>
  import { fade } from "svelte/transition";
</script>

<div class="sceneWrapper" transition:fade={{ delay: 450, duration: 600 }}>
  <div class="poem" transition:fade={{ delay: 2000, duration: 300 }}>
    <p>
      <em
        >Of course I had to go here and soak up the atmosphere whilst trying to
        find you. I didn’t know when or where you were aiming to arrive or how
        you would go about finding the necessary information, the framing – or
        rather tunneling – devices to reach the key points of interest. So I
        tried to enter via channels that originally had been dug with other
        tools but were at some point drawn into this network, and so were now,
        or still are, accessible. That made it more difficult to find food and
        shelter, and to find you, but it was much easier to catch a glimpse of a
        kind of essential artistic charm laced with god knows what.</em
      >
    </p>
    <img src="./visuals/k6a.jpeg" alt="Tangier" class="poem-image" />
    <p>
      Il m'a donné une liste des livres que je deviens lire en arabe et en
      espagnol alors c'est comme ça que j'ai commencé. People ask why and I
      can't say because there's nothing to say about either the music or the
      books mais ce soir là dans mon café à côté de là où j'habite j’ai demandé
      une petite bouteille de vin et j'ai commencé à écrire le premier chapitre.
      The dream goes on.
    </p>

    <p>
      Esta lesión que le va a usted y a escribir por favor. Things will happen.
      There is drumming out there most nights it never awakens me.
    </p>

    <p>
      Te gusta estar en una cueva y que nunca saben lo que hayan hecho la visión
      exterior. I hear the drums and incorporate them into my dream like the
      nightly cries of the Muslims even if in the dream I'm in New York.
    </p>

    <p>
      Alors pour avoir un style moi, I've got nothing to say. Digamos la cosa
      misteriosa: Una carta muerta es lo que tenías because photos are
      completely different y lo real se desvaneció pero la cosa misteriosa tiene
      una dureza iluminada de duración in every direction.
    </p>

    <p>
      From thousands of senders to thousands of unsuspecting recipients spells
      are being crushed, poison is running its course, souls are being
      dispossessed of parasitic pseudo-consciousness: Dos con serpientes, no,
      serpientes abrazando, sí llorando, sí y haciendo el amor.
    </p>

    <p>
      I had placed my sense of being in the world partly on an unreasoned
      conviction that certain areas of the earth’s surface contained more magic
      than others. C'est alors une langue quelque peu différente.
    </p>

    <p>
      It is very difficult to become intimate friends of individuals here. One
      can appreciate the people en masse as a group for an hour or so then
      suddenly decide to write books.
    </p>

    <p>
      El hombre es lo imaginativo, creas un imaginativo para ver si así nada
      experiencial que se llama una enorme rubia extradición de los profetas
      right outside this city, the back door of Europe.
    </p>

    <p>
      She is facing the backdrop carrying whatever comes next to North Africa.
    </p>
  </div>
</div>

<style>
  .poem {
    max-width: 740px;
    margin: 0 auto;
    padding: 20px;
    background-color: white;
  }
</style>
