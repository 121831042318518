<script>
  import Nav from "./components/Nav.svelte";
  import Map from "./components/Map.svelte";
  import Window from "./components/Window.svelte";
  import About from "./components/modals/About.svelte";
  import Reviews from "./components/modals/Reviews.svelte";
  import AudioPlayer from "./components/AudioPlayer.svelte";
  import Toast from "./components/Toast.svelte";
  import History from "./components/History.svelte";

  import { onMount } from "svelte";

  import { audioSrc, mapX, mapY, mapZ, homeLoc, hitCounter } from "./store";
  export let L;

  onMount(async () => {
    let { ip } = await fetch("https://api.ipify.org?format=json").then((res) =>
      res.json()
    );
    let loc = await fetch(
      `https://green-brook-5abf.imaleney.workers.dev/${ip}`
    ).then((res) => res.json());

    fetch("https://invisible-cities.fly.dev/stats/")
      .then((res) => res.json())
      .then((stats) => ($hitCounter = stats));

    $mapX = loc.lat;
    $mapY = loc.lon;
    $mapZ = 14;
    $homeLoc = {
      x: loc.lat,
      y: loc.lon,
      z: 14,
    };
  });
</script>

<svelte:head>
  <title>sorry that you were not moved</title>
</svelte:head>

<main>
  <Map {L} />
  <Window />
</main>

<Nav />
<About />
<Reviews />
<Toast />
<AudioPlayer src={$audioSrc} />
<History />

<style>
  :global(*) {
    box-sizing: border-box;
  }
  :global(body) {
    margin: 0;
    padding: 0;
  }
  main {
    background-color: var(--c);
    max-height: 100vh;
    max-width: 100vw;
    overflow: hidden;
  }
  :global(.sceneWrapper) {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
  }
  :global(.sceneWrapper h1) {
    font-size: 2rem;
  }
</style>
