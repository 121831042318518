<script>
  export let src;
  let audioPlayer;
  let muteMessage = "Mute";

  const playAudio = () => {
    audioPlayer.volume = 0.8;
    audioPlayer.play();
  };

  const toggleMute = () => {
    if (audioPlayer.volume > 0) {
      audioPlayer.volume = 0;
      muteMessage = "Listen";
    } else {
      muteMessage = "Mute";
      audioPlayer.volume = 0.8;
    }
  };
</script>

{#if src}
  <audio
    id="audio-player"
    on:canplay={playAudio}
    bind:this={audioPlayer}
    {src}
  />
  <div class="audio-controls">
    <button id="mute" on:click={toggleMute}>{muteMessage}</button>
  </div>
{/if}

<style>
  audio {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 100;
  }
  .audio-controls {
    position: fixed;
    bottom: 40px;
    left: 40px;
    z-index: 100;
  }
</style>
