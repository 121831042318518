<script>
  import {
    activeScene,
    mapX,
    mapY,
    mapZ,
    themeColour,
    audioSrc,
    tags,
  } from "../store";
  import { scenes } from "../scenes";

  let visibleScene, sceneTitle;

  activeScene.subscribe((v) => {
    let scene = $scenes.find((s) => s.id == v);
    visibleScene = scene.component;
    mapX.update((v) => scene.mapX);
    mapY.update((v) => scene.mapY);
    mapZ.update((v) => scene.mapZ);
    themeColour.update((v) => scene.colour);
    audioSrc.update((v) => scene.audio);
    tags.update((v) => scene.tags);
    sceneTitle = scene.title;
  });
</script>

<svelte:head>
  <title>{sceneTitle} | Sorry That You Were Not Moved</title>
</svelte:head>

<div class="sceneWindow" class:active={visibleScene}>
  <svelte:component this={visibleScene} />
</div>

<style>
  .sceneWindow {
    width: calc(100vw - 40px);
    height: calc(100vh - 40px);
    /* overflow-y: scroll; */
    overflow: hidden;
    margin: 18px auto;
    border: 3px solid black;
    border-radius: 6px;
    padding-top: 0;
    z-index: 20;
    position: relative;
    pointer-events: none;
  }
  .sceneWindow.active {
    pointer-events: all;
  }
</style>
