<script>
  import { fade } from "svelte/transition";
  import GraffitiBox from "../toasts/GraffitiBox.svelte";
</script>

<div class="sceneWrapper" transition:fade={{ delay: 450, duration: 600 }}>
  <div class="poem" transition:fade={{ delay: 2000, duration: 300 }}>
    <p class="intro">
      <em>
        It’s easy to connect with the past here, to recall the supermarket
        brands and the fear that family pets would be stolen from the backyard
        and used for bait in dog fights held up in the hills. This was the place
        where I first created a profile. I hadn’t realised what I was getting
        into, or that it didn’t matter where I made my profile, or when.
      </em>
    </p>

    <p>
      I mean I think tumbleweeds are really aware of the city which should have
      been cherished I can see it working so likely the permanently spoiled part
      is now a Wildlife Refuge though this is not to record the sound it’s just
      to record the text I think it goes well that’s the aboriginal belief that
      the land needs to be cherished if I can just use a moment to talk about
      the aboriginal feel I mean Captain Cook is this not to record the sound
      only hearing me it’s just to record the text when I think it goes well
      that’s cool the land needs looking after the land takes over as soon as I
      start speaking here it’s hearing itself they were sophisticated farmers
      well we treated them badly as we all know and the early history of
      Australia is as bad but the United States is something modern man never
      does let slip that landscape completely untouched and sharing grows
      ferociously and the underbrush catches to buy this thing in California of
      course this is that which is not I work on a super mission the other
      didn’t suffer for us we can learn from them helping the high tech teams
    </p>
    <img src="./visuals/k5b.jpg" alt="cincinnati" />
    <p>
      that said we don’t do that just take it slowly do what we did learn from
      what we have learned over thousands of years and you keep your fires at
      bay I’m in what you felt like when the landlord slid that envelope under
      your door along with the notice about the CDC’s eviction moratorium I was
      lost for words sorry I didn’t know what to do still don’t know what to do
      alright I got my appointment in the shelter and doing everything that I
      needed to do and having all my paperwork all the way up my God you can
      take as much time as you need you can take as much time as you need to
      gather your thoughts and share that with us I know a lot of people
      listening are feeling very sympathetic right now tomorrow we will run
      faster stretch out our arms farther and so we beat on boats against the
      current borne back ceaselessly into the past that final image a handy
      summation of the American experience at least according to author F. Scott
      Fitzgerald
    </p>

    <img src="./visuals/k5c.jpeg" alt="cincinnati" />

    <p>
      resources for school kids exist but finding what you need or getting
      access can be confusing we will have time hopefully to talk more about the
      grace notes of this moment I have opened myself up but I’m not I’m not all
      that excited to talk about anything as you may or may not know the
      traditional formula has been ruled unconstitutional four times and it is
      not equitable to be honest there are still a lot of questions there’s a
      lot of data that people don’t know from the city like specifically the
      city hasn’t released how many no-knocks are done each year for instance
      for the past 10 years and who they are served upon but people still wanted
      to pass something to regulate how no-knocks are carried out you could name
      1000 different ways but the central underlying theme of all of them is
      trying to get a person to just associate their identity with
    </p>
    <GraffitiBox />
    <p>
      painful or shameful stimuli anywhere from having a teenager wear a
      backpack full of bricks for 14 hours at a time to exposing children to
      pornography while having them sits on blocks of ice or having folks
      recount all their sexual fantasies in front of their entire church
      congregation devoted to one another and the country we love with all our
      hearts may God bless America and may God protect our troops thank you
      America what struck you while the sun came out right before the ceremony
      began so we were talking about the brown tree snake here it is
    </p>
  </div>
</div>

<style>
  .poem {
    max-width: 740px;
    padding: 10px;
    background-color: white;
  }
  img {
    max-width: 100%;
  }
</style>
