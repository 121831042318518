<script>
  import { fade } from "svelte/transition";
</script>

<div class="sceneWrapper" transition:fade={{ delay: 450, duration: 600 }}>
  <div class="poem" transition:fade={{ delay: 2000, duration: 300 }}>
    <em
      >This city is connected to the great black wall with the family name on
      it.</em
    >

    <h1>See if it goes any better</h1>
    <img class="saigon-img" src="./visuals/k5a.jpeg" alt="Saigon" />

    <p>
      The language barrier is going to be vast here.<br />
      Some guys will just want to find out the cold hard facts: <br />
      Considering the low rate of first (or second) date hook ups<br />
      I kept thinking about the chicken that the crossed the road.
    </p>

    <p>
      Go straight to Apocalypse, the best.<br />
      Log on a week before and message as many pretty women as you can<br />
      Know that you are just paying to flirt, not to get laid –<br />
      If you wanted to ‘experience the real Vietnam’ then maybe
    </p>

    <p>
      Hook up or find a long term girlfriend. Enjoy smoked sausage,<br />
      Work a little harder than just offering to Netflix and chill with alcohol involved<br
      />
      For hundreds if not thousands of cute single girls and see if it goes any better.
    </p>

    <p>
      I didn’t die. The world didn’t end. I grew a force field<br />
      And it protected me. It must not have been in Saigon.
    </p>
  </div>
</div>

<style lang="scss">
  .sceneWrapper {
    display: flex;
    height: 100vh;
    width: 100vw;
    background-color: rgba(95, 158, 160, 0.5);
  }
  .poem {
    width: 66vw;
    flex: 1 0 66%;
    padding: 20px;
    font-family: "Söhne Test", sans-serif;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(4, 25vh);
  }
  em {
    font-family: "Wremena", serif;
    display: block;
    margin-bottom: 25vh;
    grid-column: 1;
  }
  h1 {
    grid-column: 1 / -1;
    font-size: 2.6rem;
  }
  p {
    font-size: 0.85rem;
    line-height: 1.5;
  }
  .saigon-img {
    grid-column: 2;
    grid-row: 1;
    height: 30vh;
    opacity: 0.8;
    border: 2px solid black;
  }
</style>
