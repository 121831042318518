<script>
  import { onMount } from "svelte";
  import { activeToast } from "../../store";
  let active = false;

  onMount(() => {
    let a = document.getElementById("audio-player");
    if (a) {
      a.volume = 0;
    }
    active = true;
  });

  const close = () => {
    active = false;
    setTimeout(() => {
      let a = document.getElementById("audio-player");
      if (a) {
        a.volume = 1;
      }
      $activeToast = null;
    }, 550);
  };
</script>

<div class="toast-overlay" class:active />
<div class="toast" class:active>
  <h1>briefly unavailable due to extreme popularity</h1>
  <p>come back tomorrow</p>
  <button id="close" on:click={close}>✕</button>
</div>

<style>
  h1 {
    font-size: 1.2rem;
    margin: 0 0 10px;
  }
  .toast-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    filter: blur(3px);
    pointer-events: none;
    background-color: rgb(241, 241, 241);
    z-index: 999;
  }
  .toast {
    opacity: 0;
    transition: opacity 0.55s ease-in-out;
    position: relative;
    bottom: 250px;
    z-index: 1000;
  }
  .active {
    opacity: 1;
  }
  .toast-overlay.active {
    opacity: 0.8;
  }
  #close {
    position: absolute;
    top: 5px;
    right: -20px;
    border: none;
    background-color: rgba(255, 255, 255, 0);
    color: black;
    box-shadow: none;
    padding: 0;
    margin: 0;
    width: 1em;
  }
  #close:hover {
    color: darkgray;
    cursor: pointer;
  }
</style>
