exports.handlePageLock = (location_id, hitCounter) => {

	// Date functions
	function addHoursToDate(date, hours) {
		return new Date(new Date(date).setHours(date.getHours() + hours));
	}
	function dateCompare(date1, date2) {
		return new Date(date2) > new Date(date1);
	}

	function pageLock(id, locked, time) {
		fetch(`https://invisible-cities.fly.dev/stats/${id}/lock`, {
			method: "PUT",
			body: JSON.stringify({locked: locked, time_locked: time}),
			headers: {
				"Content-Type": "application/json",
			}
		}).then((res) => res.json());
	}

	// Stats
	if (!hitCounter || hitCounter.length == 0) return false;
	if (!Object.keys(hitCounter).includes('location_id')) return false;
	console.log({hitCounter});
	let next_page_stats = hitCounter.find((p) => p.location_id == location_id);
	
	if (!next_page_stats) return false;
	let now = new Date();

	if (next_page_stats && next_page_stats.locked) {
		console.log("This page is too popular.");
		let time_locked = new Date(next_page_stats.time_locked);
		let time_to_unlock = addHoursToDate(time_locked, 24);
		if (dateCompare(time_to_unlock, now)) {
			pageLock(location_id, false);
			return false;
		} else {
			return true;
		}
    } else {
		if (next_page_stats.hit_count >= 60) {
			pageLock(location_id, true, now.toISOString());
		}
		return false;
	}
}