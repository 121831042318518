<script>
  import { fade } from "svelte/transition";
  import GraffitiBox from "../toasts/GraffitiBox.svelte";
</script>

<div class="sceneWrapper" transition:fade={{ delay: 450, duration: 600 }}>
  <div class="poem" transition:fade={{ delay: 2000, duration: 300 }}>
    <h1>That Fictitious Landscape</h1>

    <div class="intro">
      <p>
        <em>
          Travel to 1950s Tangiers from the comfort of your sofa with this
          alluring adaptation. A word of caution: if you ask anyone the way
          anywhere they then think you have engaged them as a personal guide and
          are difficult to dismiss.
        </em>
      </p>
    </div>

    <div class="inner-wrapper">
      <p class="align-right r1">
        A brilliant blast of sunlight coming through<br /> and shattering the
        blue sky<br />, and the view of the Bay of Tangier<br /> – that for me
        was “the ancient<br /> parapets of Europe” – a phrase of<br /> Rimbaud –
        and my first glimpse of<br /> Europe. It was, for me, the open<br /> space,
        coming from New York.
      </p>

      <p class="r2">
        things weren’t quite as I’d imagined<br /> I was picturing giant
        insects, murderous pirates and mugwumps<br /> fierce, heckling touts and
        swarms of pickpockets<br /> slaughtered chickens in the toilet<br /> a
        wind that was dry and warm<br /> the hairs on the back of my neck
        standing up even in the midday sun<br />
        dark corners<br /> its tang of urine and fear<br /> a prosaic
        combination of frying meat, petrol, old water, sweat<br /> a hot spot
        for those who like sex with underage boys<br /> the easy availability of
        kif
      </p>

      <p class="align-right r3">
        There was a magical hush in the garden / How rich was the architecture
        and the interior design! / How beautiful the tea glasses and how
        delicious the gazelle horns! / The air was warm when we walked out and
        laden with the scent of mint, dates and orange blossom.
      </p>

      <p class="r4">
        a steep hill with some demolished buildings and loads of feral cats<br
        />
        an overpowering scent of orange blossom and of sunlight filtered through
        palm fronds<br />
        a sense of danger, of faded grandeur, of immense possibility<br />
        a haven for stoned beatniks and hippies<br />
        draft dodgers, fugitives, philosophers<br />
        there were fishermen laying out the morning’s catch<br />
        baskets of glinting sardines circled by expectant cats<br />
        melons as big as cannonballs, cages crammed with frantic chickens<br />
        dried chameleons weighed out and sold in twists of newspaper to a huddle
        of veiled women<br />
        endless cafés each packed with a clutch of grizzled old men in hooded djellaba
        robes<br />
        playing cards, telling tall tales, drawing slowly on slender ceramic pipes<br
        />
        bread wafting from open doors deliciously
      </p>

      <p class="align-right r5">
        males had fewer hang-ups about sex with other males<br />
        not all boys were underage
      </p>
      <img
        src="./visuals/c6a.jpeg"
        alt="Tangier"
        class="poem-image align-right r6"
      />

      <p class="r6">
        a canopy of fragrant honeysuckle and blazing bougainvillea<br />
        intoxicated by the heady scent of orange blossom<br />
        telling of his journeys in Arabia half a lifetime before<br />
        he opened his palm and showed me a nugget of twisted bronze the size of a
        hen’s egg<br />
        a piece from a Spanish galleon picked up on the beach
      </p>

      <p class="r7">
        while fumbling for his key at the rusted iron gate<br />
        a Coca-Cola truck knocked him down<br />
        his wife died of cancer
      </p>

      <p class="align-center r8">
        that fictitious landscape, the Tangiers of the mind
      </p>

      <p class="r9 align-center">
        There is much to take issue with in this colonial, eerie piece. The
        writing is mainly preoccupied with the self. The Moroccans, although the
        villains of the piece by implication, are consequential and neither the
        subject nor the object of the blog, which belongs to a long tradition of
        representing the other to consolidate your own subject status. The
        dominant subject in the above passage is built on conceptual binary of
        verbal fluency-power versus mutism-subalternity. The Moroccans were
        muted and cannot speak in a way that would carry any sort of authority
        or meaning for <span class="redacted">REDACTED</span> without altering
        the relations of power/knowledge that constitute it as subaltern in the
        first place. The subaltern is invisible to the writer therefore it was
        “disappeared”. The country of Morocco and its people are irrelevant and
        are neither the subject nor the object of
        <span class="redacted">REDACTED</span> blog. The “cruel” landscape was conveniently
        depopulated so he could represent it freely and to his own ends. The inability
        to see the native or engage with him/her reeks of Orientalism. Plus plus
        ça change plus c’est la même chose.
      </p>

      <p class="r10">
        just like Paris <br />
        good coffee, nice croque monsieur, good views <br />
        only men sitting outside <br />
        (they didn’t seem to take notice of me <br />
        a single woman) <br />
        good panache with either milk or orange <br />
        the inevitable mint tea bien sur
      </p>

      <p class="r11">
        free city, half European, half African with Arab notes<br />
        free to walk around to look at old pictures on walls<br />
        faded grandeur and mirrors that catch ghosts in the distance<br />
        is it African, Arab or European?<br />
        you choose what you want <br />
        a Parisian dream in Africa: look out, observe, think and be inspired<br
        />
        without fail I always sketch, design and have new ideas<br />
        a must do for literary types
      </p>

      <GraffitiBox />

      <p class="align-right r12">
        looks like writers know how to choose hotels<br />
        if u like me are a Burroughs fan take room nine<br />
        my friend stayed in room 4<br />
        the one used by Ginsberg<br />
        but nothing was pointed out to us
      </p>

      <p class="align-right r13">
        young Moroccan rock fans hung out there<br />
        & didn’t have any idea who these strange old men were
      </p>

      <img
        src="./visuals/c6b.jpeg"
        alt="Tangier"
        class="poem-image align-right r14"
      />

      <p class="r14">
        To solve a disagreement among France, Spain, and the United Kingdom over
        its control, the city was made a neutral demilitarised zone in 1924
        under a joint administration according to an international convention
        signed in Paris on 18 December 1923. Although some disagreements emerged
        about the agreement, ratifications were exchanged in Paris on 14 May
        1924. The convention was amended in 1928. The governments of Italy,
        Portugal, and Belgium adhered to the convention in 1928, and the
        government of the Netherlands in 1929. The Zone had its own appointed
        International Legislative Assembly, which was subject to supervision by
        a Committee of Control consisting of the Consuls of Belgium, France,
        Britain, Italy, the Netherlands, Portugal, and Spain. Executive power
        was vested in an Administrator, and judicial power resided in a Mixed
        Court of five judges, respectively appointed by the Belgian, British,
        Spanish, French, and Italian governments. As a result of the creation of
        the Mixed Court, the various European powers withdrew the consular
        courts that previously exercised jurisdiction there. The Zone had a
        reputation for tolerance, diversity of culture, religion, and
        bohemianism. It became a tourist hotspot for literary giants and gay men
        from Western countries. Many of the latter were able to live an openly
        “out” life in the Zone.
      </p>

      <p class="r15">
        mostly nonsense draped in more nonsense<br />
        lots of subplots that don’t make sense<br />
        gratuitous kink<br />
        nice to look at<br />
        the colours, contextual setting, backdrop and clothes<br />
        the erotic element intriguing<br />
        vibrant sumptuous and sultry<br />
        intoxicating and moreish<br />
        irresistible as the kaleidoscopic scenes<br />
        bizarrely slide into a spy story about arms dealers<br />
        like being invited to an extravagant burlesque show<br />
        then watching bare knuckle fist fighters take over the stage
      </p>

      <p class="r16 span-2">
        style over substance<br />
        <span class="spacer s8">&nbsp;</span>dazzling<br />
        <span class="spacer s16">&nbsp;</span>scintillating<br />
        <span class="spacer s26">&nbsp;</span>gripping<br />
        <span class="spacer s38">&nbsp;</span>pacy<br />
        <span class="spacer s52">&nbsp;</span>hilarious<br />
        <span class="spacer s68">&nbsp;</span>unmissable
      </p>

      <p class="align-center r17">consider a woman</p>

      <p class="align-center r18">
        who did what she wanted and didn’t ask anyone’s permission<br />
        who was married to multiple people and tried some pretty intense things<br
        />
        standing over and urinating on a bound and gagged man
      </p>

      <p class="align-center r19">
        vividly imagined hedonistic parties<br />
        orgiastic dances<br />
        the foreigners’ lavishly decorated mansions
      </p>

      <p class="align-center r20">
        a deliberate contrast with the locals’ spartan quarters<br />
        a narrative that is kinder<br />
        more tolerant than one constrained by her era
      </p>
    </div>
  </div>
</div>

<style>
  .poem {
    margin-bottom: 100px;
  }
  h1,
  .intro {
    max-width: 740px;
    margin: 10vh auto;
    text-align: center;
  }
  .inner-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 20px;
  }
  p {
    grid-column: 1;
  }
  .poem-image {
    max-width: 100%;
    object-fit: contain;
  }
  .align-center {
    grid-column: 2;
  }
  .align-right {
    grid-column: 3;
  }
  .r1 {
    grid-row: 1;
  }
  .r2 {
    grid-row: 2;
  }
  .r3 {
    grid-row: 3;
  }
  .r4 {
    grid-row: 4;
  }
  .r5 {
    grid-row: 5;
  }
  .r6 {
    grid-row: 6;
  }
  .r7 {
    grid-row: 7;
  }
  .r8 {
    grid-row: 8;
  }
  .r9 {
    grid-row: 9;
  }
  .r10 {
    grid-row: 10;
  }
  .r11 {
    grid-row: 11;
  }
  .r12 {
    grid-row: 12;
  }
  .r13 {
    grid-row: 13;
  }
  .r14 {
    grid-row: 14;
  }
  .r15 {
    grid-row: 15;
  }
  .r16 {
    grid-row: 16;
  }
  .r17 {
    grid-row: 17;
  }
  .r18 {
    grid-row: 18;
  }
  .r19 {
    grid-row: 19;
  }
  .r20 {
    grid-row: 20;
  }
  .span-2 {
    grid-column: 1 / 3;
  }
  .spacer {
    display: inline-block;
  }
  .s8 {
    width: 8em;
  }
  .s16 {
    width: 13em;
  }
  .s26 {
    width: 19em;
  }
  .s38 {
    width: 26em;
  }
  .s52 {
    width: 36em;
  }
  .s68 {
    width: 48em;
  }
  .redacted {
    background-color: black;
  }
</style>
