<script>
  import { fade } from "svelte/transition";
  import GraffitiBox from "../toasts/GraffitiBox.svelte";
</script>

<div class="sceneWrapper" transition:fade={{ delay: 450, duration: 600 }}>
  <div class="poem" transition:fade={{ delay: 2000, duration: 300 }}>
    <div class="intro-wrapper">
      <img class="intro-image" src="./visuals/k1b.jpeg" alt="Pyramid in Rome" />
      <p class="intro">
        <em>
          To begin with, I have no difficulty remembering my visit to the
          cemetery, or the importance it might have held for me at the time. It
          was clear that these particular individuals were to be kept to one
          side of the eternal city. The cats were thick on the pavements and the
          beggars ladling gruel for them were few and far between. Shards of
          glass peppered the tops of walls that lined the streets and anti-climb
          goo coated their sides. This obliterated the atmosphere found
          elsewhere in the city where one could climb freely among monuments and
          ruins. There, and in those days, posing for photographs was
          encouraged. Those photographs required materials and processing time
          in order to see them at all. I was searching for the poet who had died
          by drowning and had the book of another poet in his pocket at the
          time; that other poet is also buried there. The main impediment to my
          ability to sense the shimmering significance of the moment – me, a
          young poet, visiting the graves of young poets in an ancient city –
          was the rain, which was unusual for that city, even at that time of
          year. I could only think of socks, dry socks. To journey there again
          now all these years later and from the comfort of my home must be a
          rare privilege.</em
        >
      </p>
    </div>

    <p>
      Here lies one nice old gate.<br />
      Amazing to think how long <br />
      this gate has been in its place.
    </p>

    <p>
      We walked the street.<br />
      It was fascinating.<br />
      Another great gate. Loved it.
    </p>

    <p>
      The pyramid together <br />
      with this monument is <br />
      an incredible revelation to me.
    </p>

    <p>
      Just get rid of your copper EU's. <br />
      You will be here longer than you think.<br />
      This grave contains
    </p>

    <p>
      historical, amazing<br />
      nestled behind the pyramid<br />
      a lyric poet without a rival.
    </p>

    <p>
      Not a top-tier attraction<br />
      not a must visit, but very unique.<br />
      It was clear he is passionate.
    </p>

    <p>
      Why does he write this?<br />
      Maybe he doesn't realize <br />
      what moment we are going through
    </p>

    <p>
      and how much we want to try<br />
      to make sure that everything is within the rules.<br />
      The poor fellow seems to have been hooted from the stage of life.
    </p>

    <p>
      The diversity and depth of individuals who<br />
      – through accident or intent – came to rest here.<br />
      The broken lily the pitiful brilliant boy
    </p>

    <p>
      he had a gift for grinning <br />
      in the bitterness of his heart.<br />
      It seems a junction of every possible kind
    </p>

    <p>
      of traffic, an endless stream of cars <br />
      and motobikes, trains, trams, the metro.<br />
      Very quiet and well maintained, I love this peaceful place so much.
    </p>

    <p>
      This dubs as a kitty sanctuary too.<br />
      Flowers everywhere. Some ancient big shot <br />
      whose name I forget. I died away
    </p>

    <p>
      from my home, but I want you to know who I am.<br />
      The heart in a silken shroud <br />
      historical value but a little scruffy
    </p>

    <p>
      the fried fish smell and seems defrost <br />
      rude rude rude<br />
      calcified due to an earlier bout with tuberculosis.
    </p>

    <p>
      A little gruff but not too bad<br />
      the heart supposedly survived cremation and was snatched out of the flames.<br
      />
      To pay a visit to some literary or philosophical giants
    </p>

    <p>
      I went to this post office in order to buy <br />
      one stamp. The clerk wasn't able to sell me a stamp <br />
      right from the desk. She seemed to be astonished about my request.
    </p>

    <p>
      Be careful of your surroundings, especially <br />
      if you are a woman traveling<br />
      Taking all that into account,
    </p>

    <p>
      maybe what Mary Shelley did <br />
      when her husband died wasn't that weird.<br />
      But if you write like this, it really hurts us,
    </p>

    <p>
      without counting everything behind it <br />
      and that few who read this can understand<br />
      ‘expat’ life and death in the city.
    </p>

    <p>
      Reflective. It’s not morbid or depressing as some might think. <br />
      As a matter of fact John Keats was 5 feet 1 high stocky <br />
      and quick with reddish brown hair and something
    </p>

    <p>
      about him that made men and women <br />
      forget what size he actually was.<br />
      This is the poet Shelley speaking.
    </p>

    <p>
      Packs a punch – Pyramid metro, cross the busy road …<br />
      Cypress trees shade this high-end, contemporary <br />
      restaurant with a sushi conveyor belt
    </p>

    <p>
      & Japanese à la carte dining <br />
      whose name was writ in water. <br />
      For overall taste I find it quite lacking.
    </p>

    <p>
      The heart was found in her desk <br />
      wrapped in the manuscript <br />
      very useful to reach the seaside area
    </p>

    <GraffitiBox />

    <p>
      albeit with some risks. <br />
      Ok<br />
      Zero! Incredibile!
    </p>

    <p>
      A little backward<br />
      I am happy to share<br />
      a helpful window in the wall
    </p>

    <p>
      refused to burn<br />
      his heart. <br />
      A beautiful mystery.
    </p>

    <p>
      Vanité is fantastic ... you find crazy socks, <br />
      very nice underwear and very special <br />
      clothing, together with a deja vu atmosphere
    </p>

    <p>
      The sign says there are some relics inside<br />
      If you want to have nice photos of this mess, <br />
      I definitely recommend it either in the morning or in the evening.
    </p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
  </div>
</div>

<style>
  .sceneWrapper {
    background-color: #ece5df;
    max-width: 50%;
    height: 100%;
    margin-left: 50%;
    padding: 20px 20px 25vh;
    margin-top: -10px;
    overflow-y: scroll;
  }
</style>
