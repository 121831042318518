<script>
  import { onMount } from "svelte";
  import { tags, navHighlights } from "../store";
  import { scenes } from "../scenes";

  const updateMessage = (messages, target) => {
    let rnd = Math.floor(Math.random() * messages.length);
    let message = messages[rnd];
    if (target && message === target.textContent) {
      updateMessage(target);
    } else {
      target.textContent = message;
    }
  };

  const handleTagNav = (tag) => {
    $navHighlights = $scenes.filter((s) => s.tags.find((t) => t === tag));
  };

  onMount(() => {
    setInterval(() => {
      updateMessage($tags, document.querySelector(".scrolling-message"));
    }, 10000);
  });
</script>

<p class="status-bar-field scrolling">
  <span
    class="scrolling-message"
    on:click={(e) => handleTagNav(e.target.textContent)}>{$tags[0]}</span
  >
</p>

<style>
  @keyframes -global-scroll {
    97% {
      transform: translateX(-220px);
      opacity: 1;
    }
    98% {
      transform: translateX(-220px);
      opacity: 0;
    }
    99% {
      transform: translateX(102%);
      opacity: 0;
    }
    100% {
      transform: translateX(102%);
      opacity: 1;
    }
  }
  .scrolling {
    overflow: hidden;
    max-width: 60%;
  }
  .scrolling-message {
    white-space: nowrap;
    display: block;
    transform: translateX(102%);
    animation: scroll 8s linear infinite;
  }
  .scrolling-message:hover {
    text-decoration: underline;
  }
  .scrolling:hover {
    cursor: pointer;
  }
</style>
