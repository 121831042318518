<script>
  import { onMount } from "svelte";
  import ScrollingMessage from "./ScrollingMessage.svelte";
  import NavLink from "./NavLink.svelte";
  import { activeModal } from "../store";
  import { scenes } from "../scenes";

  let minimized = false;
  let closed = false;
  let cpu = 14;

  const toggleModal = (modal) => {
    if ($activeModal != modal) {
      $activeModal = modal;
    } else {
      $activeModal = false;
    }
  };

  const updateCPU = (cpuBase) => {
    let multiplier = [1, 2, 3, 4, 5];
    let swing = [1, 2, 3];
    let direction = [0, 0, 1, 2];

    let rnd = (arr) => {
      let r = Math.floor(Math.random() * arr.length);
      return arr[r];
    };

    let m = rnd(multiplier);
    let s = rnd(swing);
    let d = rnd(direction);
    let v;

    if (d === 0) {
      v = cpuBase;
    }
    if (d === 1) {
      v = cpuBase + m * s;
    }
    if (d === 2) {
      v = cpuBase - m * s;
    }
    return v;
  };

  onMount(() => {
    var dragItem = document.querySelector("#global-nav");
    var container = document.querySelector(".navWrapper");

    var active = false;
    var currentX;
    var currentY;
    var initialX;
    var initialY;
    var xOffset = 0;
    var yOffset = 0;

    container.addEventListener("touchstart", dragStart, false);
    container.addEventListener("touchend", dragEnd, false);
    container.addEventListener("touchmove", drag, false);

    container.addEventListener("mousedown", dragStart, false);
    container.addEventListener("mouseup", dragEnd, false);
    container.addEventListener("mousemove", drag, false);

    function dragStart(e) {
      if (e.type === "touchstart") {
        initialX = e.touches[0].clientX - xOffset;
        initialY = e.touches[0].clientY - yOffset;
      } else {
        initialX = e.clientX - xOffset;
        initialY = e.clientY - yOffset;
      }

      active = true;
    }

    function dragEnd(e) {
      initialX = currentX;
      initialY = currentY;

      active = false;
    }

    function drag(e) {
      if (active) {
        e.preventDefault();

        if (e.type === "touchmove") {
          currentX = e.touches[0].clientX - initialX;
          currentY = e.touches[0].clientY - initialY;
        } else {
          currentX = e.clientX - initialX;
          currentY = e.clientY - initialY;
        }

        xOffset = currentX;
        yOffset = currentY;

        setTranslate(currentX, currentY, dragItem);
      }
    }

    function setTranslate(xPos, yPos, el) {
      el.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0)";
    }

    setInterval(() => {
      cpu = updateCPU(14);
    }, 250);
  });
</script>

<div class="navWrapper">
  <nav id="global-nav" class="window" class:minimized class:closed>
    <div class="title-bar">
      <h1 class="global-nav__title">sorry that you were not moved</h1>
      <div class="title-bar-controls">
        <button
          aria-label="Minimize"
          on:click={() => (minimized = !minimized)}
        />
        <button
          aria-label="Maximize"
          on:click={() => (minimized = !minimized)}
        />
        <button aria-label="Close" on:click={() => (closed = true)} />
      </div>
    </div>
    <ul class="tree-view">
      <li class="sub-list">
        Poems
        <ul>
          {#each $scenes.filter((s) => s.id !== 0) as link}
            <NavLink linkID={link.id} linkTitle={link.title} />
          {/each}
        </ul>
      </li>
      <NavLink linkID={0} linkTitle={"Home"} />
      <li on:click={() => toggleModal("about")}>About</li>
      <li on:click={() => toggleModal("reviews")}>Reviews</li>
    </ul>
    <div class="status-bar">
      <p class="status-bar-field">
        CPU: {cpu}%
      </p>
      <ScrollingMessage />
    </div>
  </nav>
</div>

<style lang="scss">
  #global-nav {
    width: 280px;
    margin: 10px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 14px;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 999;
  }
  .window.minimized .tree-view {
    display: none;
  }
  .window.closed {
    display: none;
  }
  .title-bar {
    display: flex;
  }
  .global-nav__title {
    margin: 0;
    font-size: 12px;
    padding: 4px 8px;
    color: white;
  }
  .title-bar:hover {
    cursor: move;
  }
  .title-bar-controls button:hover {
    cursor: pointer;
  }
  li {
    text-decoration: none;
    color: black;
  }
  li:not(.sub-list):hover {
    color: #aaa;
  }
  .status-bar {
    will-change: transform;
  }
  .status-bar-field {
    font-variant-numeric: tabular-nums;
  }
</style>
