import { writable } from "svelte/store";

export let themeColour = writable("#fff");
export let mapX = writable(53.37187);
export let mapY = writable(-6.27880);
export let mapZ = writable(14);
export let mapActive = writable(false);
export let activeScene = writable(0);
export let activeModal = writable(false);
export let audioSrc = writable(null);
export let tags = writable(["sorry that you were not moved"]);
export let activeToast = writable(null);
export let homeLoc = writable({x: null, y: null});
export let navHighlights = writable([]);
export let locationHistory = writable(localStorage.getItem("locationHistory") ? JSON.parse(localStorage.getItem("locationHistory")) : []);
export let hitCounter = writable([]);
