<script>
  import { activeToast } from "../store";
</script>

{#if $activeToast}
  <svelte:component this={$activeToast} />
{/if}

<style>
  :global(.toast) {
    position: fixed;
    z-index: 100;
    bottom: 40px;
    width: 40vw;
    min-width: 350px;
    border: 2px solid black;
    border-radius: 3px;
    padding: 20px;
    left: 30vw;
    background-color: white;
  }
</style>
