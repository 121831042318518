<script>
  import { fade } from "svelte/transition";
</script>

<div class="sceneWrapper" transition:fade={{ delay: 450, duration: 600 }}>
  <div class="poem" transition:fade={{ delay: 2000, duration: 300 }}>
    <div class="intro post">
      <h1>Hello, Sydney</h1>
      <div class="post-content">
        <em>
          Through his florid descriptions of naked women of astounding beauty
          frolicking in cities always bearing a female name, Calvino may or may
          not have been writing of the unreality of nostalgia, and the falseness
          of memory. And in desire networks (we both noted his repeated
          references to nets) exemplified by his elucidation of places lying
          inert for the travelling gaze to manipulate and consume, we realise
          disparate kinds of ownership. As a reader-navigator devouring its
          orientalist glitz & glamour I’m being led to map it onto contemporary
          scrolls of spectacle and hedonism monetised through the endless
          packaging and flattening of the experience of pleasure. I love
          surface; surface tells me everything I need to know. Clubbing in
          Sydney with women called Sydney serviced by characters named Sydney
          has made my week.
        </em>
      </div>
    </div>

    <div class="post">
      <div class="post-header">
        <span class="post-date">23rd March, 2021</span><span class="post-number"
          >#55</span
        >
      </div>
      <div class="post-content">
        Ever dream of getting up on stage and strutting your stuff with a drag
        queen or dancing in a cage with an oiled-up hottie? The trick is knowing
        where to look and not relinquishing a night out because you think it
        can’t be done. It can. Remember when Guy Pearce christened a giant
        silver bus ‘Priscilla’? That champagne-smashing moment happened outside.
        If you ask anyone who lives there, Bondi Junction is the last place
        you’d expect to have decent snug lounges and cosy sign-posted book nooks
        (I like big books and I cannot lie). We’re not keen to move too far. We
        watch the grey ghostly navy ships glide by at the end of the wharf, look
        over a sculpture exhibition in one section of the cavernous space and
        have a luxurious swim in the stunning indoor/outdoor pool, complete with
        spa and cabanas which we have to ourselves. One look at one of the
        hottest young Hollywood actresses – she frolicked in the ocean wearing a
        revealing red bikini – experimenting with the idea of ‘meta-horror’ and
        on first viewing you can enjoy it on surface level, the slasher/mystery
        vibe and characters. How on earth does someone who is at the centre of
        several investigations and a major class action over the death of 38
        people obtain a travel exemption to fly?
        <img src="./visuals/c4a.png" alt="club" class="attachment" />
      </div>
      <div class="post-footer">
        <button class="footer-button like"
          ><img src="./visuals/thumbup.png" alt="like-button" /></button
        >
        <button class="footer-button share">Share</button>
        <button class="footer-button reply">Reply</button>
        <button class="footer-button quote">Quote</button>
      </div>
    </div>

    <div class="post">
      <div class="post-header">
        <span class="post-date">28th March, 2021</span><span class="post-number"
          >#73</span
        >
      </div>
      <div class="post-content">
        This case exemplifies what is wrong with the current concept of the
        appalling treatment of First Australians. It reinforces the need for
        truth telling as sought by the Uluru Statement. There is a simple way to
        put this right. We need a truth and reconciliation commission to bring
        it all into the light. Nearly 80 per cent of electricity generated is
        from fossil fuels. Coal accounted for 56 per cent of generated
        electricity. Electric vehicles still rely on electricity that is
        generated mostly from non-renewable sources. They are environmentally
        friendlier but not quite the lean, green mobile machines they could be.
        The cost of electric vehicles, the environmental impact of recycling
        their batteries, road usage taxes and the supply of public re-charging
        facilities will also need to be refined before these vehicles present a
        viable alternative. Like the EV or the wristwatch, smartphones were
        invented by us and are used for our information and convenience. Most of
        us would feel naked without one: we have become dependent on our own
        creation. I frequently see people so totally engrossed in recorded music
        or podcasts that they are oblivious to human company. Could this presage
        the demise of live concerts?
      </div>
      <div class="post-footer">
        <button class="footer-button like"
          ><img src="./visuals/thumbup.png" alt="like-button" /></button
        >
        <button class="footer-button share">Share</button>
        <button class="footer-button reply">Reply</button>
        <button class="footer-button quote">Quote</button>
      </div>
    </div>

    <div class="post">
      <div class="post-header">
        <span class="post-date">5th May, 2021</span><span class="post-number"
          >#97</span
        >
      </div>
      <div class="post-content">
        Hey, if you’re feeling the natural vibe then get to strippin’. Get
        liberated, you’ll love it. Here Muslim women throw off their hijabs, and
        lesbian couples rub shoulders with nannas doing daily laps. Stretches of
        sand that are perfect for a bake, a perv and maybe even a pick-up, an
        inclusive hub where you can drink cocktails with rubber ducks in them
        and see drag most nights of the week, from lively amateur performers to
        matriarch queens, turns into a neon-bathed rager after hours, somewhere
        that pumps out party vibes even on a Wednesday and is all about cheap
        fun. As a blow-in from the bush, my first encounter as I ascended on
        this corkscrew from the locker room/cafeteria area was Sydney showing
        off her fabulous figure in the two-piece which she wore to go snorkeling
        the warm waters. Her beau also showcased a rip physique with impressive
        abs as he wore a pair of white patterned swim trunks. The prettiest boys
        around pack out the courtyard, and after a few $6 pints they start to
        head upstairs. I kept moving and the same guy kept coming back (picture
        attached) and stationing himself next to me – there was some incident
        where an agitated girl or woman with nothing but a bra on jumped. And
        there is drinking, smoking, language, and talk about sexual activity.
        The employees will take pictures of you and will charge you an insane
        amount of money to buy the photos. At one point they’ll show the pics on
        a big screen.
        <img src="./visuals/c4c.jpeg" alt="beau" class="attachment" />
      </div>
      <div class="post-footer">
        <button class="footer-button like"
          ><img src="./visuals/thumbup.png" alt="like-button" /></button
        >
        <button class="footer-button share">Share</button>
        <button class="footer-button reply">Reply</button>
        <button class="footer-button quote">Quote</button>
      </div>
    </div>

    <div class="post">
      <div class="post-header">
        <span class="post-date">21st May, 2021</span><span class="post-number"
          >#110</span
        >
      </div>
      <div class="post-content">
        Ahhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh! That was a scream if you didn’t
        know. Back in my day this thingy that kids call shows came out. Now that
        my grandchildren have introduced me to all of these new things like face
        book, I have found meaning to life. This show was amazing. Back in my
        days I had to drive to the theaters. Nowadays these kids just rent it
        out on that rain forest what was it called the Ameson or something like
        that or that youtoozs thing. Kids should get up and be more active like
        us adults used to. Perhaps even worse, smartphones are increasing in
        capability at such a rate that users will need no other source of
        infotainment. The revelations in “fortunes built on back of slavery” are
        just about the tip of the iceberg. I was sickened to read about it.
        According to the most recent data on the government’s website, Sydney’s
        article should be a warning to all of us. Satire with golden delivery, a
        brilliant satirical criticism.
      </div>
      <div class="post-footer">
        <button class="footer-button like"
          ><img src="./visuals/thumbup.png" alt="like-button" /></button
        >
        <button class="footer-button share">Share</button>
        <button class="footer-button reply">Reply</button>
        <button class="footer-button quote">Quote</button>
      </div>
    </div>

    <div class="post">
      <div class="post-header">
        <span class="post-date">6th June, 2021</span><span class="post-number"
          >#139</span
        >
      </div>
      <div class="post-content">
        Sydneysiders are now able to get out for a groove again (albeit only 50
        of us at a time). We love to party – we’ll party on the roof, in the
        basement, by the pool, even once every few months in a suburban bowling
        club. With a spiral staircase drinking below street level is also
        deliciously climate controlled so on a blazing hot summer’s day, or in a
        torrential downpour, your best bet is posting up in one of the city’s
        best booze cellars. You’d have no idea this subterranean ’70s porno chic
        boozer even existed. And that would be a shame, because good times are
        writ large. The atmosphere of ravers going at it is so enjoyable, and
        whilst the drinks are very expensive, there is no need for drinks as the
        music is so loud you can enjoy the experience without requiring alcohol
        (although I would pre-drink). Scream is a very popular spot with the
        boys, who tend to show off their gym efforts with aplomb here all year
        long, and especially on hot summer days when it’s jam-packed with
        glistening, muscular bodies in the smallest budgie smugglers you can
        imagine. Not for the insecure (or the pale). It’s been clothing-optional
        since 1976. It can be a bit of a boys’ club looking to cruise, and you
        can expect stink-eye if you choose the option of actually wearing
        clothing. It would’ve been great if they had AC in the small room where
        we were suited/unsuited. Feeling anxiety due to the heat in that room we
        were initially refused leaving to regain normality and then return in a
        better state, but we spoke to Sydney who was more than assistive and
        understanding and allowed my 11 yo and myself to do so.
      </div>
      <div class="post-footer">
        <button class="footer-button like"
          ><img src="./visuals/thumbup.png" alt="like-button" /></button
        >
        <button class="footer-button share">Share</button>
        <button class="footer-button reply">Reply</button>
        <button class="footer-button quote">Quote</button>
      </div>
    </div>

    <div class="post">
      <div class="post-header">
        <span class="post-date">9th July, 2021</span><span class="post-number"
          >#165</span
        >
      </div>
      <div class="post-content">
        No aged-care home mogul who lives an extravagant high life while people
        die in his care, and while conditions in two of his homes deteriorate to
        appalling levels, should exploit vulnerable residents in this way. It
        was my first day working at the aged care facility. Mutualise all
        facilities. Abolish for-profit aged care. Residents and their relatives
        would then become owners.
      </div>
      <div class="post-footer">
        <button class="footer-button like"
          ><img src="./visuals/thumbup.png" alt="like-button" /></button
        >
        <button class="footer-button share">Share</button>
        <button class="footer-button reply">Reply</button>
        <button class="footer-button quote">Quote</button>
      </div>
    </div>

    <div class="post">
      <div class="post-header">
        <span class="post-date">10th July, 2021</span><span class="post-number"
          >#178</span
        >
      </div>
      <div class="post-content">
        Hi there! We offer the best Friday night, with glamorous dancers, tonnes
        of confetti, blasts of CO2, suspended jungle vines and golden animals.
        The new face of Friday nights, the night features pumping party music
        you can get down and dirty to, coupled with a crazy party atmosphere
        unlike anywhere else in the city. Cover is usually only $15. Typical
        crowd ranges from locals to backpackers, students and all-around party
        goers looking to dance the night away. Saturdays we have 2 rooms, one
        focused on EDM and the other playing R&B party jams. Upscale clientele
        and strict dresscode with general admission around $40 and always huge
        name DJs headlining the event weekly. This is the most upscale party on
        a Saturday night, VIP experience all the way. With nearly 20,000 square
        feet of floor space, and a capacity for 1,500 people, the venue is
        designed to house three distinct spaces within the one venue to cater
        for different tastes and moods. We have built an undisputed reputation
        as the premiere entertainment destination and celebrity venue of choice
        with unbeaten national and international talent. One of our weekend
        offerings will surely suit the best weekend nightlife experience you’re
        looking for that ranges from easy on the pocket to the most high-end
        offerings, but always has the best atmosphere and music in Sydney!
        <img src="./visuals/c4d.jpeg" alt="beau" class="attachment" />
      </div>
      <div class="post-footer">
        <button class="footer-button like"
          ><img src="./visuals/thumbup.png" alt="like-button" /></button
        >
        <button class="footer-button share">Share</button>
        <button class="footer-button reply">Reply</button>
        <button class="footer-button quote">Quote</button>
      </div>
    </div>
  </div>
</div>

<style>
  .sceneWrapper {
    overflow-y: scroll;
    height: 100%;
  }
  .intro {
    margin-top: 25vh !important;
  }
  h1 {
    text-align: center;
  }
  .post {
    max-width: 740px;
    border: 1px solid #283057;
    background-color: white;
    border-radius: 2px;
    margin: 20px auto;
  }
  .post-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #283057;
    color: white;
    font: bold 11px tahoma, verdana, geneva, lucida, "lucida grande", arial,
      helvetica, sans-serif;
    padding: 3px 8px;
  }
  .post-content {
    padding: 5px 10px 16px;
  }
  img {
    max-width: 100%;
    margin: 10px 0;
  }
  .post-footer {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
  }
  .footer-button {
    margin: 0 5px;
    background-color: #374079;
    color: white;
    padding: 0 10px;
    line-height: 0;
    border: none;
    min-width: 0;
    min-height: 0;
    box-shadow: none;
    border-radius: 0;
  }
  .footer-button:hover {
    background-color: #283057;
    cursor: pointer;
  }
  .like {
    padding: 0;
    background-color: black;
  }
  .like:hover img {
    opacity: 0.75;
  }
  .footer-button img {
    max-width: 24px;
    object-fit: contain;
    margin: 0;
  }
</style>
