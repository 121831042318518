<script>
  import { fade } from "svelte/transition";
  import CanvasVideo from "../CanvasVideo.svelte";
</script>

<div class="sceneWrapper" transition:fade={{ delay: 450, duration: 600 }}>
  <div class="poem" transition:fade={{ delay: 2000, duration: 300 }}>
    <h1>Look at this cosmopolitan architecture</h1>

    <p>
      <em
        >I was looking for simple comforts after weeks of traveling. I found
        myself among some of my own people, the same people who, when traveling,
        I normally reject and denigrate. You’ve said you would really like to go
        there one day, and I think by reading this it will certainly happen for
        you.</em
      >
    </p>

    <p>
      This beach is another white sandy beach – oozing
      with&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;! lots
      of&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;do different activities like yoga,
      belly dancing, couples staring at each other. But all this happens most
      after october to feb. A trek inside the forest, a mud bath, a yoga man –
      place to fag, relax.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;playing Congo and
      other music instruments, dancing and playing fire tricks. The sand here is
      also known to have healing properties. Everything is programmed in a way
      to suit &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; like food, environment,
      lifestyle, shops. This is the place where you can relax and chill…I think
      that’s why&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;like it more than
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;…this place allows them to be what
      they are. Shopkeepers and restaurant staff is very arrogant with
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;customers, they are happy to
      serve&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;. Whole place is occupied
      with&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; hippies having drugs openly on
      the beach and in the restaurants. Lot
      of&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;visit here
      specially&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;. If u r lucky enough then u
      might be able to see few of them enjoying themselves and dancing. They
      even sell one or the other things on d beach. This beach is quite popular
      among the&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;. They get whatever privacy
      they need. The best part – it is less crowded and most of the visitors
      there are &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;. Later on I discussed this
      with a&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;friend. He told me that the
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; mafia was behind such nuisance. They
      don’t want&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;to come here and keep it as
      their haven. The only thing i would say is that alot
      of&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;have ruined this spot due to it
      being a Disneyland for &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;. It is a nice
      place with all the beautiful faces all over the world. Most people are
      from &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;. Yes there are
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; people some with families. You can
      also make hair like Bob Marley. This place is fill with hippies. Since
      paid parking took place most of coconuts trees dried up. Unfortunately, it
      has a rep for easy women and the half nude women being inappropriate
      doesnt help. The shacks here don’t treat
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; travellers on par with
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;. Mostly you might
      see&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;more than the
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; crowd and that’s one of its greatest
      attraction also. So all those creative people looking for a view without
      the noise and lights…this is the place you’ve been searching for!! On the
      beach itself there’s lots of usual shacks with shaded sunbeds but mainly
      filled with drinking&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;which was sad to
      see. They seem to have taken over the north coast. i really liked around
      it such calm and cool people around the city specially
      the&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;, you will see more of them and the
      signs in &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;language as well :) Less
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;but
      more&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      and&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;seen enjoying their swim and sun.
      Very bad vibes everywhere. To many&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;,
      zero nice and kind atmosphere. Yoga place??? Noooo…!!! It is very very far
      from that feeling. If you are&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;, you
      feel exotic here. 99% of the crowd is&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;.
      What this means is that you are not judged, not mocked, not stared at and
      definitely not looked down upon. In
      evening&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; are performing different
      activities, like aerobics/yoga and dance etc. Luckily, we got to see one
      wedding on the beach and surprisingly it was
      some&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; wedding. No wonder, they
      like&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;culture so much. It is very good
      beach a little dirt but well is&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;, in
      the center beach is very noisy especially in the night, too many bars in
      this area and too many&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; there. The
      beach dirty and messed up. Very
      few&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;people around and hardly
      any&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;cuisine on offer. I saw few
      advertisment of yoga meditation classes, it seems
      many&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; are finding it very useful for
      them. The Unpleasant Thing On Beach: After it gets
      dark,&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;lasses roam on the beach trying
      to sell their own flesh. The highlight of the beach is
      the&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;population here. The simple living
      style of the&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;out there and the
      easy-going attitude of the&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (staying
      there) adds up to the relaxed air of the place. So
      many&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; though which wouldn’t be a
      problem except some are very rude to the
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;which is disappointing. It is a place
      where you can be yourself. And backpackers from
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;,&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;,&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;,&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      ,&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;gather there. Great friends I found
      in them. This place is my wonderful discovery and still a Virgin Beach!
      dominated by a most of the &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; national
      from
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;,&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;,&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;,
      and from many other &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; people. Mostly a
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; joint, but they are friendly people
      and we made some great friends. This is the original, the enduring, home
      of the “man bun”, home of harem pants and incense and “Om Ganesh” and
      terrible embroidered shoulder bags no
      self-respecting&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;lady would ever wear…It
      is nightly drum circle and&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;beach
      vendors hawking wares at sunset. This beach
      is&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;mostly,
      young&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;families with beautiful tanned
      naked babies playing in the sand, a few parties, chilled out and amazing.
      Good, solid crowd of people who want to relax and take life as it comes
      and aren’t trying to get you to do anything in particular. The beach is
      way cleaner than any other beach you may visit and crowd basically
      consists
      of&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;and&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;.
      Unlike many places in&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;,
      the&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;population here massively outweighs
      the&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;. So it’s very easy in terms of
      communication, but you won’t get any feel
      for&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;. Also worth noting that perhaps as
      much as 75% of the&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;here
      are&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;. Thou shalt not wear heels on the
      beach. Thou shalt not drool over our bikini-clad sisters. I felt
      uncomfortable in the town and on the beach at night. Aimed
      at&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;. The hawkers didn’t seem as feisty
      as the ones on other beaches. Lovely beach by day, Blackpool by night.
      There is something about this place. It calls your soul again and again. A
      lot of changing hands and cultures sums it up. It’s nice to
      see&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; relaxing. Rock art engravings
      found the earliest traces of human life here. Difficulty in carbon dating
      the laterite rock compounds poses a problem for determining the exact time
      period. There is not a proper garbage solution. They may not have the same
      connections, but they have to acquire the connection. I was there in the
      first week of Jan on my 39th visit. After awhile the place sucks you in.
      After a few days, I booked in for six more weeks and took up daily yoga. I
      will probably stay for the season. After that, I booked in for a week. It
      is the land of opportunity for me now.
    </p>

    <div class="videowrapper1">
      <CanvasVideo src="visuals/k2a.mp4" />
    </div>
    <div class="videowrapper2">
      <CanvasVideo src="visuals/k2b.mp4" />
    </div>
  </div>
</div>

<style>
  .videowrapper1 {
    position: fixed;
    bottom: 100px;
    left: 40px;
  }
  .videowrapper2 {
    position: fixed;
    bottom: 10px;
    right: 40px;
  }
  .poem {
    max-width: 760px;
    margin: 40px auto;
  }
  .poem p {
    max-width: 740px;
    text-align: justify;
    font-size: 1.1rem;
    line-height: 1.5;
  }
</style>
