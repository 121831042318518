 import { readable } from "svelte/store"; 

 import Rome from "./components/routes/protestant-cemetery-rome.svelte";
 import Diana from "./components/routes/sunday-31-august-1997.svelte";
 import Goa from "./components/routes/goa.svelte";
 import Beach from "./components/routes/the-beach.svelte";
 import Nicosia from "./components/routes/nicosia.svelte";
 import Elkhart from "./components/routes/elkhart.svelte";
 import Saigon from "./components/routes/saigon-sonnet.svelte";
 import Sydney from "./components/routes/hello-sydney.svelte";
 import Cincinnati from "./components/routes/cincinnati.svelte";
 import ATP from "./components/routes/all-tomorrows-parties.svelte";
 import EnigmaticTangier from "./components/routes/enigmatic-tangier.svelte";
 import FictitiousTangier from "./components/routes/fictitious-tangier.svelte";
 import DublinOH from "./components/routes/dublin-ohio.svelte";
 import DublinAUS from "./components/routes/dublin-australia.svelte";
 import Galapagos from "./components/routes/galapagos.svelte";
 import Yakutsk from "./components/routes/yakutsk.svelte";
 import Martinique from "./components/routes/martinique.svelte";
 import Neuland from "./components/routes/neuland.svelte";
 import Ys from "./components/routes/ys.svelte";
 import Atlantis from "./components/routes/atlantis.svelte";

export let scenes = readable([
	{ 
		id: 0, 
		component: null, 
		title: "Home", 
		tags: ["sorry that you were not moved", "the language barrier is going to be vast here", "you could spend a long time here"],
		mapX: 53.37187,
		mapY: -6.27880,
		mapZ: 5,
		colour: null,
		audio: null 
	},
	{ 
		id: 1, 
		component: Rome, 
		title: "You will be here longer than you think", 
		tags: ["MEMORY", "THE DEAD", "HIDDEN", "POET"],
		mapX: 41.87575,
		mapY: 12.47883,
		mapZ: 14,
		colour: "#a71d36b3",
		audio: "audio/kcaudio1.mp3" 
	},
	{ 
		id: 2, 
		component: Diana, 
		title: "Sunday 31 August 1997", 
		tags: ["MEMORY", "SIGNS", "THE DEAD", "NAMES", "POET"],
		mapX: 50.0755,
		mapY: 14.4378,
		mapZ: 14,
		colour: "#3E85D4",
		audio: "audio/cmaudio1.mp3"
	},
	{ 
		id: 3, 
		component: Goa, 
		title: "Look at this cosmopolitan architecture", 
		tags: ["EYES", "INVISIBLE", "TRADING", "NAMES", "HIDDEN"],
		mapX: 15.6871,
		mapY: 73.7213,
		mapZ: 14,
		colour: "#3E85D4",
		audio: "audio/kcaudio2.mp3"
	},
	{ 
		id: 4, 
		component: Beach, 
		title: "The Beach", 
		tags: ["DESIRE", "EYES", "TRADING", "POET"],
		mapX: 43.2965,
		mapY: 5.3698,
		mapZ: 14,
		colour: "#3E85D4",
		audio: "audio/cmaudio2.mp3" 
	},
	{ 
		id: 5, 
		component: Nicosia, 
		title: "We are going to live there and know nothing about the region", 
		tags: ["DESIRE", "SIGNS", "TRADING", "POET"],
		mapX: 35.1592902,
		mapY: 33.3255183,
		mapZ: 14,
		colour: "#3E85D4",
		audio: "audio/kcaudio3.mp3" 
	},
	{ 
		id: 6, 
		component: Elkhart, 
		title: "An Elkhart Truth", 
		tags: ["THE SKY", "NAMES", "POET"],
		mapX: 41.6899322,
		mapY: -85.9674493,
		mapZ: 18,
		colour: "#3E85D4",
		audio: "audio/cmaudio3.mp3" 
	},
	{ 
		id: 7, 
		component: Saigon, 
		title: "See if it goes any better", 
		tags: ["DESIRE", "NAMES", "CONTINUOUS"],
		mapX: 10.7779,
		mapY: 106.6748,
		mapZ: 14,
		colour: "#F0F1E1",
		audio: "audio/kcaudio4.mp3" 
	},
	{ 
		id: 8, 
		component: Sydney, 
		title: "Hello Sydney", 
		tags: ["DESIRE", "EYES", "INVISIBLE", "THIN"],
		mapX: -33.8838,
		mapY: 151.2158,
		mapZ: 14,
		colour: "#aab7bf",
		audio: "audio/cmaudio4.mp3" 
	},
	{ 
		id: 9, 
		component: Cincinnati, 
		title: "Thank you for tuning in", 
		tags: ["MEMORY", "SIGNS", "CONTINUOUS", "THIN"],
		mapX: 39.1031,
		mapY: -84.5120,
		mapZ: 14,
		colour: "#aab7bf",
		audio: "audio/kcaudio5.mp3" 
	},
	{ 
		id: 10, 
		component: ATP, 
		title: "All Tomorrow's Parties", 
		tags: ["EYES", "SIGNS", "CONTINUOUS", "POET"],
		mapX: 53.7632,
		mapY: -2.7031,
		mapZ: 14,
		colour: "#f1db07",
		audio: "audio/cmaudio5.mp3" 
	},
	{ 
		id: 11, 
		component: EnigmaticTangier, 
		title: "An enigmatic city that has survived its own myth", 
		tags: ["MEMORY", "THE DEAD", "THIN", "POET"],
		mapX: 35.7595,
		mapY: -5.8340,
		mapZ: 14,
		colour: "#f1db07",
		audio: "audio/kcaudio6.mp3" 
	},
	{ 
		id: 12, 
		component: FictitiousTangier, 
		title: "That Fictitious Landscape", 
		tags: ["DESIRE", "THE DEAD", "THE SKY", "HIDDEN"],
		mapX: 35.7595,
		mapY: -5.8340,
		mapZ: 14,
		colour: "#f1db07",
		audio: "audio/cmaudio6.mp3" 
	},
	{ 
		id: 13, 
		component: DublinOH, 
		title: "Headquarters", 
		tags: ["DESIRE", "SIGNS", "NAMES", "TRADING", "CONTINUOUS"],
		mapX: 40.0992,
		mapY: -83.1141,
		mapZ: 14,
		colour: "#f1db07",
		audio: "audio/kcaudio7.mp3" 
	},
	{ 
		id: 14, 
		component: DublinAUS, 
		title: "Citation Needed", 
		tags: ["DESIRE", "THE DEAD", "THE SKY", "HIDDEN"],
		mapX: -34.2700,
		mapY: 138.210,
		mapZ: 14,
		colour: "#f1db07",
		audio: "audio/cmaudio7.mp3" 
	},
	{ 
		id: 15, 
		component: Galapagos, 
		title: "I sincerely hope these exceptionally beautiful islands will remain sacred for all time", 
		tags: ["EYES", "THE SKY", "INVISIBLE"],
		mapX: -0.9538,
		mapY: -90.9656,
		mapZ: 14,
		colour: "#f1db07",
		audio: "audio/kcaudio8.mp3" 
	},
	{ 
		id: 16, 
		component: Yakutsk, 
		title: "Isolation into Inspiration (minimalist edit)", 
		tags: ["SIGNS", "EYES", "TRADING"],
		mapX: 62.0397,
		mapY: 129.7422,
		mapZ: 14,
		colour: "#f1db07",
		audio: "audio/cmaudio8.mp3" 
	},
	{ 
		id: 17, 
		component: Martinique, 
		title: "The name is probably a corruption", 
		tags: ["MEMORY", "SIGNS", "NAMES", "CONTINUOUS", "POET"],
		mapX: 14.6415,
		mapY: -61.0242,
		mapZ: 14,
		colour: "#f1db07",
		audio: "audio/kcaudio9.mp3" 
	},
	{ 
		id: 18, 
		component: Neuland, 
		title: "The New Land (Closed Captions)", 
		tags: ["MEMORY", "DESIRE", "INVISIBLE", "CONTINUOUS"],
		mapX: -22.6505,
		mapY: -60.1290,
		mapZ: 14,
		colour: "#f1db07",
		audio: "audio/cmaudio9.mp3" 
	},
	{ 
		id: 19, 
		component: Ys, 
		title: "I’d love to be able to say 'I’ve been there'", 
		tags: ["INVISIBLE", "SIGNS", "MEMORY", "HIDDEN"],
		mapX: 48.1700,
		mapY: -4.4330,
		mapZ: 10,
		colour: "#f1db07",
		audio: "audio/kcaudio10.mp3" 
	},
	{ 
		id: 20, 
		component: Atlantis, 
		title: "The New Republic", 
		tags: ["HIDDEN", "SIGNS", "TRADING", "CONTINUOUS"],
		mapX: 36.2105,
		mapY: 25.2413,
		mapZ: 14,
		colour: "#f1db07",
		audio: "audio/cmaudio10.mp3" 
	},
]);