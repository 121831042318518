<script>
  import { fade } from "svelte/transition";
  import { onMount } from "svelte";
  import GraffitiDisplay from "../toasts/GraffitiDisplay.svelte";

  onMount(() => {
    let a = document.getElementById("audio-player");
    a.addEventListener("ended", (e) => {
      const split = a.src.split("/");
      if (split[split.length - 1] == `cmaudio8.mp3`) {
        a.src = `./audio/slezki.ogg`;
        a.volume = 0.4;
        a.play();
      }
    });
  });
</script>

<div class="sceneWrapper" transition:fade={{ delay: 450, duration: 600 }}>
  <div class="poem-images">
    <img src="./visuals/c8b.jpg" alt="Yakutsk" class="poem-image" />
    <img src="./visuals/c8a.jpeg" alt="Yakutsk" class="poem-image" />
  </div>
  <div class="poem" transition:fade={{ delay: 2000, duration: 300 }}>
    <h1>Isolation into Inspiration (minimalist edit)</h1>

    <div class="intro">
      <p>
        <em>
          That these events are so popular is particularly impressive given how
          dramatically difficult it is to reach Yakutsk to begin with. No direct
          railways to the town exist. The closest highway sits on the opposite
          shore of the vast Lena River which can only be traversed at select
          points either by ferry in the summer or by car in the winter (provided
          the ice is thick enough to support traffic). I went today and froze my
          balls off. Your hot water bottle advice was sound.
        </em>
      </p>
    </div>

    <p>
      life is hard<br />
      summers airless and dusty<br />
      winters dark foggy and last eight months
    </p>

    <p>
      it sounds bad but I love<br />
      the only place I can feel<br />
      not a label nor a job<br />
      like a community of friends<br />
      we do what we like
    </p>

    <p>
      minus fifty degrees<br />
      can’t feel<br />
      my legs
    </p>

    <p>
      a lot of young people <br />
      older people also not going anywhere<br />
      create a friendly atmosphere<br />
      no one really comes
    </p>

    <p>
      in this terrible isolation <br />
      kids entertain themselves
    </p>

    <p>
      every year I think punks are going to disappear<br />
      but there are more and more of them
    </p>

    <p>
      once I saw a punk walking<br />
      blasting music from a tape recorder<br />
      rubbing his shoes loudly against the asphalt<br />
      to make passersby angry<br />
      I was scared and inspired
    </p>
    <GraffitiDisplay />

    <p>
      we aren’t really in touch<br />
      it ended with a Thanks for the add<br />
      a lot of nice comments<br />
      sometimes a couple of dollars<br />
      it’s cool
    </p>

    <p>
      our language is dying<br />
      performing sacral meaning<br />
      blind and uncoordinated attack
    </p>
  </div>
</div>

<style>
  .sceneWrapper {
    display: flex;
  }
  .poem-images {
    display: flex;
    flex-direction: column;
  }
  .poem-image {
    border-right: 3px solid black;
    max-width: 100%;
    object-fit: cover;
  }
  .poem {
    max-width: 50vw;
    flex: 1 0 50%;
    margin: 10vh auto 100px;
    padding: 20px;
  }
</style>
