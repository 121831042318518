<script>
  import { onMount } from "svelte";
  let active = false;

  let api =
    window.location.hostname == "localmost"
      ? "http://localhost:4231"
      : "https://invisible-cities.fly.dev";

  let toinCoss = Math.random();
  console.log({ toinCoss });

  const getGraffiti = async (e) => {
    // let content = document.querySelector("#graffiti_input").value;
    let post = await fetch(`${api}/graffiti`).then((res) => res.json());
    console.log({ post });
    let rnd = Math.floor(Math.random() * post.length);
    let tag = post[rnd];
    console.log({ tag });
    document.getElementById("graf").textContent = tag.content;
  };

  const blink = (el) => {
    let t = Math.random() * 3;
    el.classList.toggle("active");
    setTimeout(() => {
      blink(el);
    }, t * 500);
  };

  onMount(() => {
    active = true;
    if (toinCoss > 0.1) {
      getGraffiti();
      blink(document.querySelector(".graffiti-display"));
    }
  });
</script>

{#if toinCoss > 0.5}
  <div class="toast graffiti-display" class:active><span id="graf" /></div>
{/if}

<style>
  .toast {
    opacity: 0;
    position: unset;
    display: flex;
    flex-direction: column;
    border: none;
    background-color: rgba(255, 255, 255, 0);
  }
  .active {
    opacity: 1;
  }
  #graf {
    color: blue;
  }
</style>
